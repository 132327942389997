import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import styled from 'styled-components/macro';

import { ReactComponent as Map } from '../../assets/images/map.svg';

function ContactMap() {
  const svgRef = useRef(null);

  const SvgWrapper = styled.div`
    width: 100%;
    height: auto;
  `;

  useEffect(() => {
    const [elements] = svgRef.current.children;
    const svg = svgRef.current.children;
    const skyTower = elements.getElementById('skytower');
    const location = elements.getElementById('location');
    const galery = elements.getElementById('galery');
    const road = elements.getElementById('road');
    const firstText = elements.getElementById('firstText');
    const secondText = elements.getElementById('secondText');
    const thirdText = elements.getElementById('thirdText');
    const mapTl = gsap.timeline({
      paused: true,
      scrollTrigger: { trigger: skyTower, start: 'top 70%' },
    });
    const locationTl = gsap.timeline({ pause: true, yoyo: true, repeat: -1 });
    function onLocationComplete() {
      locationTl.play();
    }
    locationTl.to(location, 0.45, { y: -20 });
    mapTl
      .set(
        [skyTower, road, location, galery, firstText, secondText, thirdText],
        {
          opacity: 0,
        }
      )
      .set(svg, { opacity: 1 })
      .to(skyTower, 0.45, { opacity: 1 })
      .to(road, 0.45, { opacity: 1 })
      .to([firstText, secondText, thirdText], 0.45, { opacity: 1 })
      .to(galery, 0.45, { opacity: 1 })
      .to(location, 0.45, { opacity: 1, onLocationComplete });
  });

  return (
    <SvgWrapper ref={svgRef}>
      <Map />
    </SvgWrapper>
  );
}

export default ContactMap;
