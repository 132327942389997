import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { GET_CATEGORIES } from '../gql/getCategories';
import Loading from '../components/loading';
import { getCategoriesData } from '../state/actions/categories';

export default (ChildComponent) => {
  function ComposedComponent(props) {
    const categories = useSelector((state) => state.categories.categories);
    const dispatch = useDispatch();
    const { loading, error, data } = useQuery(GET_CATEGORIES);
    if (error) return console.log(error);
    if (loading) return <Loading />;

    if (categories.length === 0) {
      dispatch(getCategoriesData(data.productCategories.edges));
    }

    return <ChildComponent {...props} />;
  }

  return ComposedComponent;
};
