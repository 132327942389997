import styled from 'styled-components/macro';
import { Link } from '@reach/router';

export const ContentWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 90px;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.bigTablet} {
    z-index: 9998;
    position: fixed;
    margin-top: 50px;
  }
`;

export const ContentContainer = styled.div`
  ${({ theme }) => theme.container};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 30px auto;
  flex-wrap: wrap;
  ${({ theme }) => theme.mq.bigPhone} {
    flex-wrap: nowrap;
  }
`;

export const CatalogButton = styled.button`
  width: 49%;
  height: 57px;
  border: none;
  position: relative;
  background-color: ${({ theme, isBlue }) =>
    isBlue ? theme.colors.primary : theme.colors.secondary};
  margin: 20px 1% 20px 0;
  border-radius: 10px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    width: 41%;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    width: 24%;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ButtonText = styled.span`
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.white};
  width: 80%;
`;

export const ButtonIcon = styled.img`
  width: ${({ isSearch }) => (isSearch ? '26px' : '19px')};
  height: auto;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isVisible }) => (isVisible ? 'rotate(180deg)' : 'rotate(0)')};
`;

export const LogoWrapper = styled.div`
  display: none;
  width: 25%;
  padding: 0 5% 0 0;
  height: auto;
  @media (min-width: 992px) {
    display: block;
  }
`;

export const LogoLink = styled(Link)`
  outline: none;
`;

export const Logo = styled.img`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuWrapper = styled.div`
  width: 100%;
  height: 490px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  top: 50px;
  left: 0;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  ::-webkit-scrollbar {
    width: 1.2rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.ligthgrey};
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.grey};
    height: 1.2rem;
  }
`;
