import styled from 'styled-components/macro';

export const Svg = styled.svg`
  width: 100%;
  height: 100%;
  .st0 {
    fill: #008d36;
    opacity: 0;
  }
  .st1 {
    enable-background: new 0 0 458 408;
  }
`;
