import { SUBMIT_ORDER, SUBMIT_ORDER_ERROR } from '../../actions/types';

const INITIAL_STATE = {
  cartFormResponse: '',
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SUBMIT_ORDER: {
      return { ...state, cartFormResponse: action.payload };
    }
    case SUBMIT_ORDER_ERROR: {
      return { ...state, cartFormResponse: action.payload };
    }
    default:
      return state;
  }
}
