import React from 'react';

import Layout from '../../components/layout';
import HeroSliderData from '../../components/heroSlider';
import AboutUsPage from '../../components/aboutUsPage';
import SearchBar from '../../components/searchBar';
import SEO from '../../components/SEO';

const AboutUs = () => (
  <Layout>
    <SEO title="O Nas" />
    <SearchBar />
    <HeroSliderData />
    <AboutUsPage />
  </Layout>
);

export default AboutUs;
