import styled from 'styled-components';
import { Link } from '@reach/router';

export const ProductContainer = styled.div`
  ${({ theme }) => theme.container};
  margin: 20px auto 50px auto;
`;

export const ProductTitle = styled.h2`
  color: ${({ theme, isProduct }) =>
    isProduct ? theme.colors.secondary : theme.colors.grey};
  font-size: ${({ theme, isProduct }) =>
    isProduct ? theme.font.xs : '1.8rem'};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-align: ${({ isProduct }) => (isProduct ? 'left' : 'center')};
  margin-bottom: ${({ isProduct }) => (isProduct ? '0' : '20px')};
`;

export const ProductBox = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 10px;
  flex-wrap: wrap;
  ${({ theme }) => theme.mq.desktop} {
    flex-wrap: nowrap;
  }
`;

export const ProductItem = styled.div`
  width: 100%;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  margin: 5px 0;
  ${({ theme }) => theme.mq.desktop} {
    width: 25%;
    margin: 0 1% 0 0;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

export const ImageWrapper = styled.div`
  width: 50%;
  height: auto;
  margin-left: auto;
  margin-top: 10px;
`;

export const ProductImage = styled.img`
  width: 100%;
  height: 146px;
`;

export const ProductButton = styled(Link)`
  padding: 5px 40px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.secondary};
  position: absolute;
  bottom: 10px;
  left: 10px;
  margin: auto;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ProductPrice = styled.span`
  font-size: ${({ theme, isSmall }) =>
    isSmall ? theme.font.m : theme.font.xl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  bottom: 50px;
  left: 15px;
`;

export const ProductPriceText = styled.span`
  font-size: ${({ theme }) => theme.font.m};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.secondary};
  position: absolute;
  bottom: ${({ isSmall }) => (isSmall ? '95px' : '80px')};
  left: 15px;
`;
