import React from 'react';

import Layout from '../../components/layout';
import SearchBar from '../../components/searchBar';
import HeroSliderData from '../../components/heroSlider';
import PortfolioBox from '../../components/portfolioBox';
import SEO from '../../components/SEO';

const Portfolio = () => (
  <Layout>
    <SEO title="Portfolio" />
    <SearchBar />
    <HeroSliderData />
    <PortfolioBox />
  </Layout>
);

export default Portfolio;
