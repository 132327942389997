import {
  SEND_CONTACT,
  SEND_CONTACT_ERROR,
  RESET_SEND_STATUS,
} from '../../actions/types';

const INITIAL_STATE = {
  contactFormResponse: '',
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SEND_CONTACT: {
      return { ...state, contactFormResponse: action.payload };
    }
    case SEND_CONTACT_ERROR: {
      return { ...state, contactFormResponse: action.payload };
    }
    case RESET_SEND_STATUS: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
