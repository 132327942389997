import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import NoImage from '../../../assets/images/no_image.png';
import { ReactComponent as TrashIcon } from '../../../assets/images/trash.svg';
import {
  deleteFromCart,
  increaseProductQuantity,
  decreaseProductQuantity,
  setProductQuantity,
} from '../../../state/actions/cart';
import {
  CartRow,
  CartInformationItem,
  CartInformationItemOption,
  CartWrapper,
  ProductWrapper,
  QuantityWrapper,
  PriceWrapper,
  OptionWrapper,
  ImageWrapper,
  StyledImage,
  ProductTextWrapper,
  ProductTitle,
  PriceText,
  DeleteButton,
  QuantityButton,
  QuantityInput,
} from '../cartProductList.styled';

function CartProductListDesktop() {
  const dispatch = useDispatch();
  const cartList = useSelector((state) => state.cart.cartList);

  const deleteProduct = (id) => {
    dispatch(deleteFromCart(id));
  };

  const increaseQuantity = (id) => {
    dispatch(increaseProductQuantity(id));
  };

  const decreaseQuantity = (id) => {
    dispatch(decreaseProductQuantity(id));
  };

  const calculatePrice = (total, quantity) => {
    const price = Number(total) * quantity;
    return price.toFixed(2);
  };

  const handleChange = (e) => {
    if (e.target.value <= 0) {
      return null;
    }

    if (isNaN(e.target.value)) {
      return null;
    }

    if (cartList.map((product) => product.quantity !== e.target.value)) {
      dispatch(setProductQuantity(e.target.id, e.target.value));
    }

    return null;
  };

  return (
    <>
      <CartRow isFirst>
        <CartInformationItem isFirst>Dane Produktu</CartInformationItem>
        <CartInformationItem>Ilość</CartInformationItem>
        <CartInformationItemOption isFirst>
          Cena Za Sztuke Netto
        </CartInformationItemOption>
        <CartInformationItemOption isFirst>
          Całkowita Wartość Netto
        </CartInformationItemOption>
        <CartInformationItemOption>Opcje</CartInformationItemOption>
      </CartRow>
      <CartWrapper>
        {cartList.map((product) => (
          <CartRow key={product.id}>
            <ProductWrapper>
              <ImageWrapper>
                {product.images === null ? (
                  <StyledImage src={NoImage} alt={product.name} />
                ) : (
                  <StyledImage
                    src={product.image.sourceUrl}
                    alt={product.name}
                  />
                )}
              </ImageWrapper>
              <ProductTextWrapper>
                <ProductTitle as="h2" isTitle>
                  {product.name}
                </ProductTitle>
                <ProductTitle>Numer produktu: {product.sku}</ProductTitle>
                {/* <ProductTitle>
                  Kolor: {product.attributes[0].options}
                </ProductTitle> */}
              </ProductTextWrapper>
            </ProductWrapper>
            <QuantityWrapper>
              {product.quantity === 1 ? (
                <QuantityButton type="button">-</QuantityButton>
              ) : (
                <QuantityButton
                  type="button"
                  price={product.price}
                  onClick={() => decreaseQuantity(product.id)}
                >
                  -
                </QuantityButton>
              )}
              <form>
                <QuantityInput
                  type="text"
                  id={product.id}
                  value={product.quantity}
                  onChange={handleChange}
                />
              </form>
              <QuantityButton
                type="button"
                onClick={() => increaseQuantity(product.id)}
              >
                +
              </QuantityButton>
            </QuantityWrapper>
            <PriceWrapper>
              <PriceText>{product.price}</PriceText>
            </PriceWrapper>
            <PriceWrapper>
              <PriceText>
                {calculatePrice(product.price, product.quantity)}
              </PriceText>
            </PriceWrapper>
            <OptionWrapper>
              <DeleteButton
                type="button"
                onClick={() => deleteProduct(product.id)}
              >
                <TrashIcon />
              </DeleteButton>
            </OptionWrapper>
          </CartRow>
        ))}
      </CartWrapper>
    </>
  );
}

export default CartProductListDesktop;
