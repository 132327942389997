import styled from 'styled-components';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 30px;
  ${({ theme }) => theme.mq.bigTablet} {
    margin-top: 210px;
  }
`;

export const SectionContainer = styled.div`
  ${({ theme }) => theme.container};
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.xl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.grey};
  text-align: center;
  margin-bottom: 20px;
`;

export const SupervisorWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 30px 0;
`;

export const SupervisorItem = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 50px;
  ${({ theme }) => theme.mq.bigTablet} {
    width: 50%;
    margin-bottom: 0;
  }
`;

export const ImageWrapper = styled.div`
  width: 250px;
  height: 250px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 15px;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const TextBox = styled.p`
  font-size: ${({ theme, isTitle }) => (isTitle ? theme.font.l : theme.font.m)};
  font-weight: ${({ theme, isTitle }) =>
    isTitle ? theme.fontWeight.bold : theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  text-align: center;
  margin-bottom: 5px;
  &:nth-child(3) {
    margin-bottom: 20px;
  }
  &:nth-child(4) {
    margin-bottom: 20px;
  }
`;
