import { gql } from '@apollo/client';

export const GET_PORTFOLIO_ITEM = gql`
  query GET_PORTFOLIO_ITEM($id: ID!) {
    portfolio(idType: URI, id: $id) {
      acfPortfolio {
        longdesc
        title
        gallery {
          sourceUrl
        }
        heroimage {
          sourceUrl
        }
      }
    }
  }
`;
