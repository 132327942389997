import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_ABOUTUS_DATA } from '../../gql/getAboutusData';
import Loading from '../loading';

import AboutUsTimeline from '../aboutUsTimeline';
import AboutCompany from '../aboutCompany';
import FlprSection from '../flprSection';
import { SectionWrapper, SectionContainer } from './aboutUsPage.styled';

function AboutUsTemplate() {
  const { loading, error, data } = useQuery(GET_ABOUTUS_DATA);
  if (error) return console.log(error);
  if (loading) return <Loading />;

  return (
    <SectionWrapper>
      <SectionContainer>
        <AboutUsTimeline data={data} />
      </SectionContainer>
      <AboutCompany data={data} />
      <FlprSection data={data} />
    </SectionWrapper>
  );
}

export default AboutUsTemplate;
