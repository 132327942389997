import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
  query GET_CATEGORIES {
    productCategories(first: 100) {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`;
