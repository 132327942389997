import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_HOME_PRODUCT } from '../../gql/getHomeProducts';
import Loading from '../loading';
import noImage from '../../assets/images/no_image.png';

import {
  SectionWrapper,
  SectionRow,
  FirstSectionItem,
  SectionItem,
  StyledImage,
  Title,
  StyledButton,
  ProductPrice,
  ProductPriceText,
} from './homeProducts.styled';

function HomeProducts() {
  const { loading, error, data } = useQuery(GET_HOME_PRODUCT);
  if (error) return console.log(error);
  if (loading) return <Loading />;

  const products = data.products.edges.slice(1);

  console.log(data);

  return (
    <SectionWrapper>
      <SectionRow isFirst>
        <FirstSectionItem>
          <Title>{data.products.edges[0].node.name}</Title>
          {data.products.edges[0].node.featuredImage.node.sourceUrl === null ? (
            <StyledImage
              isFirst
              src={noImage}
              alt={data.products.edges[0].node.name}
            />
          ) : (
            <StyledImage
              isFirst
              src={data.products.edges[0].node.featuredImage.node.sourceUrl}
              alt={data.products.edges[0].node.name}
            />
          )}
          {data.products.edges[0].node.price === null ? (
            <>
              <ProductPrice isSmall>
                Dopytaj
                <br />
                sprzedawce
              </ProductPrice>
              <ProductPriceText isSmall>Cena od:</ProductPriceText>
            </>
          ) : (
            <>
              <ProductPrice>
                {data.products.edges[0].node.price}&nbsp;zł
              </ProductPrice>
              <ProductPriceText>Cena od:</ProductPriceText>
            </>
          )}
          <StyledButton to={`/produkt/${data.products.edges[0].node.slug}`}>
            Więcej
          </StyledButton>
        </FirstSectionItem>
      </SectionRow>
      <SectionRow>
        {products.map((product) => (
          <SectionItem key={product.node.id}>
            <Title>{product.node.name}</Title>
            {product.node.featuredImage.node.sourceUrl === null ? (
              <StyledImage src={noImage} alt={product.node.name} />
            ) : (
              <StyledImage
                src={product.node.featuredImage.node.sourceUrl}
                alt={product.node.name}
              />
            )}
            {product.node.price === null ? (
              <>
                <ProductPrice isSmall>
                  Dopytaj
                  <br />
                  sprzedawce
                </ProductPrice>
                <ProductPriceText isSmall>Cena od:</ProductPriceText>
              </>
            ) : (
              <>
                <ProductPrice>{product.node.price}&nbsp;zł</ProductPrice>
                <ProductPriceText>Cena od:</ProductPriceText>
              </>
            )}
            <StyledButton to={`/produkt/${product.node.slug}`}>
              Więcej
            </StyledButton>
          </SectionItem>
        ))}
        <SectionItem isLast>
          <Title isCenter>NIE MOŻESZ ZNALEŹĆ PRODUKTU?</Title>
          <StyledButton to="/kontakt">Skontaktuj się z nami</StyledButton>
        </SectionItem>
      </SectionRow>
    </SectionWrapper>
  );
}

export default HomeProducts;
