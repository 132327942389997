import React from 'react';

import icon from '../../assets/images/icon.png';
import { ReactComponent as FacebookIcon } from '../../assets/images/facebook-icon.svg';
import { ReactComponent as MailIcon } from '../../assets/images/mail-icon.svg';
import {
  SpacerWrapper,
  SectionContainer,
  TextWrapper,
  TextBox,
  ContactWrapper,
  ImageWrapper,
  StyledImage,
  LineWrapper,
  Line,
  ContactTextWrapper,
  LinksWrapper,
  LinkIcon,
  StyledLink,
} from './aboutCompany.styled';

const AboutCompany = ({ data }) => {
  console.log(data);

  return (
    <>
      <SpacerWrapper>
        <SectionContainer>
          <TextWrapper>
            <TextBox isTitle>Progadget – Gadgets 4 Everyone</TextBox>
            <TextBox>Wszystko aby Twoja firma była widoczna.</TextBox>
          </TextWrapper>
        </SectionContainer>
      </SpacerWrapper>
      <ContactWrapper>
        <SectionContainer isFlex>
          <ImageWrapper>
            <StyledImage src={icon} />
          </ImageWrapper>
          <LineWrapper>
            <Line />
          </LineWrapper>
          <ContactTextWrapper>
            <LinksWrapper>
              <StyledLink
                href={data.onass.nodes[0].acfAboutUs.facebook}
                target="_blank"
              >
                <LinkIcon>
                  <FacebookIcon />
                </LinkIcon>
              </StyledLink>
              <StyledLink
                href={`mailto:${data.onass.nodes[0].acfAboutUs.mail}`}
              >
                <LinkIcon>
                  <MailIcon />
                </LinkIcon>
              </StyledLink>
            </LinksWrapper>
            <TextBox>Progadget</TextBox>
            <TextBox>Zrobimy wszystko aby Twoja firma była widoczna.</TextBox>
            <StyledLink href={`tel:${data.onass.nodes[0].acfAboutUs.phone}`}>
              <TextBox>{data.onass.nodes[0].acfAboutUs.phone}</TextBox>
            </StyledLink>
          </ContactTextWrapper>
        </SectionContainer>
      </ContactWrapper>
    </>
  );
};

export default AboutCompany;
