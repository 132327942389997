import React from 'react';

import bidon from '../../assets/images/bidon-image.png';
import cup from '../../assets/images/cup-image.png';
import pins from '../../assets/images/pins-image.png';
import note from '../../assets/images/note-image.png';
import folder from '../../assets/images/folder-image.png';
import {
  SectionWrapper,
  SectionContainer,
  ProductWrapper,
  ProductItem,
  ImageWrapper,
  StyledImage,
  TextWrapper,
  TextBox,
} from './kauflandGadgetsTemplate.styled';

const KauflandGadgetsTemplate = () => {
  return (
    <SectionWrapper>
      <SectionContainer>
        <ProductWrapper>
          <ProductItem>
            <ImageWrapper>
              <StyledImage src={cup} />
            </ImageWrapper>
            <TextWrapper>
              <TextBox isTitle isMargin>
                Kubek Americano nr. 001
              </TextBox>
              <TextBox isMargin>
                Kultowy kubek reklamowy o klasycznym, obłym kształcie i bardzo
                dużej pojemności (450 ml)
              </TextBox>
              <TextBox isTitle>Ceny netto dla:</TextBox>
              <TextBox>do 500 szt – 16,22 zł szt</TextBox>
              <TextBox>501-100 szt – 16,22 zł szt</TextBox>
              <TextBox>1001-2000 szt – 15,48 zł szt</TextBox>
              <TextBox>2001-3000 szt – 13,82 zł szt</TextBox>
              <TextBox>pow. 3000 szt – 13,82 zł szt</TextBox>
            </TextWrapper>
          </ProductItem>
          <ProductItem>
            <ImageWrapper>
              <StyledImage src={bidon} />
            </ImageWrapper>
            <TextWrapper>
              <TextBox isTitle isMargin>
                Bidon nr. 002
              </TextBox>
              <TextBox isMargin>
                Lekki, szczelny bidon z solidnym zamknięciem, wyposażony w
                pojemnik na kawałki owoców lub miętę. Wykonany z tritanu –
                wytrzymałego, przezroczystego tworzywa niezmieniającego smaku
                napoju. Nie zawiera BPA.
              </TextBox>
              <TextBox isTitle>Ceny netto dla:</TextBox>
              <TextBox>do 500 szt – 18,25 zł szt</TextBox>
              <TextBox>501-100 szt – 18,25 zł szt</TextBox>
              <TextBox>1001-2000 szt – 17,42 zł szt</TextBox>
              <TextBox>2001-3000 szt – 16,59 zł szt</TextBox>
              <TextBox>pow. 3000 szt – 16,59 zł szt</TextBox>
            </TextWrapper>
          </ProductItem>
          <ProductItem>
            <ImageWrapper>
              <StyledImage src={pins} />
            </ImageWrapper>
            <TextWrapper>
              <TextBox isTitle isMargin>
                Pinsy reklamowe nr. 003
              </TextBox>
              <TextBox isMargin>
                Pinsy reklamowe w formacie 5,5 cm z dedykowaną grafiką w środku.
                Idealne na każdą akcję promocyjną.
              </TextBox>
              <TextBox isTitle>Ceny netto dla:</TextBox>
              <TextBox>do 500 szt – 0,88 zł szt</TextBox>
              <TextBox>501-100 szt – 0,88 zł szt</TextBox>
              <TextBox>1001-2000 szt – 0,84 zł szt</TextBox>
              <TextBox>2001-3000 szt – 0,82 zł szt</TextBox>
              <TextBox>pow. 3000 szt – 0,78 zł szt</TextBox>
            </TextWrapper>
          </ProductItem>
          <ProductItem>
            <ImageWrapper>
              <StyledImage src={note} />
            </ImageWrapper>
            <TextWrapper>
              <TextBox isTitle isMargin>
                Zeszyt w formacie A4 lub A5 nr. 004A4 oraz 005A5 (odpowiednio
                dla zeszytów A4 i A5)
              </TextBox>
              <TextBox isMargin>
                Zeszyt spiralowany z okładką. Format A4 lub A5. Wydruk na
                papierze +/-90g, okładka 350g.
              </TextBox>
              <TextBox isTitle>A4 Ceny netto dla:</TextBox>
              <TextBox>do 500 szt – 3,68 zł szt</TextBox>
              <TextBox>501-100 szt – 3,31 zł szt</TextBox>
              <TextBox>1001-2000 szt – 3,31 zł szt</TextBox>
              <TextBox>2001-3000 szt – 3,12 zł szt</TextBox>
              <TextBox isMargin>pow. 3000 szt – 3,06 zł szt</TextBox>
              <TextBox isTitle>A5 Ceny netto dla:</TextBox>
              <TextBox>do 500 szt – 2,24 zł szt</TextBox>
              <TextBox>501-100 szt – 2,01 zł szt</TextBox>
              <TextBox>1001-2000 szt – 2,01 zł szt</TextBox>
              <TextBox>2001-3000 szt – 1,90 zł szt</TextBox>
              <TextBox>pow. 3000 szt – 1,85 zł szt</TextBox>
            </TextWrapper>
          </ProductItem>
          <ProductItem>
            <ImageWrapper>
              <StyledImage src={folder} />
            </ImageWrapper>
            <TextWrapper>
              <TextBox isTitle isMargin>
                Teczka nr. 006
              </TextBox>
              <TextBox isMargin>
                Teczka reklamowa w formacie A4. Bez gumki. Zadruk jedno lub
                dwustronny.
              </TextBox>
              <TextBox isTitle>Ceny netto dla:</TextBox>
              <TextBox>do 500 szt – 3,36 zł szt</TextBox>
              <TextBox>501-100 szt – 1,22 zł szt</TextBox>
              <TextBox>1001-2000 szt – 0,90 zł szt</TextBox>
              <TextBox>2001-3000 szt – 0,74 zł szt</TextBox>
              <TextBox>pow. 3000 szt – 0,69 zł szt</TextBox>
            </TextWrapper>
          </ProductItem>
        </ProductWrapper>
      </SectionContainer>
    </SectionWrapper>
  );
};

export default KauflandGadgetsTemplate;
