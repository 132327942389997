import styled from 'styled-components/macro';
import { Form, Field } from 'formik';

export const StyledForm = styled(Form)`
  position: relative;
  margin-top: 20px;
`;

export const FieldWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-flow: column;
  align-items: center;
  ${({ theme }) => theme.mq.bigTablet} {
    align-items: flex-end;
  }
`;

export const StyledField = styled(Field)`
  font-family: 'CenturyGothic', sans-serif;
  font-size: ${({ theme }) => theme.font.m};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  width: 100%;
  height: ${({ isMessage }) => (isMessage ? '250px' : '45px')};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.ligthgrey};
  border: ${({ theme, isError }) =>
    isError ? `1px solid ${theme.colors.red}` : 'none'};
  padding: ${({ isMessage }) => (isMessage ? '15px' : '0 15px')};
  resize: none;
  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const FieldError = styled.p`
  font-size: ${({ theme }) => theme.font.xs};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.red};
  text-align: right;
  margin-top: 2px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.mq.bigTablet} {
    justify-content: flex-end;
  }
`;

export const FormButton = styled.button`
  width: 304px;
  height: 57px;
  background-color: ${({ theme }) => theme.colors.secondary};
  font-size: ${({ theme }) => theme.font.xs};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  border: none;
  border-radius: 10px;
`;

export const SendStatus = styled.p`
  font-size: ${({ theme }) => theme.font.l};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme, isError }) =>
    isError ? theme.colors.red : theme.colors.secondary};
  text-align: right;
  margin: 20px 0;
`;
