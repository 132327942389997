import styled from 'styled-components';
import { Link } from '@reach/router';

export const SectionWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 50px 0;
  ${({ theme }) => theme.mq.bigTablet} {
    width: calc(100% - 10px);
    margin: 0 0 50px 10px;
  }
`;

export const SectionItem = styled.div`
  width: ${({ isNone }) => (isNone ? '100%' : 'calc(100% - 20px);')};
  height: ${({ isNone }) => (isNone ? '500px' : 'auto')};
  margin: 10px;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  display: ${({ isNone }) => (isNone ? 'flex' : 'block')};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ theme }) => theme.mq.desktop} {
    width: ${({ isNone }) => (isNone ? '100%' : 'calc(50% - 20px);')};
  }
`;

export const ProductTitle = styled.h2`
  width: 100%;
  font-size: ${({ theme, isNone }) =>
    isNone ? theme.font.xxl : theme.font.xs};
  color: ${({ theme }) => theme.colors.secondary};
  text-align: ${({ isNone }) => (isNone ? 'center' : 'left')};
  height: auto;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 147px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (min-width: 480px) {
    height: 200px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    height: 242px;
  }
`;

export const ProductImage = styled.img`
  width: 147px;
  height: 100%;
  @media (min-width: 480px) {
    width: 200px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    width: 60%;
    height: 100%;
  }
  ${({ theme }) => theme.mq.tablet} {
    width: 242px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    width: 200px;
    height: 200px;
  }
  ${({ theme }) => theme.mq.bigDesktop} {
    width: 60%;
    height: 100%;
  }
`;

export const ProductButton = styled(Link)`
  padding: 5px 40px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.secondary};
  position: absolute;
  bottom: ${({ isNone }) => (isNone ? '150px' : '15px')};
  left: ${({ isNone }) => (isNone ? 'none' : '15px')};
  margin: auto;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ProductPrice = styled.span`
  font-size: ${({ theme, isSmall }) =>
    isSmall ? theme.font.m : theme.font.xl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  bottom: 50px;
  left: 15px;
`;

export const ProductPriceText = styled.span`
  font-size: ${({ theme }) => theme.font.m};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.secondary};
  position: absolute;
  bottom: ${({ isSmall }) => (isSmall ? '95px' : '80px')};
  left: 15px;
`;
