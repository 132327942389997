import { combineReducers } from 'redux';

import cart from './cart/cart';
import contact from './contact/contact';
import categories from './categories/categories';
import search from './search/search';
import cartForm from './cartForm/cartForm';
import summary from './summary/summary';

export default combineReducers({
  cart,
  contact,
  categories,
  search,
  cartForm,
  summary,
});
