import styled from 'styled-components';
import { Link } from '@reach/router';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  ${({ theme }) => theme.mq.bigTablet} {
    margin-bottom: 115px;
  }
`;

export const CategoryNavBar = styled.nav`
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.colors.secondary};
`;

export const SubCategoryContainer = styled.ul`
  position: absolute;
  top: 35px;
  left: 50px;
  width: auto;
  opacity: 0;
  visibility: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.colors.primary};
  z-index: 2;
`;

export const SubCategoryItem = styled.li`
  width: auto;
  height: auto;
  color: ${({ theme }) => theme.colors.white};
  list-style: none;
  padding: 5px 10px;
  position: relative;
  text-align: left;
  &:hover {
    &::after {
      content: '';
      position: absolute;
      top: 25px;
      left: 10px;
      width: calc(100% - 20px);
      height: 1px;
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const CategoryItem = styled.li`
  width: 100%;
  height: auto;
  color: ${({ theme }) => theme.colors.white};
  list-style: none;
  padding: 10px;
  position: relative;
  text-align: left;
  &:hover ${SubCategoryContainer} {
    max-height: 1000px;
    opacity: 1;
    visibility: visible;
  }
  &:hover {
    &::after {
      content: '';
      position: absolute;
      top: 32px;
      left: 10px;
      width: calc(100% - 20px);
      height: 2px;
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const CategoryLink = styled(Link)`
  width: 100%;
  height: auto;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
`;
