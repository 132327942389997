import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import '../../assets/css/fonts.css';
import { theme } from '../../utils/theme/theme';
import Footer from '../footer';
import Header from '../header';
import { GlobalStyle, SiteWrapper } from './layout.styled';

function Layout({ children }) {
  if (typeof window !== `undefined`) {
    gsap.registerPlugin(ScrollTrigger);
    gsap.core.globals('ScrollTrigger', ScrollTrigger);
  }

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <SiteWrapper>
          <Header />
          {children}
          <Footer />
        </SiteWrapper>
      </>
    </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Layout;
