import React from 'react';

import marcin from '../../assets/images/marcin.png';
import sandra from '../../assets/images/sandra.png';
import {
  SectionWrapper,
  SectionContainer,
  Title,
  SupervisorWrapper,
  SupervisorItem,
  ImageWrapper,
  StyledImage,
  TextBox,
} from './kauflandSupervisorTemplate.styled';

const KauflandSupervisorTemplate = () => {
  return (
    <SectionWrapper>
      <SectionContainer>
        <Title>DEDYKOWANI OPIEKUNOWIE HANDLOWI KAUFLAND POLSKA</Title>
        <SupervisorWrapper>
          <SupervisorItem>
            <ImageWrapper>
              <StyledImage src={marcin} />
            </ImageWrapper>
            <TextBox isTitle>MARCIN CHABIER</TextBox>
            <TextBox>OPIEKUN FIRMY KAUFLAND</TextBox>
            <TextBox>
              W razie jakichkowlek pytań proszę się śmiało kontaktować. Pomogę,
              doradzę i wskażę najlepsze rozwiązanie. Czasem także pocieszę
            </TextBox>
            <TextBox>Kontakt telefoniczny:</TextBox>
            <TextBox>+48 607 380 062</TextBox>
            <TextBox>+48 71 792 45 69</TextBox>
          </SupervisorItem>
          <SupervisorItem>
            <ImageWrapper>
              <StyledImage src={sandra} />
            </ImageWrapper>
            <TextBox isTitle>SANDRA CIEŚLAREK</TextBox>
            <TextBox>WSPARCIE SPRZEDAŻY</TextBox>
            <TextBox>Kontakt telefoniczny:</TextBox>
            <TextBox>+48 535 260 320</TextBox>
            <TextBox>+48 71 792 45 69</TextBox>
          </SupervisorItem>
        </SupervisorWrapper>
      </SectionContainer>
    </SectionWrapper>
  );
};

export default KauflandSupervisorTemplate;
