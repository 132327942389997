import React from 'react';

import Layout from '../../components/layout';
import SearchBar from '../../components/searchBar';
import CartSendOffer from '../../components/cartSendOffer';
import SEO from '../../components/SEO';

const Complete = () => {
  return (
    <Layout>
      <SEO title="status" />
      <SearchBar />
      <CartSendOffer />
    </Layout>
  );
};

export default Complete;
