import styled from 'styled-components/macro';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 50px;
  ${({ theme }) => theme.mq.bigTablet} {
    margin-top: 250px;
  }
`;

export const SectionContainer = styled.div`
  ${({ theme }) => theme.container};
`;

export const InformationWrapper = styled.div`
  width: 100%;
  height: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  ${({ theme }) => theme.mq.tablet} {
    height: 60vh;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    height: 480px;
  }
`;

export const SvgWrapper = styled.div`
  width: 200px;
  height: 200px;
`;

export const StyledText = styled.p`
  font-size: ${({ theme }) => theme.font.m};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.ligthgrey};
`;

export const BlankPage = styled.div`
  width: 100%;
  height: calc(100vh - 469px);
`;
