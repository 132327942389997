import React from 'react';

import Layout from '../../components/layout';
import SearchBar from '../../components/searchBar';
import DetailsFormPage from '../../components/detailsFormPage';
import SEO from '../../components/SEO';

function Details() {
  return (
    <Layout>
      <SEO title="Szczegóły" />
      <SearchBar />
      <DetailsFormPage />
    </Layout>
  );
}

export default Details;
