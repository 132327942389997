import { gql } from '@apollo/client';

export const GET_PORTFOLIO_BOX = gql`
  query GET_PORTFOLIO_BOX {
    portfolios {
      edges {
        node {
          acfPortfolio {
            shortdesc
            title
            boximage {
              sourceUrl
            }
          }
          slug
          id
        }
      }
    }
  }
`;
