import React from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { addCartForm } from '../../state/actions/cartForm';
import {
  AddNewWrapper,
  AddButton,
  CheckoutWrapper,
  CheckoutText,
  CheckoutButtonsWrapper,
  CheckoutButton,
} from '../cartPage/cartPage.styled';
import {
  StyledForm,
  FieldWrapper,
  StyledField,
  StyledError,
} from './checkoutForm.styled';

const validationSchema = Yup.object({
  name: Yup.string()
    .required('Pole wymagane')
    .matches(/^[a-zA-Z]/, 'Imię może zawierać tylko litery'),
  lastname: Yup.string()
    .required('Pole wymagane')
    .matches(/^[a-zA-Z]/, 'Nazwisko może zawierać tylko litery'),
  email: Yup.string()
    .required('Pole wymagane')
    .email('Nieprawidłowy adres email'),
  phone: Yup.string()
    .matches(/^[0-9]/, 'Nieprawidłowy numer telefonu')
    .required('Pole wymagane'),
});

function CheckoutForm() {
  const dispatch = useDispatch();
  const cartForm = useSelector((state) => state.cartForm.cartForm);
  const cartList = useSelector((state) => state.cart.cartList);

  function TotalPrice() {
    const subPrices = cartList.map((product) => {
      return { subTotal: product.quantity * product.price };
    });
    const total = subPrices.reduce((acc, product) => {
      return acc + product.subTotal;
    }, 0);
    return total;
  }

  return (
    <Formik
      initialValues={{
        name: cartForm === undefined ? '' : cartForm.name,
        lastname: cartForm === undefined ? '' : cartForm.lastname,
        email: cartForm === undefined ? '' : cartForm.email,
        phone: cartForm === undefined ? '' : cartForm.phone,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        dispatch(addCartForm(values));
      }}
    >
      {({ errors, touched }) => (
        <StyledForm>
          <FieldWrapper>
            <StyledField
              id="name"
              name="name"
              type="text"
              autoComplete="off"
              placeholder="Imię"
              isError={touched.name && errors.name}
            />
            {touched.name && errors.name ? (
              <StyledError>{errors.name}</StyledError>
            ) : null}
          </FieldWrapper>
          <FieldWrapper>
            <StyledField
              id="lastname"
              name="lastname"
              type="text"
              autoComplete="off"
              placeholder="Nazwisko"
              isError={touched.lastname && errors.lastname}
            />
            {touched.lastname && errors.lastname ? (
              <StyledError>{errors.lastname}</StyledError>
            ) : null}
          </FieldWrapper>
          <FieldWrapper>
            <StyledField
              id="email"
              name="email"
              type="email"
              autoComplete="off"
              placeholder="Email"
              isError={touched.email && errors.email}
            />
            {touched.email && errors.email ? (
              <StyledError>{errors.email}</StyledError>
            ) : null}
          </FieldWrapper>
          <FieldWrapper>
            <StyledField
              id="phone"
              name="phone"
              type="text"
              autoComplete="off"
              placeholder="Numer Telefonu"
              isError={touched.phone && errors.phone}
            />
            {touched.phone && errors.phone ? (
              <StyledError>{errors.phone}</StyledError>
            ) : null}
          </FieldWrapper>
          <AddNewWrapper>
            <AddButton to="/produkty">+ dodaj kolejny produkt</AddButton>
          </AddNewWrapper>
          <CheckoutWrapper>
            <CheckoutText>
              Wartość zamównienia netto:&nbsp;
              <CheckoutText as="span" isPrice>
                {TotalPrice().toFixed(2)}
              </CheckoutText>
            </CheckoutText>
            <CheckoutButtonsWrapper>
              <CheckoutButton to="/produkty">
                &lArr; wróć do listy produktów
              </CheckoutButton>
              <CheckoutButton as="button" isCheckout>
                DALEJ
              </CheckoutButton>
            </CheckoutButtonsWrapper>
          </CheckoutWrapper>
        </StyledForm>
      )}
    </Formik>
  );
}

export default CheckoutForm;
