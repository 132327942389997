import React from 'react';

import Layout from '../../components/layout';
import SearchBar from '../../components/searchBar';
import KauflandSupervisorTemplate from '../../components/kauflandSupervisorTemplate';
import Seo from '../../components/SEO';

const KaufLandSupervisor = () => (
  <Layout>
    <Seo title="Kaufland Opiekun" />
    <SearchBar />
    <KauflandSupervisorTemplate />
  </Layout>
);

export default KaufLandSupervisor;
