import React from 'react';
import { useSelector } from 'react-redux';

import {
  SectionWrapper,
  SectionTitle,
  ContentWrapper,
  ContentContainer,
  SectionText,
} from './summaryForm.styled';

function SummaryForm() {
  const cartForm = useSelector((state) => state.cartForm.cartForm);

  return (
    <SectionWrapper>
      <SectionTitle>Dane Kontaktowe</SectionTitle>
      <ContentWrapper>
        <ContentContainer>
          <SectionTitle isContent>Imie i nazwisko</SectionTitle>
          <SectionText>
            {cartForm.name}&nbsp;{cartForm.nazwisko}
          </SectionText>
        </ContentContainer>
        <ContentContainer>
          <SectionTitle isContent>adres Email</SectionTitle>
          <SectionText>{cartForm.email}</SectionText>
        </ContentContainer>
        <ContentContainer>
          <SectionTitle isContent>Numer telefonu</SectionTitle>
          <SectionText>{cartForm.phone}</SectionText>
        </ContentContainer>
      </ContentWrapper>
    </SectionWrapper>
  );
}

export default SummaryForm;
