import React from 'react';
import { useLocation, navigate } from '@reach/router';
import { useQuery } from '@apollo/client';
import { animateScroll as scroll } from 'react-scroll';
import { GET_CATEGORY_PRODUCTS } from '../../gql/getCategoryProducts';
import Loading from '../loading';
import CategoryMenu from '../categoryMenu';
import NoImage from '../../assets/images/no_image.png';

import {
  SectionWrapper,
  SectionContainer,
  LinkWrapper,
  CategoryLink,
  CategoryMenuWrapper,
  ProductSectionWrapper,
  ProductWrapper,
  ProductItem,
  ProductTitle,
  ImageWrapper,
  ProductImage,
  ProductButton,
  ProductPrice,
  ProductPriceText,
  PaginationScrollStart,
  ButtonWrapper,
  StyledButton,
} from './categoryPage.styled';

function CategoryPage() {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const location = useLocation();
  const slug = location.pathname.split('/')[2];
  const updateQuery = (previousResult, { fetchMoreResult }) =>
    fetchMoreResult.productCategory.products.edges.length
      ? fetchMoreResult
      : previousResult;
  const variables = {
    id: slug,
    first: 20,
    last: null,
    after: null,
    before: null,
  };
  const { loading, error, data, fetchMore } = useQuery(GET_CATEGORY_PRODUCTS, {
    variables,
  });
  if (error) return console.log(error);
  if (loading) return <Loading />;

  return (
    <>
      <LinkWrapper>
        <SectionContainer isLink>
          <PaginationScrollStart id="cat-pagination" />
          <CategoryLink to="/produkty">Produkty /&nbsp;</CategoryLink>
          <CategoryLink to={`/${data.productCategory.slug}`}>
            {data.productCategory.name}
          </CategoryLink>
        </SectionContainer>
      </LinkWrapper>
      <SectionWrapper>
        <SectionContainer>
          <CategoryMenuWrapper>
            <CategoryMenu />
          </CategoryMenuWrapper>
          <ProductSectionWrapper>
            <ProductWrapper>
              {data.productCategory.products.edges.map((cat) => (
                <ProductItem
                  key={cat.node.id}
                  onClick={() => navigate(`/produkt/${cat.node.slug}`)}
                >
                  <ProductTitle>{cat.node.name}</ProductTitle>
                  <ImageWrapper>
                    {cat.node.image === null ||
                    cat.node.image.sourceUrl === null ? (
                      <ProductImage src={NoImage} alt={cat.node.name} />
                    ) : (
                      <ProductImage
                        src={cat.node.image.sourceUrl}
                        alt={cat.node.name}
                      />
                    )}
                  </ImageWrapper>
                  {cat.node.price === null ? (
                    <>
                      <ProductPrice isSmall>
                        Dopytaj
                        <br />
                        sprzedawce
                      </ProductPrice>
                      <ProductPriceText isSmall>Cena od:</ProductPriceText>
                    </>
                  ) : (
                    <ProductPrice>
                      <ProductPrice>{cat.node.price}&nbsp;zł</ProductPrice>
                      <ProductPriceText>Cena od:</ProductPriceText>
                    </ProductPrice>
                  )}
                  <ProductButton to={`/produkt/${cat.node.slug}`}>
                    Więcej
                  </ProductButton>
                </ProductItem>
              ))}
            </ProductWrapper>
            <ButtonWrapper>
              {data.productCategory.products.pageInfo.hasPreviousPage ? (
                <StyledButton
                  type="button"
                  onClick={() => {
                    fetchMore(
                      {
                        variables: {
                          id: slug,
                          first: null,
                          after: null,
                          last: 20,
                          before:
                            data.productCategory.products.pageInfo
                              .startCursor || null,
                        },
                        updateQuery,
                      },
                      scrollToTop()
                    );
                  }}
                >
                  poprzednia
                </StyledButton>
              ) : null}
              {data.productCategory.products.pageInfo.hasNextPage ? (
                <StyledButton
                  isRight
                  type="button"
                  onClick={() => {
                    fetchMore(
                      {
                        variables: {
                          id: slug,
                          first: 20,
                          after:
                            data.productCategory.products.pageInfo.endCursor ||
                            null,
                          last: null,
                          before: null,
                        },
                        updateQuery,
                      },
                      scrollToTop()
                    );
                  }}
                >
                  nastepna
                </StyledButton>
              ) : null}
            </ButtonWrapper>
          </ProductSectionWrapper>
        </SectionContainer>
      </SectionWrapper>
    </>
  );
}

export default CategoryPage;
