import styled from 'styled-components';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
  display: ${({ isMobile }) => (isMobile ? 'block' : 'none')};
  ${({ theme }) => theme.mq.bigTablet} {
    display: ${({ isMobile }) => (isMobile ? 'none' : 'block')};
  }
`;
