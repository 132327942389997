import { gql } from '@apollo/client';

export const GET_HOME_PRODUCT = gql`
  query GET_HOME_PRODUCT {
    products(where: { featured: true }) {
      edges {
        node {
          ... on SimpleProduct {
            id
            name
            price(format: RAW)
            slug
            featuredImage {
              node {
                id
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`;
