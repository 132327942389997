import styled from 'styled-components/macro';

export const CartFormItem = styled.p`
  width: 60%;
  text-align: center;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.colors.grey};
`;

export const ProductFormWrapper = styled.div`
  width: 60%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DetailsForm = styled.form`
  width: 100%;
  height: auto;
`;

export const DetailsInput = styled.textarea`
  width: 100%;
  height: 300px;
  resize: none;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.ligthgrey};
  background-color: ${({ theme }) => theme.colors.ligthgrey};
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  margin-top: 15px;
  ${({ theme }) => theme.mq.tablet} {
    height: 200px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    height: 90px;
    margin-top: 0;
  }
  :focus {
    outline: none;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary};
  }
  :-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    line-height: 70px;
  }
`;
