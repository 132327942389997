import React from 'react';

import Layout from '../../components/layout';
import SearchBar from '../../components/searchBar';
import HomeCategory from '../../components/homeCategory';
import HomeProducts from '../../components/homeProducts';
import HeroSliderData from '../../components/heroSlider';
import CategoryDescriptionBox from '../../components/categoryDescriptionBox';
import OfferCircle from '../../components/offerCircle';
import SEO from '../../components/SEO';

function Home() {
  return (
    <Layout>
      <SEO title="Home" />
      <SearchBar />
      <HeroSliderData />
      <HomeCategory />
      <HomeProducts />
      <OfferCircle />
      <CategoryDescriptionBox />
    </Layout>
  );
}

export default Home;
