import React from 'react';
import { useLocation } from '@reach/router';
import { useQuery } from '@apollo/client';

import { GET_PRODUCT } from '../../gql/getProduct';
import Layout from '../../components/layout';
import SearchBar from '../../components/searchBar';
import ProductPage from '../../components/productPage';
import Loading from '../../components/loading';
import SEO from '../../components/SEO';

function Category() {
  const location = useLocation();
  const slug = location.pathname.split('/')[2];
  const variables = {
    id: slug,
  };
  const { loading, error, data } = useQuery(GET_PRODUCT, {
    variables,
  });
  if (error) return console.log(error);
  if (loading) return <Loading />;

  return (
    <Layout>
      <SEO title={data.product.name} />
      <SearchBar />
      <ProductPage product={data} />
    </Layout>
  );
}

export default Category;
