import React from 'react';

import Layout from '../../components/layout';
import SearchBar from '../../components/searchBar';
import KauflandOrder from '../../components/kauflandOrder';
import Seo from '../../components/SEO';

const KauflandHowOrder = () => (
  <Layout>
    <Seo title="Kaufland Jak Zamawiać" />
    <SearchBar />
    <KauflandOrder />
  </Layout>
);

export default KauflandHowOrder;
