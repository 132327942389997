import styled from 'styled-components/macro';
import { Link } from '@reach/router';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-flow: column;
  ${({ theme }) => theme.mq.bigTablet} {
    flex-flow: row;
  }
`;

export const SectionRow = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  ${({ theme }) => theme.mq.bigTablet} {
    width: ${({ isFirst }) => (isFirst ? '25%' : '75%')};
  }
`;

export const FirstSectionItem = styled.div`
  width: calc(100% - 20px);
  height: 570px;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 10px;
  padding: 10px;
  flex-direction: column;
  position: relative;
  display: none;
  cursor: pointer;
  ${({ theme }) => theme.mq.bigTablet} {
    display: flex;
  }
`;

export const SectionItem = styled.div`
  width: calc(100% - 20px);
  height: 200px;
  display: flex;
  justify-content: ${({ isLast }) => (isLast ? 'center' : 'flex-start')};
  align-items: ${({ isLast }) => (isLast ? 'center' : 'flex-start')};
  background-color: ${({ theme }) => theme.colors.white};
  margin: 10px;
  padding: 10px;
  position: relative;
  cursor: pointer;
  @media (min-width: 460px) {
    height: 250px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    width: calc(50% - 20px);
    height: 275px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    width: calc(33.3% - 20px);
    height: 275px;
  }
`;

export const StyledImage = styled.img`
  width: 180px;
  height: 180px;
  margin-left: auto;
  @media (min-width: 460px) {
    width: 238px;
    height: 238px;
  }
  @media (min-width: 800px) {
    width: 60%;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    width: ${({ isFirst }) => (isFirst ? '100%' : '150px')};
    height: ${({ isFirst }) => (isFirst ? 'auto' : '150px')};
  }
  ${({ theme }) => theme.mq.desktop} {
    width: ${({ isFirst }) => (isFirst ? '100%' : '200px')};
    height: ${({ isFirst }) => (isFirst ? 'auto' : '200px')};
  }
`;

export const Title = styled.h2`
  width: ${({ isCenter }) => (isCenter ? '100%' : '40%')};
  font-size: ${({ theme, isCenter }) =>
    isCenter ? theme.font.xxl : theme.font.xs};
  color: ${({ theme }) => theme.colors.secondary};
  text-align: ${({ isCenter }) => (isCenter ? 'center' : 'left')};
  height: auto;
`;

export const StyledButton = styled(Link)`
  padding: 5px 40px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.secondary};
  position: absolute;
  bottom: 10px;
  left: 10px;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ProductPrice = styled.span`
  font-size: ${({ theme, isSmall }) =>
    isSmall ? theme.font.m : theme.font.xl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  bottom: 50px;
  left: 15px;
`;

export const ProductPriceText = styled.span`
  font-size: ${({ theme }) => theme.font.m};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.secondary};
  position: absolute;
  bottom: ${({ isSmall }) => (isSmall ? '95px' : '80px')};
  left: 15px;
`;
