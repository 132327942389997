import React, { useRef, useEffect, useState } from 'react';
import gsap from 'gsap';
import PropTypes from 'prop-types';

import { ReactComponent as PeopleSvg } from '../../assets/images/people.svg';
import { ReactComponent as RocketSvg } from '../../assets/images/rocket.svg';
import {
  SectionWrapper,
  SectionContainer,
  ButtonWrapper,
  StyledButton,
  TextBoxWrapper,
  Title,
  Text,
  StyledArrow,
  StyledLink,
  SvgWrapper,
} from './heroSlider.styled';

function HeroSlider({ data, handleClick }) {
  const [location, setLocation] = useState(true);
  const svgRef = useRef(null);

  useEffect(() => {
    const [elements] = svgRef.current.children;

    const line = elements.getElementById('line');
    const rocket = elements.getElementById('rocket');
    const rocketCircle = elements.getElementById('rocketCircle');
    const rocketLine = elements.getElementById('rocketLine');

    const tl = gsap.timeline();

    if (elements.children.length === 1) {
      gsap.to(line, 3, { strokeDashoffset: 0, ease: 'none' });
    }
    if (elements.children.length === 4) {
      tl.to(line, 3, { strokeDashoffset: 0, ease: 'none' })
        .to(rocket, 1.5, { strokeDasharray: '835 0', ease: 'none' }, '-=1.5')
        .to(rocketCircle, 1, { strokeDasharray: '130 0', ease: 'none' }, '-=1')
        .to(rocketLine, 1, { strokeDashoffset: 0, ease: 'none' }, '-=0.7');
    }

    if (window.location.pathname === '/') {
      setLocation(false);
    }
  });

  return (
    <SectionWrapper>
      <SectionContainer>
        <TextBoxWrapper>
          <Title>{data.title}</Title>
          <Text>{data.text}</Text>
          <StyledLink to={data.linkSlug}>{data.link}</StyledLink>
        </TextBoxWrapper>
        <ButtonWrapper isDisplay={location}>
          <StyledButton type="button" onClick={() => handleClick()}>
            <StyledArrow isNext />
          </StyledButton>
          <StyledButton type="button" onClick={() => handleClick()}>
            <StyledArrow />
          </StyledButton>
        </ButtonWrapper>
      </SectionContainer>
      <SvgWrapper ref={svgRef}>
        {data.svg === 'people' ? <PeopleSvg /> : <RocketSvg />}
      </SvgWrapper>
    </SectionWrapper>
  );
}

HeroSlider.propTypes = {
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default HeroSlider;
