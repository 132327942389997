import { GET_CATEGORIES } from '../../actions/types';

const INITIAL_STATE = {
  categories: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CATEGORIES: {
      return { categories: action.payload };
    }
    default:
      return state;
  }
}
