import styled from 'styled-components/macro';

export const InformationWrapper = styled.p`
  width: 100%;
  height: auto;
  font-size: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.colors.grey};
  text-align: left;
  text-transform: uppercase;
`;

export const ProductItem = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
`;
