import React from 'react';

import Layout from '../../components/layout';
import SearchBar from '../../components/searchBar';
import KauflandTemplate from '../../components/kauflandTemplate';
import Seo from '../../components/SEO';

const Kaufland = () => (
  <Layout>
    <Seo title="Kaufland" />
    <SearchBar />
    <KauflandTemplate />
  </Layout>
);

export default Kaufland;
