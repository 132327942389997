import styled from 'styled-components/macro';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  ::after {
    content: '';
    width: 84%;
    height: 215px;
    border: 2px dotted ${({ theme }) => theme.colors.ligthgrey};
    margin: auto;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    z-index: -1;
    ${({ theme }) => theme.mq.bigTablet} {
      height: 95px;
    }
  }
`;

export const SectionTitle = styled.h2`
  text-align: center;
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  ::after {
    content: '';
    width: 200px;
    height: 20px;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
    display: ${({ isContent }) => (isContent ? 'none' : 'block')};
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: auto;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.ligthgrey};
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 10px 30px;
  margin-top: 10px;
  flex-flow: column;
  ${({ theme }) => theme.mq.bigTablet} {
    flex-flow: row;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin-bottom: 20px;
  :last-child {
    margin-bottom: 0;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    width: 20%;
    margin-bottom: 0;
  }
`;

export const SectionText = styled.div`
  font-size: ${({ theme }) => theme.font.xs};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-top: 5px;
`;
