import { gql } from '@apollo/client';

export const GET_CONTACT_DATA = gql`
  query GET_CONTACT_DATA {
    kontakts {
      edges {
        node {
          acfKontakt {
            adressFirstLine
            adressSecondLine
            bankAccount
            description
            emailFirst
            emailSecond
            fieldGroupName
            googleMap
            invoiceFirstLine
            invoiceSecondLine
            nip
            phone
            regon
            title
          }
        }
      }
    }
  }
`;
