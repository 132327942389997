import React from 'react';

import Layout from '../../components/layout';
import SearchBar from '../../components/searchBar';
import SummaryPage from '../../components/summaryPage';
import SEO from '../../components/SEO';

function Summary() {
  return (
    <Layout>
      <SEO title="Podsumowanie" />
      <SearchBar />
      <SummaryPage />
    </Layout>
  );
}

export default Summary;
