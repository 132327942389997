import React from 'react';
import styled from 'styled-components/macro';

import Layout from '../../components/layout';
import SearchBar from '../../components/searchBar';
import CategoryMenu from '../../components/categoryMenu';
import SearchPage from '../../components/searchPage';
import SEO from '../../components/SEO';

const SectionWrapper = styled.div`
  ${({ theme }) => theme.container};
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
  flex-wrap: wrap;
  ${({ theme }) => theme.mq.bigTablet} {
    margin-top: 250px;
    flex-wrap: nowrap;
  }
`;

const CategorySection = styled.div`
  width: 100%;
  height: auto;
  ${({ theme }) => theme.mq.bigTablet} {
    width: 30%;
  }
`;

const SearchSection = styled.div`
  width: 100%;
  height: auto;
  ${({ theme }) => theme.mq.bigTablet} {
    width: 70%;
  }
`;

const Search = () => {
  return (
    <Layout>
      <SEO title="Znajdź" />
      <SearchBar />
      <SectionWrapper>
        <CategorySection>
          <CategoryMenu />
        </CategorySection>
        <SearchSection>
          <SearchPage />
        </SearchSection>
      </SectionWrapper>
    </Layout>
  );
};

export default Search;
