import styled from 'styled-components';
import { Link } from '@reach/router';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 30px;
  ${({ theme }) => theme.mq.bigTablet} {
    margin-top: 210px;
  }
`;

export const SectionContainer = styled.div`
  ${({ theme }) => theme.container};
`;

export const InfoSection = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  ${({ theme }) => theme.mq.bigTablet} {
    flex-flow: row;
  }
`;

export const InfoItem = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  ${({ theme }) => theme.mq.bigTablet} {
    width: ${({ isOuter }) => (isOuter ? '50%' : '100%')};
  }
`;

export const InfoImage = styled.img`
  width: 100%;
  height: auto;
`;

export const TextWrapper = styled.div`
  width: 300px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  position: absolute;
  top: ${({ isFirst, isSecond, isThird }) => {
    switch (true) {
      case isFirst:
        return '30%';
      case isSecond:
        return '20%';
      case isThird:
        return '15%';
      default:
        return '30%';
    }
  }};
  left: ${({ isThird }) => (isThird ? '-15px' : '0')};
  right: 0;
  margin: auto;
  @media (min-width: 470px) {
    width: 400px;
    top: ${({ isFirst, isSecond, isThird }) => {
      switch (true) {
        case isFirst:
          return '40%';
        case isSecond:
          return '30%';
        case isThird:
          return '20%';
        default:
          return '30%';
      }
    }};
    left: 0;
  }
  ${({ theme }) => theme.mq.desktop} {
    top: ${({ isFirst, isSecond, isThird }) => {
      switch (true) {
        case isFirst:
          return '40%';
        case isSecond:
          return '35%';
        case isThird:
          return '30%';
        default:
          return '30%';
      }
    }};
  }
`;

export const TextBox = styled.div`
  font-size: ${({ theme, isTitle }) =>
    isTitle ? theme.font.m : theme.font.xs};
  font-weight: ${({ theme, isTitle }) =>
    isTitle ? theme.fontWeight.bold : theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.kaufRed};
  text-align: center;
  margin-bottom: 5px;
  @media (min-width: 470px) {
    font-size: ${({ theme, isTitle }) =>
      isTitle ? theme.font.l : theme.font.xs};
    margin-bottom: 15px;
  }
`;

export const TextLink = styled(Link)`
  width: auto;
  height: auto;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.kaufRed};
  border-radius: 10px;
  text-decoration: none;
  font-size: ${({ theme }) => theme.font.xs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.kaufRed};
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.kaufRed};
  }
  @media (min-width: 470px) {
    font-size: ${({ theme }) => theme.font.m};
  }
`;

export const SectionTitle = styled.p`
  width: 100%;
  padding: 0 20px;
  font-size: ${({ theme }) => theme.font.xl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.grey};
  text-transform: uppercase;
  text-align: center;
  position: relative;
  margin-bottom: 20px;
  &::after {
    content: '';
    width: 400px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
  }
  &::before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.ligthgrey};
    position: absolute;
    top: 15px;
    left: 0;
    z-index: -1;
  }
`;

export const ProductsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 40px 0 20px 0;
`;

export const ProductsItem = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${({ isLarge }) => (isLarge ? '20px' : '0')};
  cursor: pointer;
  ${({ theme }) => theme.mq.tablet} {
    width: ${({ isLarge }) => (isLarge ? '50%' : '33.3%')};
  }
`;

export const ProductsLink = styled(Link)`
  text-decoration: none;
`;
