import {
  ADD_TO_CART,
  DELETE_FROM_CART,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  SET_PRODUCT_QUANTITY,
  SET_PRODUCT_DETAILS,
  RESET_CART,
} from '../../actions/types';

const INITIAL_STATE = {
  cartList: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_TO_CART: {
      return { ...state, cartList: [...state.cartList, action.payload] };
    }
    case DELETE_FROM_CART: {
      return {
        ...state,
        cartList: state.cartList.filter(
          (product) => product.id !== action.payload
        ),
      };
    }
    case INCREASE_QUANTITY: {
      return {
        ...state,
        cartList: state.cartList.map((product) => {
          if (product.id === action.payload) {
            product.quantity = Number(product.quantity) + 1;
          }
          return product;
        }),
      };
    }
    case DECREASE_QUANTITY: {
      return {
        ...state,
        cartList: state.cartList.map((product) => {
          if (product.id === action.payload) {
            product.quantity = Number(product.quantity) - 1;
          }
          return product;
        }),
      };
    }
    case SET_PRODUCT_QUANTITY: {
      return {
        ...state,
        cartList: state.cartList.map((product) => {
          if (product.id === action.payload.id) {
            product.quantity = Number(action.payload.quantity);
          }
          return product;
        }),
      };
    }
    case SET_PRODUCT_DETAILS: {
      return {
        ...state,
        cartList: state.cartList.map((product) => {
          if (product.id === action.payload.id) {
            product.details = action.payload.details;
          }
          return product;
        }),
      };
    }
    case RESET_CART: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
