import React from 'react';

import info from '../../assets/images/kaufland-info.png';
import {
  SectionWrapper,
  SectionContainer,
  Title,
  TextBox,
  InfoWrapper,
  InfoTitle,
  InfoImage,
  InfoLine,
} from './kauflandOrder.styled';

const KauflandOrder = () => {
  return (
    <SectionWrapper>
      <SectionContainer>
        <Title>JAK ZAMAWIAĆ GADŻETY REKLAMOWE - KROK PO KROKU</Title>
        <TextBox isTitle>Krok po kroku</TextBox>
        <TextBox>
          Dzięki prostej grafice obok, dowiedzą się Państwo jak zamawiać gadżety
          reklamowe – krok po kroku. Zapraszamy do zapoznania się z poniższą
          grafiką i udanych zakupów! Czekamy na Państwa kontakt
        </TextBox>
        <TextBox isContact isTitle>
          W razie pytań zawsze jesteśmy dostępni:
        </TextBox>
        <TextBox isContact>Opiekun Kaufland Polska</TextBox>
        <TextBox isContact>Marcin Chabier</TextBox>
        <TextBox isContact>e-mail: biuro@progadget.pl</TextBox>
        <TextBox isContact>tel. kom.: +48 607 380 062</TextBox>
        <TextBox isContact>tel.: +48 71 792 45 69</TextBox>
        <InfoWrapper>
          <InfoTitle>Jak zamawiać artykuły promocyjne krok po kroku</InfoTitle>
          <InfoImage src={info} />
          <InfoLine />
        </InfoWrapper>
      </SectionContainer>
    </SectionWrapper>
  );
};

export default KauflandOrder;
