import { navigate } from '@reach/router';
import { ADD_SEARCH_VALUE } from '../types';

export const addSearchValue = (value, select) => (dispatch) => {
  dispatch({
    type: ADD_SEARCH_VALUE,
    payload: { value, select },
  });
  navigate('/znajdz');
};
