import { gql } from '@apollo/client';

export const GET_HOME_CATEGORY = gql`
  query GET_HOME_CATEGORY {
    productCategories(where: { descriptionLike: "home" }) {
      edges {
        node {
          id
          name
          slug
          image {
            sourceUrl
          }
        }
      }
    }
  }
`;
