import styled from 'styled-components/macro';

export const TimelineWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const TimelineRow = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  order: 1;
  ${({ theme }) => theme.mq.desktop} {
    width: 50%;
    order: ${({ isRight }) => (isRight ? '2' : '1')};
  }
`;

export const Strip = styled.div`
  width: 33.4%;
  height: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  top: 0;
  left: ${({ isFirst, isSecond, isThird }) => {
    switch (true) {
      case isFirst:
        return '0';
      case isSecond:
        return '33.4%';
      case isThird:
        return '66.8%';
      default:
        return '0';
    }
  }};
`;

export const TextWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 0;
  order: 2;
  ${({ theme }) => theme.mq.desktop} {
    width: 50%;
    order: ${({ isRight }) => (isRight ? '1' : '2')};
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
`;

export const TextBox = styled.p`
  width: 100%;
  height: auto;
  font-size: ${({ theme }) => theme.font.l};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  text-align: center;
  margin: 40px 0;
  ${({ theme }) => theme.mq.desktop} {
    text-align: ${({ isRight }) => (isRight ? 'right' : 'left')};
    padding: ${({ isRight }) => (isRight ? '0 20px 0 0' : '0 0 0 20px')};
  }
`;
