import styled from 'styled-components/macro';
import { Link } from '@reach/router';

export const ContentWrapper = styled.div`
  width: 100%;
  height: 90px;
  background-color: ${({ theme }) => theme.colors.primary};
  position: fixed;
  z-index: 9998;
  ${({ theme }) => theme.mq.bigTablet} {
    height: 50px;
  }
`;

export const ContentContainer = styled.div`
  ${({ theme }) => theme.container};
  display: ${({ isMobile }) => (isMobile ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.mq.bigTablet} {
    display: ${({ isMobile }) => (isMobile ? 'none' : 'flex')};
  }
`;

export const LogoWrapper = styled.div`
  width: 183px;
  height: auto;
  cursor: pointer;
`;

export const Logo = styled.img`
  width: 183px;
  height: auto;
  z-index: 9999;
`;

export const HamburgerWrapper = styled.div`
  width: 122px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
`;

export const MenuLink = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-decoration: none;
`;

export const CartWrapper = styled.div`
  width: 42px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9999;
`;

export const CartLink = styled(Link)`
  outline: none;
`;

export const CartImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const CartNumber = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  position: absolute;
  top: 17px;
  left: ${({ isDecimal }) => (isDecimal ? '27px' : '30px')};
  ${({ theme }) => theme.mq.bigTablet} {
    top: 24px;
  }
`;
