import React from 'react';
import offerCircle from '../../assets/images/offer_circle.png';

import OfferCircleText from '../offerCircleText';
import {
  SectionWrapper,
  SectionContainer,
  StyledImage,
} from './offerCircle.styled';

function OfferCircle() {
  return (
    <SectionWrapper>
      <SectionContainer>
        <StyledImage src={offerCircle} />
        <OfferCircleText />
      </SectionContainer>
    </SectionWrapper>
  );
}

export default OfferCircle;
