import styled from 'styled-components/macro';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 50px;
  ${({ theme }) => theme.mq.bigTablet} {
    margin-top: 250px;
  }
`;

export const SectionContainer = styled.div`
  ${({ theme }) => theme.container};
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  text-align: center;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  font-size: ${({ theme }) => theme.font.l};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  text-align: center;
  margin-bottom: 100px;
`;

export const MapRow = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: ${({ isCenter }) => (isCenter ? 'center' : 'flex-start')};
  flex-wrap: wrap;
`;

export const MapItem = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 50px;
  ${({ theme }) => theme.mq.bigTablet} {
    width: 50%;
    padding-right: ${({ isLeft }) => (isLeft ? '100px' : '0')};
  }
`;

export const IframeWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 75%;
`;

export const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

export const SubTitle = styled.h2`
  font-size: ${({ theme }) => theme.font.xl};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  padding-bottom: 5px;
  margin-bottom: 15px;
  text-align: center;
  ${({ theme }) => theme.mq.bigTablet} {
    text-align: left;
  }
`;

export const SubText = styled.p`
  font-size: ${({ theme }) => theme.font.m};
  font-weight: ${({ theme, isBold }) =>
    isBold ? theme.fontWeight.bold : theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  margin-bottom: ${({ isMargin }) => (isMargin ? '10px' : '0')};
  text-align: center;
  ${({ theme }) => theme.mq.bigTablet} {
    text-align: left;
  }
`;
