import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from '@reach/router';

import MobileMenu from '../mobileMenu';
import Hamburger from '../hamburger';
import logoWhite from '../../assets/images/logo_white.png';
import { ReactComponent as Cart } from '../../assets/images/cart.svg';
import {
  ContentWrapper,
  ContentContainer,
  LogoWrapper,
  Logo,
  HamburgerWrapper,
  MenuWrapper,
  MenuItem,
  MenuLink,
  CartWrapper,
  CartLink,
  CartNumber,
} from './header.styled';

function Header() {
  const [isMenuOpen, setMenuState] = useState(false);

  const cartNumber = useSelector((state) => state.cart.cartList);

  const toggleMobileMenu = () => {
    setMenuState(!isMenuOpen);
  };

  return (
    <ContentWrapper>
      <ContentContainer isMobile>
        <LogoWrapper onClick={() => navigate('/')}>
          <Logo src={logoWhite} />
        </LogoWrapper>
        <HamburgerWrapper>
          <CartWrapper>
            <CartLink to="/cart">
              <Cart />
              {cartNumber.length < 10 ? (
                <CartNumber>{cartNumber.length}</CartNumber>
              ) : (
                <CartNumber isDecimal>{cartNumber.length}</CartNumber>
              )}
            </CartLink>
          </CartWrapper>
          <Hamburger onClick={toggleMobileMenu} isOpen={isMenuOpen} />
        </HamburgerWrapper>
      </ContentContainer>
      <ContentContainer>
        <MenuWrapper>
          <MenuItem>
            <MenuLink to="/o-nas">O nas</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to="/">Znakowanie</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to="/realizacje">Nasze Realizacje</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to="/">Katalogi</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to="/">Doradztwo gadżetowe</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to="/kontakt">Kontakt</MenuLink>
          </MenuItem>
        </MenuWrapper>
        <CartWrapper>
          <CartLink to="/koszyk">
            <Cart />
            {cartNumber.length < 10 ? (
              <CartNumber>{cartNumber.length}</CartNumber>
            ) : (
              <CartNumber isDecimal>{cartNumber.length}</CartNumber>
            )}
          </CartLink>
        </CartWrapper>
      </ContentContainer>
      <MobileMenu isOpen={isMenuOpen} />
    </ContentWrapper>
  );
}

export default Header;
