import styled from 'styled-components/macro';

export const SectionContainer = styled.div`
  ${({ theme }) => theme.container};
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SpacerWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.colors.ligthgrey};
  margin: 100px 0;
`;

export const TextWrapper = styled.div`
  width: 50%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.font.l};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  margin-right: 30px;
`;

export const LogoWrapper = styled.div`
  width: 50%;
  height: 65px;
  display: flex;
  justify-content: flex-start;
`;

export const LogoContainer = styled.div`
  width: 200px;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: '';
    width: 100%;
    height: calc(100% + 60px);
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: -30px;
    left: 0;
    z-index: 0;
    @media (min-width: 408px) {
      height: calc(100% + 40px);
      top: -20px;
    }
  }
`;

export const Logo = styled.img`
  width: 70%;
  height: auto;
  position: relative;
  z-index: 1;
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const CompaniesWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SvgWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

export const CompaniesTextWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: -20px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
`;

export const Text = styled.span`
  width: 25%;
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  text-align: center;
`;
