import React from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { navigate } from '@reach/router';
import { animateScroll as scroll } from 'react-scroll';

import { GET_SEARCHED_PRODUCT } from '../../gql/getSearchedProduct';
import Loading from '../loading';
import NoImage from '../../assets/images/no_image.png';
import {
  ButtonWrapper,
  StyledButton,
} from '../categoryPage/categoryPage.styled';
import {
  SectionWrapper,
  SectionItem,
  ProductTitle,
  ImageWrapper,
  ProductImage,
  ProductButton,
  ProductPriceText,
  ProductPrice,
} from './searchPage.styled';

function SearchPage() {
  const searchValue = useSelector((state) => state.search.searchValue);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const updateQuery = (previousResult, { fetchMoreResult }) =>
    fetchMoreResult.products.edges.length ? fetchMoreResult : previousResult;
  const variables = {
    sku: searchValue.select === 'sku' ? searchValue.value : '',
    search: searchValue.select === 'name' ? searchValue.value : '',
    first: 20,
    last: null,
    after: null,
    before: null,
  };
  const { loading, error, data, fetchMore } = useQuery(GET_SEARCHED_PRODUCT, {
    variables,
  });
  if (error) return console.log(error);
  if (loading) return <Loading />;

  return (
    <>
      <SectionWrapper>
        {data.products.edges.length === 0 ? (
          <SectionItem isNone>
            <ProductTitle isNone>Niestety nie znaleziono produktu</ProductTitle>
            <ProductButton isNone to="/">
              Wróc do strony Głównej
            </ProductButton>
          </SectionItem>
        ) : (
          data.products.edges.map((product) => (
            <SectionItem
              key={product.node.id}
              onClick={() => navigate(`/produkt/${product.node.slug}`)}
            >
              <ProductTitle>{product.node.name}</ProductTitle>
              <ImageWrapper>
                {product.node.image === null ? (
                  <ProductImage src={NoImage} alt={product.node.name} />
                ) : (
                  <ProductImage
                    src={product.node.image.sourceUrl}
                    alt={product.node.name}
                  />
                )}
              </ImageWrapper>
              {product.node.price === null ? (
                <>
                  <ProductPrice isSmall>
                    Dopytaj
                    <br />
                    sprzedawce
                  </ProductPrice>
                  <ProductPriceText isSmall>Cena od:</ProductPriceText>
                </>
              ) : (
                <>
                  <ProductPrice>{product.node.price}&nbsp;zł</ProductPrice>
                  <ProductPriceText>Cena od:</ProductPriceText>
                </>
              )}
              <ProductButton to={`/produkt/${product.node.slug}`}>
                Więcej
              </ProductButton>
            </SectionItem>
          ))
        )}
      </SectionWrapper>
      <ButtonWrapper>
        {data.products.pageInfo.hasPreviousPage ? (
          <StyledButton
            type="button"
            onClick={() => {
              fetchMore(
                {
                  variables: {
                    first: null,
                    after: null,
                    last: 20,
                    before: data.products.pageInfo.startCursor || null,
                  },
                  updateQuery,
                },
                scrollToTop()
              );
            }}
          >
            poprzednia
          </StyledButton>
        ) : null}
        {data.products.pageInfo.hasNextPage ? (
          <StyledButton
            isRight
            type="button"
            onClick={() => {
              fetchMore(
                {
                  variables: {
                    first: 20,
                    after: data.products.pageInfo.endCursor || null,
                    last: null,
                    before: null,
                  },
                  updateQuery,
                },
                scrollToTop()
              );
            }}
          >
            nastepna
          </StyledButton>
        ) : null}
      </ButtonWrapper>
    </>
  );
}

export default SearchPage;
