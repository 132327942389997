import React from 'react';

import CheckoutForm from '../checkoutForm';
import {
  SectionWrapper,
  SectionContainer,
  LinkWrapper,
  StyledLink,
  CartLinkWrapper,
  CartLink,
} from '../cartPage/cartPage.styled';
import { FormWrapper } from './cartFormPage.styled';

function CartFormPage() {
  return (
    <SectionWrapper>
      <LinkWrapper>
        <SectionContainer isLink>
          <StyledLink to="/">Strona Główna</StyledLink>
          <StyledLink as="span">&nbsp;/&nbsp;</StyledLink>
          <StyledLink to="/koszyk">Koszyk</StyledLink>
          <StyledLink as="span">&nbsp;/&nbsp;</StyledLink>
          <StyledLink to="/kasa">Dane Kontaktowe</StyledLink>
        </SectionContainer>
      </LinkWrapper>
      <SectionContainer>
        <CartLinkWrapper>
          <CartLink to="/koszyk">1.twój koszyk</CartLink>
          <CartLink to="/kasa" isActive>
            2.dane kontaktowe
          </CartLink>
          <CartLink as="div">3.szczegóły</CartLink>
          <CartLink as="div" isLast>
            4.finalizacja
          </CartLink>
        </CartLinkWrapper>
        <FormWrapper>
          <CheckoutForm />
        </FormWrapper>
      </SectionContainer>
    </SectionWrapper>
  );
}

export default CartFormPage;
