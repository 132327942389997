import styled from 'styled-components/macro';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const SectionContainer = styled.div`
  ${({ theme }) => theme.container}
`;

export const CategoryRow = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const CategoryItem = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  margin-bottom: 50px;
  ${({ theme }) => theme.mq.bigTablet} {
    width: 50%;
  }
`;

export const CategoryTitle = styled.h2`
  font-size: ${({ theme }) => theme.font.m};
  color: ${({ theme }) => theme.colors.grey};
  font-weight: ${({ theme }) => theme.colors.regular};
  padding-bottom: 7px;
  border-bottom: 3px solid ${({ theme }) => theme.colors.secondary};
`;

export const CategoryText = styled.h2`
  font-size: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.colors.grey};
  font-weight: ${({ theme }) => theme.colors.regular};
  padding: 10px 0;
`;

export const CategoryImage = styled.img`
  width: 30%;
  height: auto;
`;

export const CategoryTextBox = styled.div`
  width: 70%;
  height: auto;
  padding: 0 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;
