import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setProductDetails } from '../../../state/actions/cart';
import NoImage from '../../../assets/images/no_image.png';
import {
  CartRow,
  ProductWrapper,
  ImageWrapper,
  StyledImage,
  ProductTextWrapper,
  ProductTitle,
} from '../../cartProductList/cartProductList.styled';
import {
  DetailsInput,
  DetailsForm,
} from '../desktop/detailsFormDesktop.styled';
import {
  CartInformationItem,
  InformationWrapper,
  ProductItem,
} from './detailsFormMobile.styled';

function DetailsFormMobile() {
  const dispatch = useDispatch();
  const cartList = useSelector((state) => state.cart.cartList);

  const handleChange = (e) => {
    if (cartList.map((product) => product.details !== e.target.value)) {
      dispatch(setProductDetails(e.target.id, e.target.value));
    }
    return null;
  };

  return (
    <>
      {cartList.map((product) => (
        <CartRow key={product.id}>
          <ProductItem>
            <CartInformationItem>dane produktu</CartInformationItem>
            <InformationWrapper>
              <ProductWrapper>
                <ImageWrapper>
                  {product.image === null ? (
                    <StyledImage src={NoImage} alt={product.name} />
                  ) : (
                    <StyledImage src={product.image.sourceUrl} />
                  )}
                </ImageWrapper>
                <ProductTextWrapper>
                  <ProductTitle as="h2" isTitle>
                    {product.name}
                  </ProductTitle>
                  <ProductTitle>Numer produktu: {product.sku}</ProductTitle>
                  {/* <ProductTitle>
                    Kolor: {product.attributes[0].options}
                  </ProductTitle> */}
                </ProductTextWrapper>
              </ProductWrapper>
            </InformationWrapper>
          </ProductItem>
          <ProductItem>
            <CartInformationItem>szczegóły produktu</CartInformationItem>
            <InformationWrapper>
              <DetailsForm>
                <DetailsInput
                  type="text"
                  id={product.id}
                  value={product.details}
                  onChange={handleChange}
                  placeholder="Podaj Tutaj szczęgóły znakowania takie jak rodzaj, wielkość oraz inne informacje dotyczące produktu"
                />
              </DetailsForm>
            </InformationWrapper>
          </ProductItem>
        </CartRow>
      ))}
    </>
  );
}

export default DetailsFormMobile;
