import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import arrowDown from '../../assets/images/arrow_down.png';
import logoColor from '../../assets/images/logo_color.png';
import SearchForm from '../searchForm';
import {
  CategoryNavBar,
  CategoryItem,
  CategoryLink,
} from '../categoryMenu/categoryMenu.styled';
import {
  ContentWrapper,
  ContentContainer,
  CatalogButton,
  ButtonWrapper,
  ButtonText,
  ButtonIcon,
  LogoWrapper,
  LogoLink,
  Logo,
  MenuWrapper,
} from './searchBar.styled';

function SearchBar() {
  const categories = useSelector((state) => state.categories.categories);
  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <ContentWrapper>
      <ContentContainer>
        <LogoWrapper>
          <LogoLink to="/">
            <Logo src={logoColor} />
          </LogoLink>
        </LogoWrapper>
        <CatalogButton onClick={() => setMenuVisible(!menuVisible)}>
          <ButtonWrapper>
            <ButtonText>Kategorie Produktów</ButtonText>
            <ButtonIcon src={arrowDown} isVisible={menuVisible} />
          </ButtonWrapper>
          <MenuWrapper isVisible={menuVisible}>
            <CategoryNavBar>
              {categories.map((cat) => (
                <CategoryItem key={cat.node.slug}>
                  <CategoryLink to={`/kategoria/${cat.node.slug}`}>
                    {cat.node.name}
                  </CategoryLink>
                </CategoryItem>
              ))}
            </CategoryNavBar>
          </MenuWrapper>
        </CatalogButton>
        <CatalogButton isBlue>
          <ButtonWrapper>
            <ButtonText>Katalogi Produktów</ButtonText>
            <ButtonIcon src={arrowDown} />
          </ButtonWrapper>
        </CatalogButton>
        <SearchForm once />
      </ContentContainer>
    </ContentWrapper>
  );
}

export default SearchBar;
