import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setProductDetails } from '../../../state/actions/cart';
import NoImage from '../../../assets/images/no_image.png';
import {
  ProductWrapper,
  ProductTextWrapper,
  ProductTitle,
  StyledImage,
  CartRow,
  CartInformationItem,
  ImageWrapper,
} from '../../cartProductList/cartProductList.styled';
import {
  CartFormItem,
  ProductFormWrapper,
  DetailsInput,
  DetailsForm,
} from './detailsFormDesktop.styled';

function DetailsFormDesktop() {
  const dispatch = useDispatch();
  const cartList = useSelector((state) => state.cart.cartList);

  const handleChange = (e) => {
    if (cartList.map((product) => product.details !== e.target.value)) {
      dispatch(setProductDetails(e.target.id, e.target.value));
    }
    return null;
  };

  return (
    <>
      <CartRow isFirst>
        <CartInformationItem isFirst>Dane Produktu</CartInformationItem>
        <CartFormItem>Szczegóły Produktu</CartFormItem>
      </CartRow>
      {cartList.map((product) => (
        <CartRow key={product.id}>
          <ProductWrapper>
            <ImageWrapper>
              {product.images === null ? (
                <StyledImage src={NoImage} alt={product.name} />
              ) : (
                <StyledImage src={product.image.sourceUrl} alt={product.name} />
              )}
            </ImageWrapper>
            <ProductTextWrapper>
              <ProductTitle as="h2" isTitle>
                {product.name}
              </ProductTitle>
              <ProductTitle>Numer produktu: {product.sku}</ProductTitle>
              {/* <ProductTitle>
                Kolor: {product.attributes[0].options}
              </ProductTitle> */}
            </ProductTextWrapper>
          </ProductWrapper>
          <ProductFormWrapper>
            <DetailsForm>
              <DetailsInput
                type="text"
                id={product.id}
                value={product.details}
                onChange={handleChange}
                placeholder="Podaj Tutaj szczęgóły znakowania takie jak rodzaj, wielkość oraz inne informacje dotyczące produktu"
              />
            </DetailsForm>
          </ProductFormWrapper>
        </CartRow>
      ))}
    </>
  );
}

export default DetailsFormDesktop;
