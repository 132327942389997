import React from 'react';

import Layout from '../../components/layout';
import SearchBar from '../../components/searchBar';
import PortfolioItemPage from '../../components/portfolioItemPage';
import SEO from '../../components/SEO';

function PortfolioItem() {
  return (
    <Layout>
      <SEO title="Portfolio" />
      <SearchBar />
      <PortfolioItemPage />
    </Layout>
  );
}

export default PortfolioItem;
