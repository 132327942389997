import styled from 'styled-components/macro';

export const Svg = styled.svg`
  height: auto;
  position: absolute;
  width: 900px;
  top: -50px;
  left: 40px;
  display: none;
  ${({ theme }) => theme.mq.bigTablet} {
    display: block;
  }
  ${({ theme }) => theme.mq.desktop} {
    left: 135px;
  }
  ${({ theme }) => theme.mq.bigDesktop} {
    width: 1200px;
    top: -80px;
    left: 90px;
  }
  .st0 {
    fill: none;
    stroke: #f2f2f2;
    stroke-width: 9;
    stroke-miterlimit: 10;
  }
  .st1 {
    fill: #f2f2f2;
    opacity: 0;
  }
  .st2 {
    fill: none;
    stroke: #f2f2f2;
    stroke-width: 2;
    stroke-miterlimit: 10;
    opacity: 0;
  }
  .st4 {
    enable-background: new 0 0 1178.7 673;
  }
`;
