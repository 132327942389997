import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { some } from 'lodash';
import gsap from 'gsap';

import RecommendedProduct from '../recommendedProduct';
import { ReactComponent as CartAddSvg } from '../../assets/images/cartAdd.svg';
import { ReactComponent as CartAddedSvg } from '../../assets/images/cartAdded.svg';
import NoImage from '../../assets/images/no_image.png';
import DescriptionIcon from '../../assets/images/description_icon.png';
import ColorIcon from '../../assets/images/color_icon.png';
import SpecIcon from '../../assets/images/spec_icon.png';
import MarkingIcon from '../../assets/images/marking_icon.png';
import { addToCart } from '../../state/actions/cart';
import {
  ProductWrapper,
  ProductTitle,
  ProductText,
  ProductPrice,
  ProductContainer,
  ImageWrapper,
  ProductInformationWrapper,
  ProductImage,
  ProductIcon,
  PriceWrapper,
  ProductRow,
  ProductItem,
  ProductBox,
  ColorBoxWrapper,
  RecommendedProductWrapper,
  CartWrapper,
  CartLink,
  SvgWrapper,
} from './productPage.styled';
import {
  LinkWrapper,
  SectionContainer,
  CategoryLink,
} from '../categoryPage/categoryPage.styled';

function ProductPage({ product }) {
  const dispatch = useDispatch();
  const cartList = useSelector((state) => state.cart.cartList);

  const cartAddRef = useRef(null);
  const cartAddedRef = useRef(null);

  useEffect(() => {
    if (some(cartList, { id: product.product.id })) {
      const [elements] = cartAddedRef.current.children;

      const text = elements.getElementById('text');
      const circle = elements.getElementById('circle');

      const tl = gsap.timeline();

      tl.to(circle, 1, { strokeDashoffset: 0 }).staggerFrom(
        text.children,
        1,
        { y: -50, opacity: 0 },
        0.15,
        '-=1'
      );
    } else {
      const cartAdd = cartAddRef.current.children[0];
      const arrow = cartAddRef.current.children[0].children[0];

      const tl = gsap.timeline({ paused: true, repeat: -1, yoyo: true });

      cartAdd.addEventListener('mouseenter', () => tl.play());
      cartAdd.addEventListener('mouseleave', () => tl.restart().pause());
      cartAdd.addEventListener('touchstart', () => tl.play());
      cartAdd.addEventListener('touchend', () => tl.restart().pause());

      tl.to(arrow, 0.5, { y: 10, ease: 'none' });
    }
  });

  const handleClick = () => {
    dispatch(addToCart(product.product));
  };

  console.log(product);

  return (
    <>
      <LinkWrapper>
        <SectionContainer isLink>
          <CategoryLink to="/produkty">Produkty/&nbsp;</CategoryLink>
          {product.product.productCategories.edges.length === 0 ? null : (
            <CategoryLink
              to={`/kategoria/${product.product.productCategories.edges[0].node.slug}`}
            >
              {product.product.productCategories.edges[0].node.name}
            </CategoryLink>
          )}
        </SectionContainer>
      </LinkWrapper>
      <ProductWrapper>
        <ProductTitle>{product.product.name}</ProductTitle>
        <ProductContainer>
          <ImageWrapper>
            {product.product.image === null ? (
              <ProductImage src={NoImage} alt={product.product.name} />
            ) : (
              <ProductImage
                src={product.product.image.sourceUrl}
                alt={product.product.name}
              />
            )}
          </ImageWrapper>
          <ProductInformationWrapper>
            <PriceWrapper>
              <ProductText isFirst>
                Numer Magazynowy: {product.product.sku}
              </ProductText>
              {product.product.price === null ? (
                <>
                  <ProductText isSecond>
                    Cena od:&nbsp;
                    <ProductPrice>dopytaj sprzedawce</ProductPrice>
                  </ProductText>
                </>
              ) : (
                <>
                  <ProductText isSecond>
                    Cena od:&nbsp;
                    <ProductPrice>{product.product.price}</ProductPrice>
                    &nbsp;netto
                  </ProductText>
                </>
              )}
            </PriceWrapper>
            <ProductRow>
              <ProductItem>
                <ImageWrapper isIcon>
                  <ProductIcon src={DescriptionIcon} />
                </ImageWrapper>
                <ProductInformationWrapper isDescription>
                  <ProductText isBold>Opis:</ProductText>
                  <ProductText>
                    {product.product.description.replace(/(<p>|<\/p>)/g, '')}
                  </ProductText>
                </ProductInformationWrapper>
              </ProductItem>
              <ProductItem>
                <ImageWrapper isIcon>
                  <ProductIcon src={ColorIcon} />
                </ImageWrapper>
                <ProductInformationWrapper isDescription>
                  <ProductText isBold>
                    Produkty dostępne w kolorach:
                  </ProductText>
                  <ColorBoxWrapper>
                    {product.product.paColorHices.edges.length === 0 ? null : (
                      <ProductBox
                        color={product.product.paColorHices.edges[0].node.name}
                      />
                    )}
                    {product.product.paColorNames.edges.length === 0 ? (
                      <ProductText>jednokolorowy</ProductText>
                    ) : (
                      <ProductText>
                        {product.product.paColorNames.edges[0].node.name}
                      </ProductText>
                    )}
                  </ColorBoxWrapper>
                </ProductInformationWrapper>
              </ProductItem>
              <ProductItem>
                <ImageWrapper isIcon>
                  <ProductIcon src={SpecIcon} />
                </ImageWrapper>
                <ProductInformationWrapper isDescription>
                  <ProductText isBold>Specyfikacja:</ProductText>
                  {product.product.weight === '' ? (
                    <ProductText noMargin>Waga: dopytaj sprzedawce</ProductText>
                  ) : (
                    <ProductText noMargin>
                      Waga: {product.product.weight} kg
                    </ProductText>
                  )}
                  {product.product.paSizes.edges.length === 0 ? (
                    <ProductText noMargin>
                      Wymiary: dopytaj sprzedawce
                    </ProductText>
                  ) : (
                    <ProductText noMargin>
                      Wymiary: {product.product.paSizes.edges[0].node.name}
                    </ProductText>
                  )}
                </ProductInformationWrapper>
              </ProductItem>
              <ProductItem>
                <ImageWrapper isIcon>
                  <ProductIcon src={MarkingIcon} />
                </ImageWrapper>
                <ProductInformationWrapper isDescription>
                  <ProductText isBold>Znakowanie:</ProductText>
                  {product.product.paMarkingNames.edges.length === 0 ? (
                    <ProductText>dopytaj sprzedawce</ProductText>
                  ) : (
                    <ProductText>
                      {product.product.paMarkingNames.edges[0].node.name}
                    </ProductText>
                  )}
                </ProductInformationWrapper>
              </ProductItem>
              <CartWrapper>
                {some(cartList, { id: product.product.id }) ? (
                  <SvgWrapper ref={cartAddedRef}>
                    <CartAddedSvg />
                  </SvgWrapper>
                ) : (
                  <CartLink type="button" onClick={handleClick}>
                    <SvgWrapper ref={cartAddRef}>
                      <CartAddSvg />
                    </SvgWrapper>
                  </CartLink>
                )}
              </CartWrapper>
            </ProductRow>
          </ProductInformationWrapper>
        </ProductContainer>
      </ProductWrapper>
      <RecommendedProductWrapper>
        <RecommendedProduct />
      </RecommendedProductWrapper>
    </>
  );
}

export default ProductPage;
