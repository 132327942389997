import React from 'react';

import mail from '../../assets/images/mail_icon.png';
import location from '../../assets/images/location_icon.png';
import phone from '../../assets/images/phone_icon.png';
import facebook from '../../assets/images/facebook_icon.png';
import {
  ContentWrapper,
  Container,
  ContentContainer,
  AboutUsContainer,
  ContactContainer,
  ContactRow,
  ContactItem,
  Icon,
  Title,
  Text,
} from './footer.styled';

function Footer() {
  return (
    <ContentWrapper>
      <Container>
        <ContentContainer>
          <AboutUsContainer>
            <Title>O nas</Title>
            <Text>
              Progadget to agencja specjalizująca się w sprzedaży, imporcie oraz
              produkcji artykułów reklamowych oraz koszy prezentowych. Progadget
              – Gadgets 4 Everyone – wszystko aby Twoja firma była widoczna
            </Text>
          </AboutUsContainer>
          <ContactContainer>
            <Title>kontakt</Title>
            <ContactRow>
              <ContactItem>
                <Icon src={location} />
                <Text isInline>ul. Powstańców Śląskich 50 53-333 Wrocław</Text>
              </ContactItem>
              <ContactItem>
                <Icon src={phone} />
                <Text isInline>tel. +48 71 792 45 69</Text>
                <Icon src={phone} />
                <Text isInline>tel. +48 535 260 320 </Text>
              </ContactItem>
              <ContactItem>
                <Icon src={mail} />
                <Text isInline>sklep@progadget.pl</Text>
                <Icon src={mail} />
                <Text isInline>biuro@progadget.pl</Text>
              </ContactItem>
              <ContactItem>
                <Icon src={facebook} />
                <Text isInline>/Progadget4all</Text>
              </ContactItem>
            </ContactRow>
          </ContactContainer>
        </ContentContainer>
      </Container>
    </ContentWrapper>
  );
}

export default Footer;
