import React, { createRef, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Svg } from './offerCircleSvg.styled';

function offerCircleSvg() {
  const CircleRef = useRef([...Array(12)].map(() => createRef()));
  const LineRef = useRef([...Array(12)].map(() => createRef()));
  const DotRef = useRef([...Array(12)].map(() => createRef()));

  useEffect(() => {
    ScrollTrigger.refresh();
    const tl = gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: CircleRef.current[0].current,
      },
    });

    tl.fromTo(
      [
        CircleRef.current[0].current,
        CircleRef.current[1].current,
        CircleRef.current[2].current,
        CircleRef.current[3].current,
        CircleRef.current[4].current,
        CircleRef.current[5].current,
        CircleRef.current[6].current,
        CircleRef.current[7].current,
        CircleRef.current[8].current,
        CircleRef.current[9].current,
        CircleRef.current[10].current,
        CircleRef.current[11].current,
      ],
      0.3,
      { opacity: 1, scale: 0, transformOrigin: 'center center' },
      { scale: 1, transformOrigin: 'center center' }
    )
      .fromTo(
        [
          LineRef.current[0].current,
          LineRef.current[1].current,
          LineRef.current[2].current,
          LineRef.current[3].current,
          LineRef.current[4].current,
          LineRef.current[5].current,
          LineRef.current[6].current,
          LineRef.current[7].current,
          LineRef.current[8].current,
          LineRef.current[9].current,
          LineRef.current[10].current,
          LineRef.current[11].current,
        ],
        1,
        { opacity: 1, scaleY: 0, transformOrigin: '100%' },
        { scaleY: 1, transformOrigin: '100%' }
      )
      .fromTo(
        [
          DotRef.current[0].current,
          DotRef.current[1].current,
          DotRef.current[2].current,
          DotRef.current[3].current,
          DotRef.current[4].current,
          DotRef.current[5].current,
          DotRef.current[6].current,
          DotRef.current[7].current,
          DotRef.current[8].current,
          DotRef.current[9].current,
          DotRef.current[10].current,
          DotRef.current[11].current,
        ],
        0.3,
        {
          opacity: 1,
          scale: 0,
          transformOrigin: 'center center',
        },
        { scale: 1, transformOrigin: 'center center' }
      );
  });

  return (
    <Svg
      version="1.1"
      id="Slider_1_"
      x="0px"
      y="0px"
      viewBox="0 0 1178.7 673"
      classNameName="st4"
    >
      <g id="Elipsa_1_xA0_Obraz_1_">
        <g>
          <g>
            <circle className="st0" cx="555.2" cy="333" r="300" />
          </g>
        </g>
        <g>
          <g>
            <circle
              ref={CircleRef.current[0]}
              className="st1"
              cx="359.5"
              cy="108"
              r="15"
            />
          </g>
        </g>
        <g>
          <g>
            <circle
              ref={CircleRef.current[1]}
              className="st1"
              cx="753"
              cy="108"
              r="15"
            />
          </g>
        </g>
        <g>
          <g>
            <circle
              ref={CircleRef.current[2]}
              className="st1"
              cx="822.4"
              cy="191.5"
              r="15"
            />
          </g>
        </g>
        <g>
          <g>
            <circle
              ref={CircleRef.current[3]}
              className="st1"
              cx="289"
              cy="191.5"
              r="15"
            />
          </g>
        </g>
        <g>
          <g>
            <circle
              ref={CircleRef.current[4]}
              className="st1"
              cx="853.8"
              cy="285.5"
              r="15"
            />
          </g>
        </g>
        <g>
          <g>
            <circle
              ref={CircleRef.current[5]}
              className="st1"
              cx="256.2"
              cy="285.5"
              r="15"
            />
          </g>
        </g>
        <g>
          <g>
            <circle
              ref={CircleRef.current[6]}
              className="st1"
              cx="259"
              cy="400.5"
              r="15"
            />
          </g>
        </g>
        <g>
          <g>
            <circle
              ref={CircleRef.current[7]}
              className="st1"
              cx="853.8"
              cy="400.5"
              r="15"
            />
          </g>
        </g>
        <g>
          <g>
            <circle
              ref={CircleRef.current[8]}
              className="st1"
              cx="812"
              cy="495.9"
              r="15"
            />
          </g>
        </g>
        <g>
          <g>
            <circle
              ref={CircleRef.current[9]}
              className="st1"
              cx="304"
              cy="495.9"
              r="15"
            />
          </g>
        </g>
        <g>
          <g>
            <circle
              ref={CircleRef.current[10]}
              className="st1"
              cx="367"
              cy="567.5"
              r="15"
            />
          </g>
        </g>
        <g>
          <g>
            <circle
              ref={CircleRef.current[11]}
              className="st1"
              cx="746"
              cy="567.5"
              r="15"
            />
          </g>
        </g>
        <line
          ref={LineRef.current[0]}
          className="st2"
          x1="352"
          y1="112.3"
          x2="271.2"
          y2="136"
        />
        <line
          ref={LineRef.current[1]}
          className="st2"
          x1="283.5"
          y1="191.5"
          x2="113.5"
          y2="197"
        />
        <line
          ref={LineRef.current[2]}
          className="st2"
          x1="125.5"
          y1="275.9"
          x2="256.2"
          y2="286.4"
        />
        <line
          ref={LineRef.current[3]}
          className="st2"
          x1="255.2"
          y1="396"
          x2="96.5"
          y2="354"
        />
        <line
          ref={LineRef.current[4]}
          className="st2"
          x1="297.5"
          y1="495.9"
          x2="136.5"
          y2="463"
        />
        <line
          ref={LineRef.current[5]}
          className="st2"
          x1="367"
          y1="566.7"
          x2="217"
          y2="552.5"
        />
        <line
          ref={LineRef.current[6]}
          className="st2"
          x1="738"
          y1="566.7"
          x2="853.8"
          y2="547"
        />
        <line
          ref={LineRef.current[7]}
          className="st2"
          x1="822.4"
          y1="495.9"
          x2="1024.5"
          y2="486"
        />
        <line
          ref={LineRef.current[8]}
          className="st2"
          x1="862.5"
          y1="396"
          x2="955.5"
          y2="385.5"
        />
        <line
          ref={LineRef.current[9]}
          className="st2"
          x1="847.5"
          y1="285.5"
          x2="998.2"
          y2="285.5"
        />
        <line
          ref={LineRef.current[10]}
          className="st2"
          x1="827"
          y1="192.6"
          x2="934.5"
          y2="207.6"
        />
        <line
          ref={LineRef.current[11]}
          className="st2"
          x1="761"
          y1="108"
          x2="847.5"
          y2="136"
        />
        <circle
          ref={DotRef.current[0]}
          className="st1"
          cx="846.6"
          cy="136.9"
          r="5.2"
        />
        <circle
          ref={DotRef.current[1]}
          className="st1"
          cx="937.4"
          cy="208.6"
          r="5.2"
        />
        <circle
          ref={DotRef.current[2]}
          className="st1"
          cx="1000"
          cy="285"
          r="5.2"
        />
        <circle
          ref={DotRef.current[3]}
          className="st1"
          cx="953.6"
          cy="385.8"
          r="5.2"
        />
        <circle
          ref={DotRef.current[4]}
          className="st1"
          cx="1026.8"
          cy="485.7"
          r="5.2"
        />
        <circle
          ref={DotRef.current[5]}
          className="st1"
          cx="855.3"
          cy="547"
          r="5.2"
        />
        <circle
          ref={DotRef.current[6]}
          className="st1"
          cx="217"
          cy="552.5"
          r="5.2"
        />
        <circle
          ref={DotRef.current[7]}
          className="st1"
          cx="136.5"
          cy="463"
          r="5.2"
        />
        <circle
          ref={DotRef.current[8]}
          className="st1"
          cx="96.5"
          cy="354"
          r="5.2"
        />
        <circle
          ref={DotRef.current[9]}
          className="st1"
          cx="125.5"
          cy="275.9"
          r="5.2"
        />
        <circle
          ref={DotRef.current[10]}
          className="st1"
          cx="113.5"
          cy="197"
          r="5.2"
        />
        <circle
          ref={DotRef.current[11]}
          className="st1"
          cx="269.6"
          cy="136"
          r="5.2"
        />
      </g>
    </Svg>
  );
}

export default offerCircleSvg;
