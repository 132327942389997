import { gql } from '@apollo/client';

export const GET_PRODUCT = gql`
  query GET_PRODUCT($id: ID!) {
    product(idType: SLUG, id: $id) {
      ... on SimpleProduct {
        id
        name
        weight
        slug
        sku
        description
        image {
          sourceUrl
        }
        paSizes {
          edges {
            node {
              name
            }
          }
        }
        paColorNames {
          edges {
            node {
              name
            }
          }
        }
        paColorHices {
          edges {
            node {
              name
            }
          }
        }
        price(format: RAW)
        productCategories {
          edges {
            node {
              name
              slug
            }
          }
        }
        paMarkingNames {
          edges {
            node {
              name
            }
          }
        }
      }
    }
  }
`;
