import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from '@reach/router';

import FailSvg from '../failSvg';
import SuccesSvg from '../succesSvg';
import { resetCart } from '../../state/actions/cart';
import { resetCartForm } from '../../state/actions/cartForm';
import {
  SectionWrapper,
  SectionContainer,
  InformationWrapper,
  SvgWrapper,
  StyledText,
  BlankPage,
} from './cartSendOffer.styled';

function CartSendOffer() {
  const dispatch = useDispatch();
  const summary = useSelector((state) => state.summary.cartFormResponse);

  useEffect(() => {
    dispatch(resetCart());
    dispatch(resetCartForm());

    setTimeout(() => {
      navigate('/');
    }, 5000);
  });

  function CheckFormSendStatus() {
    if (summary.status === 200) {
      return (
        <InformationWrapper>
          <SvgWrapper>
            <SuccesSvg />
          </SvgWrapper>
          <StyledText>Formularz został wysłany</StyledText>
        </InformationWrapper>
      );
    }
    if (summary.status === 405) {
      return (
        <InformationWrapper>
          <SvgWrapper>
            <FailSvg />
          </SvgWrapper>
          <StyledText>
            Wystąpił błąd przy wysłaniu formularze, proszę spróbować ponownie za
            chwilę
          </StyledText>
        </InformationWrapper>
      );
    }
    return <BlankPage />;
  }

  return (
    <SectionWrapper>
      <SectionContainer>
        <CheckFormSendStatus />
      </SectionContainer>
    </SectionWrapper>
  );
}

export default CartSendOffer;
