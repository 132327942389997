import React from 'react';
import { useSelector } from 'react-redux';

import CartProductListMobile from './mobile/cartProductListMobile';
import CartProductListDesktop from './desktop/cartProductListDesktop';
import {
  EmptyCartWrapper,
  EmptyCartText,
  CartPageWrapper,
} from './cartProductList.styled';

function CartProductList() {
  const cartList = useSelector((state) => state.cart.cartList);

  if (cartList.length === 0) {
    return (
      <EmptyCartWrapper>
        <EmptyCartText>Koszyk pusty</EmptyCartText>
      </EmptyCartWrapper>
    );
  }
  return (
    <>
      <CartPageWrapper isMobile>
        <CartProductListMobile />
      </CartPageWrapper>
      <CartPageWrapper>
        <CartProductListDesktop />
      </CartPageWrapper>
    </>
  );
}

export default CartProductList;
