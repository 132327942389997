import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';

import { ReactComponent as CompaniesSvg } from '../../assets/images/flpr-group.svg';
import logo from '../../assets/images/flpr-logo.png';
import {
  SpacerWrapper,
  SectionContainer,
  TextWrapper,
  Title,
  LogoWrapper,
  LogoContainer,
  Logo,
  CompaniesWrapper,
  SvgWrapper,
  CompaniesTextWrapper,
  Text,
} from './flprSection.styled';

const FlprSection = ({ data }) => {
  const companiesRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    const [elements] = companiesRef.current.children;

    const content = elements.getElementById('content');
    const links = elements.getElementById('links');

    function AnimationIn(el, key) {
      el.addEventListener('mouseenter', () => {
        gsap.to(content, 1, { opacity: 0 });
        gsap.set(textRef.current.children[key], { display: 'block' });
        gsap.fromTo(
          textRef.current.children[key],
          1,
          { opacity: 0, scale: 0 },
          { opacity: 1, scale: 1 }
        );
      });
    }

    function AnimationOut(el, key) {
      el.addEventListener('mouseout', () => {
        gsap.to(content, 1, { opacity: 1 });
        gsap.set(textRef.current.children[key], { display: 'none' });
      });
    }

    Object.keys(links.children).forEach((key) => {
      AnimationIn(links.children[key], key);
    });
    Object.keys(links.children).forEach((key) => {
      AnimationOut(links.children[key], key);
    });
  });

  return (
    <>
      <SpacerWrapper>
        <SectionContainer>
          <TextWrapper>
            <Title>NALEŻYMY DO </Title>
          </TextWrapper>
          <LogoWrapper>
            <LogoContainer>
              <Logo src={logo} />
            </LogoContainer>
          </LogoWrapper>
        </SectionContainer>
      </SpacerWrapper>
      <SectionContainer>
        <CompaniesWrapper>
          <SvgWrapper ref={companiesRef}>
            <CompaniesSvg />
            <CompaniesTextWrapper ref={textRef}>
              <Text>{data.onass.nodes[0].acfAboutUs.descEkopaleciak}</Text>
              <Text>{data.onass.nodes[0].acfAboutUs.descFol}</Text>
              <Text>{data.onass.nodes[0].acfAboutUs.descFiloslogos}</Text>
              <Text>{data.onass.nodes[0].acfAboutUs.descOutdoor}</Text>
              <Text>{data.onass.nodes[0].acfAboutUs.descProgadget}</Text>
            </CompaniesTextWrapper>
          </SvgWrapper>
        </CompaniesWrapper>
      </SectionContainer>
    </>
  );
};

export default FlprSection;
