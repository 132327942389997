import React from 'react';

import catalog from '../../assets/images/catalog.png';
import consultant from '../../assets/images/consultant.png';
import order from '../../assets/images/order.png';
import cup from '../../assets/images/cup.png';
import folder from '../../assets/images/folder.png';
import pins from '../../assets/images/pins.png';
import note from '../../assets/images/note.png';
import bidon from '../../assets/images/bidon.png';
import {
  SectionWrapper,
  SectionContainer,
  InfoSection,
  InfoItem,
  InfoImage,
  TextWrapper,
  TextBox,
  TextLink,
  SectionTitle,
  ProductsWrapper,
  ProductsItem,
  ProductsLink,
} from './kauflandTemplate.styled';

function KauflandTemplate() {
  return (
    <SectionWrapper>
      <SectionContainer>
        <InfoSection>
          <InfoItem isOuter>
            <InfoImage src={catalog} />
            <TextWrapper isFirst>
              <TextBox isTitle>KATALOG GADŻETÓW REKLAMOWYCH</TextBox>
              <TextBox>
                Odkryj setki gadżetów reklamowych. Wejdź na stronę i ciesz się
                wyborem produktów reklamowych i produktów marek premium
              </TextBox>
              <TextLink
                as="a"
                href="http://www.m-collection.tiphost.net/index.php?lang=pl&vkey=be279d20b693"
              >
                DO KATALOGU
              </TextLink>
            </TextWrapper>
          </InfoItem>
          <InfoItem isOuter>
            <InfoItem>
              <InfoImage src={consultant} />
              <TextWrapper isSecond>
                <TextBox isTitle>TWÓJ PRZEDSTAWICIEL HANDLOWY</TextBox>
                <TextLink to="/kaufland-opiekun">SKONTAKTUJ SIĘ</TextLink>
              </TextWrapper>
            </InfoItem>
            <InfoItem>
              <InfoImage src={order} />
              <TextWrapper isThird>
                <TextBox isTitle>JAK ZAMAWIAĆ GADŻETY REKLAMOWE</TextBox>
                <TextBox>KROK PO KROKU</TextBox>
                <TextLink to="/kaufland-jak-zamawiac">
                  ZOBACZ JAK ZAMAWIAĆ
                </TextLink>
              </TextWrapper>
            </InfoItem>
          </InfoItem>
        </InfoSection>
        <SectionTitle>TOP GADŻETY REKLAMOWE</SectionTitle>
        <ProductsLink to="/kaufland-top-gadzety">
          <ProductsWrapper>
            <ProductsItem isLarge>
              <InfoImage src={bidon} />
            </ProductsItem>
            <ProductsItem isLarge>
              <InfoImage src={cup} />
            </ProductsItem>
            <ProductsItem>
              <InfoImage src={pins} />
            </ProductsItem>
            <ProductsItem>
              <InfoImage src={note} />
            </ProductsItem>
            <ProductsItem>
              <InfoImage src={folder} />
            </ProductsItem>
          </ProductsWrapper>
        </ProductsLink>
      </SectionContainer>
    </SectionWrapper>
  );
}

export default KauflandTemplate;
