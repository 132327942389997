import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledWrapper,
  MenuWrapper,
  MenuItem,
  MenuLink,
} from './mobileMenu.styled';

function MobileMenu({ isOpen }) {
  return (
    <StyledWrapper isOpen={isOpen}>
      <MenuWrapper>
        <MenuItem>
          <MenuLink to="/o-nas">O nas</MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink to="/">Znakowanie</MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink to="/realizacje">Nasze Realizacje</MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink to="/">Katalogi</MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink to="/">Doradztwo Gadżetowe</MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink to="/kontakt">Kontakt</MenuLink>
        </MenuItem>
      </MenuWrapper>
    </StyledWrapper>
  );
}

MobileMenu.propTypes = {
  isOpen: PropTypes.bool,
};

MobileMenu.defaultProps = {
  isOpen: false,
};

export default MobileMenu;
