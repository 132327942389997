import styled from 'styled-components';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 30px;
  ${({ theme }) => theme.mq.bigTablet} {
    margin-top: 210px;
  }
`;

export const SectionContainer = styled.div`
  ${({ theme }) => theme.container};
`;

export const ProductWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const ProductItem = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 20px;
  ${({ theme }) => theme.mq.bigTablet} {
    width: 50%;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
`;

export const TextWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const TextBox = styled.p`
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme, isTitle }) =>
    isTitle ? theme.fontWeight.bold : theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  text-align: center;
  line-height: 1.4;
  margin-bottom: ${({ isMargin }) => (isMargin ? '15px' : '5px')};
  &:first-child {
    margin-top: 20px;
  }
`;
