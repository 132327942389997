import { gql } from '@apollo/client';

export const GET_CATEGORY_PRODUCTS = gql`
  query GET_CATEGORY_PRODUCTS(
    $id: ID!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    productCategory(idType: SLUG, id: $id) {
      id
      name
      slug
      products(first: $first, last: $last, after: $after, before: $before) {
        edges {
          node {
            ... on SimpleProduct {
              id
              name
              price(format: RAW)
              sku
              slug
              image {
                sourceUrl
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`;
