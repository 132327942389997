import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_DESCRIPTION_BOX } from '../../gql/getDescriptionBox';
import Loading from '../loading';

import {
  SectionWrapper,
  SectionContainer,
  CategoryRow,
  CategoryItem,
  CategoryTitle,
  CategoryTextBox,
  CategoryText,
  CategoryImage,
} from './categoryDescriptionBox.styled';

function CategoryDescriptionBox() {
  const { loading, error, data } = useQuery(GET_DESCRIPTION_BOX);
  if (error) return console.log(error);
  if (loading) return <Loading />;

  return (
    <SectionWrapper>
      <SectionContainer>
        <CategoryRow>
          {data.homeboxes.edges.map((item) => (
            <CategoryItem key={item.node.acfHomeBox.title}>
              <CategoryImage
                src={item.node.acfHomeBox.image.sourceUrl}
                alt={item.node.acfHomeBox.title}
              />
              <CategoryTextBox>
                <CategoryTitle>{item.node.acfHomeBox.title}</CategoryTitle>
                <CategoryText>
                  {item.node.acfHomeBox.description.replace(/(<p>|<\/p>)/g, '')}
                </CategoryText>
              </CategoryTextBox>
            </CategoryItem>
          ))}
        </CategoryRow>
      </SectionContainer>
    </SectionWrapper>
  );
}

export default CategoryDescriptionBox;
