import React from 'react';

import SummaryProductMobile from './mobile';
import SummaryProductDesktop from './desktop';
import { SectionWrapper } from './summaryProduct.styled';

const SummaryProduct = () => {
  return (
    <>
      <SectionWrapper isMobile>
        <SummaryProductMobile />
      </SectionWrapper>
      <SectionWrapper>
        <SummaryProductDesktop />
      </SectionWrapper>
    </>
  );
};

export default SummaryProduct;
