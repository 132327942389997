import styled from 'styled-components/macro';

export const SectionWrapper = styled.div`
  height: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  ${({ theme }) => theme.mq.bigTablet} {
    width: 100%;
  }
`;

export const FirstLineBox = styled.div`
  height: 150px;
  width: 20px;
  position: absolute;
  top: 200px;
  right: -70px;
  @media (min-width: 420px) {
    right: -105px;
  }
  @media (min-width: 480px) {
    right: -130px;
  }
  @media (min-width: 540px) {
    right: -160px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    top: 310px;
    right: -100px;
  }
  ${({ theme }) => theme.mq.tablet} {
    top: 330px;
    right: -110px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    display: none;
  }
`;

export const SecondLineBox = styled.div`
  height: 150px;
  width: 20px;
  position: absolute;
  top: 265px;
  right: -220px;
  @media (min-width: 420px) {
    right: -250px;
  }
  @media (min-width: 480px) {
    right: -280px;
  }
  @media (min-width: 540px) {
    right: -310px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    top: 405px;
  }
  ${({ theme }) => theme.mq.tablet} {
    top: 560px;
    right: -350px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    display: none;
  }
`;

export const Dot = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0;
`;

export const Line = styled.div`
  height: ${({ isSecond }) => (isSecond ? '30px' : '95px')};
  width: 2px;
  background-color: ${({ theme }) => theme.colors.white};
  margin: auto;
  ${({ theme }) => theme.mq.bigPhone} {
    height: ${({ isSecond }) => (isSecond ? '30px' : '130px')};
  }
  ${({ theme }) => theme.mq.tablet} {
    height: ${({ isSecond }) => (isSecond ? '30px' : '260px')};
  }
`;

export const FirstTextBox = styled.div`
  width: 150px;
  position: absolute;
  top: 320px;
  left: 0;
  @media (min-width: 420px) {
    left: 30px;
  }
  @media (min-width: 480px) {
    left: 60px;
  }
  @media (min-width: 480px) {
    left: 90px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    top: 470px;
    left: 40px;
  }
  ${({ theme }) => theme.mq.tablet} {
    top: 615px;
    left: 40px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    top: 40px;
    left: 90px;
  }
  ${({ theme }) => theme.mq.desktop} {
    left: 180px;
  }
  ${({ theme }) => theme.mq.bigDesktop} {
    top: 30px;
    left: 220px;
  }
`;

export const SecondTextBox = styled.div`
  width: 140px;
  position: absolute;
  top: 360px;
  left: 0;
  @media (min-width: 420px) {
    left: 30px;
  }
  @media (min-width: 480px) {
    left: 60px;
  }
  @media (min-width: 480px) {
    left: 90px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    top: 510px;
    left: 40px;
  }
  ${({ theme }) => theme.mq.tablet} {
    top: 655px;
    left: 40px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    top: 80px;
    left: -20px;
  }
  ${({ theme }) => theme.mq.desktop} {
    left: 70px;
  }
  ${({ theme }) => theme.mq.bigDesktop} {
    top: 95px;
    left: 90px;
  }
`;

export const ThirdTextBox = styled.div`
  width: 140px;
  position: absolute;
  top: 400px;
  left: 0;
  @media (min-width: 420px) {
    left: 30px;
  }
  @media (min-width: 480px) {
    left: 60px;
  }
  @media (min-width: 480px) {
    left: 90px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    top: 550px;
    left: 40px;
  }
  ${({ theme }) => theme.mq.tablet} {
    top: 695px;
    left: 40px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    width: 30px;
    top: 150px;
    left: 90px;
  }
  ${({ theme }) => theme.mq.desktop} {
    left: 190px;
  }
  ${({ theme }) => theme.mq.bigDesktop} {
    top: 190px;
    left: 170px;
  }
`;

export const FourthTextBox = styled.div`
  width: 150px;
  position: absolute;
  top: 425px;
  left: 0;
  @media (min-width: 420px) {
    left: 30px;
  }
  @media (min-width: 480px) {
    left: 60px;
  }
  @media (min-width: 480px) {
    left: 90px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    top: 575px;
    left: 40px;
  }
  ${({ theme }) => theme.mq.tablet} {
    top: 720px;
    left: 40px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    top: 190px;
    left: -30px;
  }
  ${({ theme }) => theme.mq.desktop} {
    width: 170px;
    left: 20px;
  }
  ${({ theme }) => theme.mq.bigDesktop} {
    top: 260px;
    left: 10px;
  }
`;

export const FifthTextBox = styled.div`
  width: 130px;
  position: absolute;
  top: 480px;
  left: 0;
  @media (min-width: 420px) {
    left: 30px;
  }
  @media (min-width: 480px) {
    left: 60px;
  }
  @media (min-width: 480px) {
    left: 90px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    top: 630px;
    left: 40px;
  }
  ${({ theme }) => theme.mq.tablet} {
    top: 775px;
    left: 40px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    top: 285px;
    left: 10px;
  }
  ${({ theme }) => theme.mq.desktop} {
    width: 190px;
    left: 30px;
  }
  ${({ theme }) => theme.mq.bigDesktop} {
    top: 370px;
    left: 20px;
  }
`;

export const SixthTextBox = styled.div`
  width: 150px;
  position: absolute;
  top: 550px;
  left: 0;
  @media (min-width: 420px) {
    left: 30px;
  }
  @media (min-width: 480px) {
    left: 60px;
  }
  @media (min-width: 480px) {
    left: 90px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    top: 700px;
    left: 40px;
  }
  ${({ theme }) => theme.mq.tablet} {
    top: 845px;
    left: 40px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    width: 60px;
    top: 360px;
    left: 100px;
  }
  ${({ theme }) => theme.mq.desktop} {
    left: 200px;
  }
  ${({ theme }) => theme.mq.bigDesktop} {
    top: 470px;
    left: 200px;
  }
`;

export const SeventhTextBox = styled.div`
  width: 150px;
  position: absolute;
  top: 320px;
  left: 160px;
  @media (min-width: 420px) {
    left: 190px;
  }
  @media (min-width: 480px) {
    left: 220px;
  }
  @media (min-width: 480px) {
    left: 250px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    top: 470px;
    left: 245px;
  }
  ${({ theme }) => theme.mq.tablet} {
    top: 615px;
    left: 285px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    width: 60px;
    top: 50px;
    left: 700px;
  }
  ${({ theme }) => theme.mq.desktop} {
    left: 800px;
  }
  ${({ theme }) => theme.mq.bigDesktop} {
    top: 55px;
    left: 970px;
  }
`;

export const EighthTextBox = styled.div`
  width: 150px;
  position: absolute;
  top: 345px;
  left: 160px;
  @media (min-width: 420px) {
    left: 190px;
  }
  @media (min-width: 480px) {
    left: 220px;
  }
  @media (min-width: 480px) {
    left: 250px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    top: 495px;
    left: 245px;
  }
  ${({ theme }) => theme.mq.tablet} {
    top: 640px;
    left: 285px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    top: 100px;
    left: 760px;
  }
  ${({ theme }) => theme.mq.desktop} {
    left: 850px;
  }
  ${({ theme }) => theme.mq.bigDesktop} {
    top: 130px;
    left: 1040px;
  }
`;

export const NinthTextBox = styled.div`
  width: 140px;
  position: absolute;
  top: 390px;
  left: 160px;
  @media (min-width: 420px) {
    left: 190px;
  }
  @media (min-width: 480px) {
    left: 220px;
  }
  @media (min-width: 480px) {
    left: 250px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    top: 540px;
    left: 245px;
  }
  ${({ theme }) => theme.mq.tablet} {
    top: 685px;
    left: 285px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    top: 150px;
    left: 800px;
  }
  ${({ theme }) => theme.mq.desktop} {
    left: 900px;
  }
  ${({ theme }) => theme.mq.bigDesktop} {
    top: 190px;
    left: 1110px;
  }
`;

export const TenthTextBox = styled.div`
  width: 155px;
  position: absolute;
  top: 430px;
  left: 160px;
  @media (min-width: 420px) {
    left: 190px;
  }
  @media (min-width: 480px) {
    left: 220px;
  }
  @media (min-width: 480px) {
    left: 250px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    top: 580px;
    left: 245px;
  }
  ${({ theme }) => theme.mq.tablet} {
    top: 725px;
    left: 285px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    width: 140px;
    top: 220px;
    left: 780px;
  }
  ${({ theme }) => theme.mq.desktop} {
    left: 870px;
  }
  ${({ theme }) => theme.mq.bigDesktop} {
    top: 290px;
    left: 1070px;
  }
`;

export const EleventhTextBox = styled.div`
  width: 140px;
  position: absolute;
  top: 470px;
  left: 160px;
  @media (min-width: 420px) {
    left: 190px;
  }
  @media (min-width: 480px) {
    left: 220px;
  }
  @media (min-width: 480px) {
    left: 250px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    top: 620px;
    left: 245px;
  }
  ${({ theme }) => theme.mq.tablet} {
    top: 765px;
    left: 285px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    top: 310px;
    left: 820px;
  }
  ${({ theme }) => theme.mq.desktop} {
    left: 910px;
  }
  ${({ theme }) => theme.mq.bigDesktop} {
    top: 405px;
    left: 1130px;
  }
`;

export const TwelfthTextBox = styled.div`
  width: 140px;
  position: absolute;
  top: 495px;
  left: 160px;
  @media (min-width: 420px) {
    left: 190px;
  }
  @media (min-width: 480px) {
    left: 220px;
  }
  @media (min-width: 480px) {
    left: 250px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    top: 645px;
    left: 245px;
  }
  ${({ theme }) => theme.mq.tablet} {
    top: 790px;
    left: 285px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    top: 350px;
    left: 710px;
  }
  ${({ theme }) => theme.mq.desktop} {
    left: 800px;
  }
  ${({ theme }) => theme.mq.bigDesktop} {
    top: 465px;
    left: 980px;
  }
`;

export const TextBox = styled.h2`
  font-size: ${({ theme }) => theme.font.xs};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  position: relative;
  ${({ theme }) => theme.mq.bigTablet} {
    text-align: center;
  }
  ::after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 0;
    ${({ theme }) => theme.mq.bigTablet} {
      display: none;
    }
  }
`;

export const TextWord = styled.span`
  display: inline-block;
  :first-child {
    padding-left: 15px;
    ${({ theme }) => theme.mq.bigTablet} {
      padding-left: 0;
    }
  }
`;
