import styled from 'styled-components';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
  ${({ theme }) => theme.mq.bigPhone} {
    width: 330px;
  }
`;

export const StyledForm = styled.form`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mq.bigPhone} {
    height: 57px;
  }
`;

export const StyledInput = styled.input`
  width: calc(100% - 60px);
  height: 30px;
  border: none;
  background-color: ${({ theme }) => theme.colors.ligthgrey};
  padding: 0 10px;
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  font-family: 'Century Gothic', sans-serif;
  transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out;
  ${({ theme }) => theme.mq.bigPhone} {
    height: 57px;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.grey};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    background-color: ${({ theme }) => theme.colors.white};
    outline: none;
  }
`;

export const StyledButton = styled.button`
  width: 60px;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: none;
  ${({ theme }) => theme.mq.bigPhone} {
    height: 57px;
  }
`;

export const StyledIcon = styled.img`
  width: 20px;
  height: auto;
  margin: auto;
  ${({ theme }) => theme.mq.bigPhone} {
    width: 26px;
    height: 27px;
  }
`;

export const StyledSelect = styled.select`
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 30px;
  border: 1px solid ${({ theme }) => theme.colors.ligthgrey};
  background-color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  font-family: 'Century Gothic', sans-serif;
  padding: 10px;

  ${({ theme }) => theme.mq.bigPhone} {
    height: 57px;
  }
`;

export const StyledOption = styled.option``;
