import styled from 'styled-components/macro';

export const SectionWrapper = styled.div`
  width: 100%;
  height: 600px;
  background-color: ${({ theme }) => theme.colors.primary};
  margin: 100px 0;
  ${({ theme }) => theme.mq.bigPhone} {
    height: 750px;
  }
  ${({ theme }) => theme.mq.tablet} {
    height: 900px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    height: 400px;
  }
  ${({ theme }) => theme.mq.bigDesktop} {
    height: 520px;
  }
`;

export const SectionContainer = styled.div`
  ${({ theme }) => theme.container}
  position: relative;
  text-align: center;
`;

export const StyledImage = styled.img`
  display: block;
  width: 345px;
  height: auto;
  position: absolute;
  @media (min-width: 420px) {
    left: 30px;
  }
  @media (min-width: 480px) {
    left: 60px;
  }
  @media (min-width: 540px) {
    left: 90px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    width: 100%;
    height: auto;
    left: inherit;
    position: relative;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    width: 520px;
    top: -40px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.mq.bigDesktop} {
    width: 700px;
    top: -70px;
  }
  ::after {
    content: '';
    width: 72%;
    height: 77%;
    border: 5px solid ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    position: absolute;
    top: 33px;
    left: 50px;
    ${({ theme }) => theme.mq.bigPhone} {
      width: 73%;
      top: 51px;
      left: 70px;
    }
    ${({ theme }) => theme.mq.tablet} {
      width: 72%;
      top: 70px;
      left: 99px;
    }
    ${({ theme }) => theme.mq.bigTablet} {
      display: none;
    }
  }
`;
