import React from 'react';

import Layout from '../../components/layout';
import SearchBar from '../../components/searchBar';
import CartFormPage from '../../components/cartFormPage';
import SEO from '../../components/SEO';

function Checkout() {
  return (
    <Layout>
      <SEO title="Kasa" />
      <SearchBar />
      <CartFormPage />
    </Layout>
  );
}

export default Checkout;
