import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormData from 'form-data';
import Recaptcha from 'react-recaptcha';
import { useDispatch, useSelector } from 'react-redux';

import { sendContactForm, resetSendStatus } from '../../state/actions/contact';
import {
  StyledForm,
  StyledField,
  FieldWrapper,
  FieldError,
  ButtonWrapper,
  FormButton,
  SendStatus,
} from './contactForm.styled';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('pole wymagane')
    .matches(/^[A-ŻŹĆĄŚĘŁÓŃa-zżźćńółęąś]/),
  mail: Yup.string().required('pole wymagane').email('email nie prawidłowy'),
  phone: Yup.string()
    .matches(/^[0-9]{9}/, { message: 'numer telefonu nie prawidłowy' })
    .required('pole wymagane'),
  message: Yup.string().required('pole wymagane'),
  recaptcha: Yup.string().required('pole wymagane'),
});

const ContactForm = () => {
  const contact = useSelector((state) => state.contact.contactFormResponse);
  const dispatch = useDispatch();

  function FormStatus() {
    if (contact.status === 200) {
      setTimeout(() => {
        dispatch(resetSendStatus());
      }, 5000);
      return <SendStatus>Twoja wiadomość została wysłana pomyślnie</SendStatus>;
    }
    if (contact.status === 405) {
      setTimeout(() => {
        dispatch(resetSendStatus());
      }, 5000);
      return (
        <SendStatus isError>Twoja wiadomość nie została wysłana</SendStatus>
      );
    }
    return null;
  }

  return (
    <Formik
      initialValues={{
        name: '',
        mail: '',
        phone: '',
        message: '',
        recaptcha: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const formData = new FormData();
        function convertForm(object) {
          Object.keys(object).forEach((key) =>
            formData.append(key, object[key])
          );
          return formData;
        }
        convertForm(values);
        dispatch(sendContactForm(formData));
      }}
    >
      {({ errors, touched, setFieldValue }) => (
        <>
          <StyledForm>
            <FieldWrapper>
              <StyledField
                id="name"
                name="name"
                type="text"
                autoComplete="off"
                placeholder="imię i nazwisko (wymagane)"
                isError={touched.name && errors.name}
              />
              {touched.name && errors.name ? (
                <FieldError>{errors.name}</FieldError>
              ) : null}
            </FieldWrapper>
            <FieldWrapper>
              <StyledField
                id="mail"
                name="mail"
                type="text"
                autoComplete="off"
                placeholder="adres email (wymagany)"
                isError={touched.mail && errors.mail}
              />
              {touched.mail && errors.mail ? (
                <FieldError>{errors.mail}</FieldError>
              ) : null}
            </FieldWrapper>
            <FieldWrapper>
              <StyledField
                id="phone"
                name="phone"
                type="text"
                autoComplete="off"
                placeholder="numer telefunu (wymagany)"
                isError={touched.phone && errors.phone}
              />
              {touched.phone && errors.phone ? (
                <FieldError>{errors.phone}</FieldError>
              ) : null}
            </FieldWrapper>
            <FieldWrapper>
              <StyledField
                isMessage
                id="message"
                component="textarea"
                name="message"
                type="text"
                autoComplete="off"
                placeholder="Twoja wiadomość"
                isError={touched.message && errors.message}
              />
              {touched.message && errors.message ? (
                <FieldError>{errors.message}</FieldError>
              ) : null}
            </FieldWrapper>
            <FieldWrapper>
              <Recaptcha
                sitekey="6LdF6MoZAAAAALCrcKAcFUwEjzki5_Z4aN2Ag69w"
                render="explicit"
                hl="pl"
                verifyCallback={(response) => {
                  setFieldValue('recaptcha', response);
                }}
                expiredCallback={() => setFieldValue('recaptcha', '')}
                onloadCallback={() => console.log('Recaptcha is Loaded')}
              />
              {touched.recaptcha && errors.recaptcha ? (
                <FieldError>{errors.recaptcha}</FieldError>
              ) : null}
            </FieldWrapper>
            <ButtonWrapper>
              <FormButton type="submit">Wyślij</FormButton>
            </ButtonWrapper>
            <FormStatus />
          </StyledForm>
        </>
      )}
    </Formik>
  );
};

export default ContactForm;
