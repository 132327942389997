import React from 'react';
import { useSelector } from 'react-redux';

import {
  SectionWrapper,
  CategoryNavBar,
  CategoryItem,
  CategoryLink,
} from './categoryMenu.styled';

function CategoryMenu() {
  const categories = useSelector((state) => state.categories.categories);

  return (
    <SectionWrapper>
      <CategoryNavBar>
        {categories.map((cat) => (
          <CategoryItem key={cat.node.name}>
            <CategoryLink to={`/kategoria/${cat.node.slug}`}>
              {cat.node.name}
            </CategoryLink>
          </CategoryItem>
        ))}
      </CategoryNavBar>
    </SectionWrapper>
  );
}

export default CategoryMenu;
