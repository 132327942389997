export const colors = {
  white: '#ffffff',
  black: '#000000',
  primary: '#00A5DC',
  secondary: '#ED0972',
  grey: '#4D4D4D',
  ligthgrey: '#D7D7D7',
  purple: '#D10060',
  red: '#CE0000',
  kaufRed: '#ed1c24',
};
