import styled from 'styled-components/macro';

export const CartInformationItem = styled.div`
  margin-right: 1%;
  width: ${({ isFirst }) => (isFirst ? '15%' : '10%')};
  text-align: ${({ isFirst }) => (isFirst ? 'left' : 'center')};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.colors.grey};
`;

export const CartInformationPrice = styled.div`
  width: 20%;
  margin-right: 1%;
  text-align: center;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.colors.grey};
  &:last-child {
    margin-right: 0;
  }
`;

export const CartInformationDesc = styled.div`
  width: 40%;
  margin-right: 1%;
  text-align: center;
  text-transform: uppercase;
`;

export const ProductWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mq.bigTablet} {
    width: 15%;
    margin-right: 1%;
    flex-wrap: wrap;
  }
`;

export const ProductTextWrapper = styled.div`
  width: calc(100% - 130px);
  height: auto;
  margin-left: 30px;
  ${({ theme }) => theme.mq.bigTablet} {
    width: 100%;
    margin-left: 0;
  }
`;

export const ProductDescWrapper = styled.div`
  width: 100%;
  height: 178px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  ${({ theme }) => theme.mq.bigTablet} {
    width: 40%;
    margin-right: 1%;
    justify-content: center;
  }
  ::-webkit-scrollbar {
    width: 1rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.ligthgrey};
    background-clip: content-box;
    border-radius: 15px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.secondary};
    border-radius: 15px;
  }
`;

export const QuantityWrapper = styled.div`
  width: 10%;
  height: auto;
  margin-right: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DescText = styled.p`
  font-size: ${({ theme }) => theme.font.xs};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  text-align: justify;
  padding: 20px 10px 0 0;
  ${({ theme }) => theme.mq.bigTablet} {
    padding: 20px 40px;
  }
`;

export const QuantityText = styled.p`
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.grey};
`;
