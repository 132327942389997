import { ADD_CART_FORM, RESET_CART_FORM } from '../../actions/types';

const INITIAL_STATE = {
  cartForm: {},
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_CART_FORM: {
      return { ...state, cartForm: action.payload };
    }
    case RESET_CART_FORM: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
