import formApi from '../../../apis/formApi';
import { SEND_CONTACT, SEND_CONTACT_ERROR, RESET_SEND_STATUS } from '../types';

export const sendContactForm = (values) => async (dispatch) => {
  try {
    const response = await formApi.post('130/feedback', values);
    dispatch({ type: SEND_CONTACT, payload: response });
  } catch (e) {
    dispatch({
      type: SEND_CONTACT_ERROR,
      payload: { status: 405 },
    });
  }
};

export const resetSendStatus = () => (dispatch) => {
  dispatch({ type: RESET_SEND_STATUS });
};
