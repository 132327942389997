import React from 'react';

import SummaryConvert from '../summaryConvert';
import SummaryForm from '../summaryForm';
import SummaryProduct from '../summaryProduct';
import {
  SectionWrapper,
  SectionContainer,
  LinkWrapper,
  StyledLink,
  CartLinkWrapper,
  CartLink,
} from '../cartPage/cartPage.styled';

const SummaryPage = () => (
  <SectionWrapper>
    <LinkWrapper>
      <SectionContainer isLink>
        <StyledLink to="/">Strona Główna</StyledLink>
        <StyledLink as="span">&nbsp;/&nbsp;</StyledLink>
        <StyledLink to="/koszyk">Twój Koszyk</StyledLink>
        <StyledLink as="span">&nbsp;/&nbsp;</StyledLink>
        <StyledLink to="/kasa">Dane Kontaktowe</StyledLink>
        <StyledLink as="span">&nbsp;/&nbsp;</StyledLink>
        <StyledLink to="/szczegoly">Szczegóły</StyledLink>
        <StyledLink as="span">&nbsp;/&nbsp;</StyledLink>
        <StyledLink to="/podsumowanie">Finalizacja</StyledLink>
      </SectionContainer>
    </LinkWrapper>
    <SectionContainer>
      <CartLinkWrapper>
        <CartLink to="/koszyk">1.twój koszyk</CartLink>
        <CartLink to="/kasa">2.dane kontaktowe</CartLink>
        <CartLink to="/szczegoly">3.szczegóły</CartLink>
        <CartLink to="/podsumowanie" isActive isLast>
          4.finalizacja
        </CartLink>
      </CartLinkWrapper>
      <SummaryProduct />
      <SummaryForm />
      <SummaryConvert />
    </SectionContainer>
  </SectionWrapper>
);

export default SummaryPage;
