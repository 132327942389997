import React from 'react';

import Layout from '../../components/layout';
import SearchBar from '../../components/searchBar';
import CartPage from '../../components/cartPage';
import SEO from '../../components/SEO';

const Cart = () => {
  return (
    <Layout>
      <SEO title="Koszyk" />
      <SearchBar />
      <CartPage />
    </Layout>
  );
};

export default Cart;
