import styled, { createGlobalStyle } from 'styled-components/macro';

export const GlobalStyle = createGlobalStyle`
    
    *, *::before, *::after {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    html {
        font-size: 62.5%;
    }
    body {
        margin: 0;
        font-family: "Century Gothic", sans-serif;
        font-size: 16px;
        line-height: 1.15;
        overflow-x: hidden;
        ::-webkit-scrollbar {
            width: 1.2rem
        }
  
        ::-webkit-scrollbar-track {
            background: ${({ theme }) => theme.colors.ligthgrey};
            background-clip: content-box;
            border-radius: 15px;
        }
    
        ::-webkit-scrollbar-thumb {
            background: ${({ theme }) => theme.colors.secondary};
            border-radius: 15px;
            height: 1.2rem;
        }
    }
`;

export const SiteWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
  width: 100%;
  height: auto;
`;
