import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, keywords, url }) => {
  const defaultSeo = {
    title: '',
    description: 'Progadget site',
    keywords: '',
    url: 'https://progadget.pl',
  };

  const seo = {
    title: title || defaultSeo.title,
    description: description || defaultSeo.description,
    keywords: keywords || defaultSeo.keywords,
    url: url || defaultSeo.url,
  };

  const titleTemplate = `${seo.title} · Progadget · Gadgets 4 Everyone`;

  return (
    <>
      <Helmet title={seo.title} titleTemplate={titleTemplate}>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
        <meta property="og:title" content={seo.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:description" content={seo.description} />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
      </Helmet>
    </>
  );
};

export default SEO;
