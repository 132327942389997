import styled from 'styled-components/macro';

export const EmptyCartWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const EmptyCartText = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.font.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
`;

export const CartPageWrapper = styled.div`
  width: 100%;
  height: auto;
  display: ${({ isMobile }) => (isMobile ? 'block' : 'none')};
  ${({ theme }) => theme.mq.bigTablet} {
    display: ${({ isMobile }) => (isMobile ? 'none' : 'block')};
  }
`;

export const CartRow = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ isFirst }) => (isFirst ? '20px' : '30px')};
  flex-wrap: wrap;
  border-bottom: 5px dotted ${({ theme }) => theme.colors.ligthgrey};
  :last-child {
    border-bottom: none;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    flex-wrap: nowrap;
    border-bottom: none;
  }
`;

export const CartWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
`;

export const CartInformationItem = styled.p`
  margin-right: 1%;
  width: ${({ isFirst }) => (isFirst ? '40%' : '15%')};
  text-align: ${({ isFirst }) => (isFirst ? 'left' : 'center')};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.colors.grey};
`;

export const CartInformationItemOption = styled.p`
  width: ${({ isFirst }) => (isFirst ? '20%' : '5%')};
  margin-right: 1%;
  text-align: center;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.colors.grey};
  &:last-child {
    margin-right: 0;
  }
`;

export const ProductWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
  height: auto;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mq.bigTablet} {
    width: 40%;
    margin-top: 0;
    margin-right: 1%;
  }
`;

export const QuantityWrapper = styled.div`
  width: 15%;
  height: auto;
  margin-right: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PriceWrapper = styled.div`
  width: 20%;
  height: auto;
  margin-right: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OptionWrapper = styled.div`
  width: 5%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  margin: ${({ isCenter }) => (isCenter ? '0 auto' : '0 0')};
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const ProductTextWrapper = styled.div`
  width: calc(100% - 130px);
  margin-left: 30px;
  height: auto;
`;

export const ProductTitle = styled.p`
  font-size: ${({ theme, isTitle }) =>
    isTitle ? theme.font.s : theme.font.xs};
  font-weight: ${({ theme, isTitle }) =>
    isTitle ? theme.fontWeight.bold : theme.fontWeight.regular};
  margin: ${({ isTitle }) => (isTitle ? '5px 0;' : '0 0')};
  text-align: ${({ isCenter }) => (isCenter ? 'center' : 'left')};
`;

export const PriceText = styled.p`
  font-size: ${({ theme }) => theme.font.xl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.ligthgrey};
`;

export const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const QuantityButton = styled.button`
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.ligthgrey};
  padding: 10px 15px;
`;

export const QuantityInput = styled.input`
  border-top: 1px solid ${({ theme }) => theme.colors.ligthgrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.ligthgrey};
  border-left: none;
  border-right: none;
  width: 80px;
  height: 37px;
  padding: 15px;
  font-size: ${({ theme }) => theme.font.s};
  text-align: center;
  &:focus {
    outline: none;
  }
`;
