import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import SearchIcon from '../../assets/images/search_icon.png';
import { addSearchValue } from '../../state/actions/search';
import {
  SectionWrapper,
  StyledForm,
  StyledInput,
  StyledButton,
  StyledIcon,
  StyledSelect,
  StyledOption,
} from './searchForm.styled';

const SearchForm = () => {
  const dispatch = useDispatch();
  const [searchResults, setSearchResults] = useState('');
  const [selectResults, setSelectResults] = useState('sku');

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addSearchValue(searchResults, selectResults));
  };

  const handleSelectChange = (e) => {
    setSelectResults(e.target.value);
  };

  const handleChange = (e) => {
    setSearchResults(e.target.value);
  };

  return (
    <SectionWrapper>
      <StyledForm onSubmit={(e) => handleSubmit(e)}>
        <StyledSelect onChange={(e) => handleSelectChange(e)}>
          <StyledOption value="sku">SKU</StyledOption>
          <StyledOption value="name">Nazwa</StyledOption>
        </StyledSelect>
        <StyledInput
          id="Search"
          placeholder="Czego Szukasz ?"
          onChange={(e) => handleChange(e)}
          autoComplete="off"
        />
        <StyledButton type="submit">
          <StyledIcon src={SearchIcon} />
        </StyledButton>
      </StyledForm>
    </SectionWrapper>
  );
};

export default SearchForm;
