import {
  ADD_TO_CART,
  DELETE_FROM_CART,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  SET_PRODUCT_QUANTITY,
  SET_PRODUCT_DETAILS,
  RESET_CART,
} from '../types';

export const addToCart = (productData) => (dispatch) => {
  dispatch({
    type: ADD_TO_CART,
    payload: { ...productData, quantity: 1 },
  });
};

export const deleteFromCart = (id) => (dispatch) => {
  dispatch({ type: DELETE_FROM_CART, payload: id });
};

export const increaseProductQuantity = (id) => (dispatch) => {
  dispatch({ type: INCREASE_QUANTITY, payload: id });
};

export const decreaseProductQuantity = (id) => (dispatch) => {
  dispatch({ type: DECREASE_QUANTITY, payload: id });
};

export const setProductQuantity = (id, quantity) => (dispatch) => {
  dispatch({ type: SET_PRODUCT_QUANTITY, payload: { id, quantity } });
};

export const setProductDetails = (id, details) => (dispatch) => {
  dispatch({ type: SET_PRODUCT_DETAILS, payload: { id, details } });
};

export const resetCart = () => (dispatch) => {
  dispatch({ type: RESET_CART });
};
