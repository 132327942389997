import styled from 'styled-components/macro';
import { Link } from '@reach/router';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const SectionContainer = styled.div`
  ${({ theme }) => theme.container};
  display: flex;
  justify-content: flex-start;
  align-items: ${({ isLink }) => (isLink ? 'center' : 'flex-start')};
  flex-wrap: wrap;
  ${({ theme }) => theme.mq.bigTablet} {
    flex-wrap: nowrap;
  }
`;

export const LinkWrapper = styled.div`
  width: 100%;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.ligthgrey};
  margin-top: 50px;
  ${({ theme }) => theme.mq.bigTablet} {
    margin-top: 220px;
  }
`;

export const CategoryLink = styled(Link)`
  color: ${({ theme }) => theme.colors.grey};
  text-decoration: none;
`;

export const CategoryMenuWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 50px;
  ${({ theme }) => theme.mq.bigTablet} {
    width: 30%;
  }
`;

export const ProductSectionWrapper = styled.div`
  width: 100%;
  height: auto;
  ${({ theme }) => theme.mq.bigTablet} {
    width: 70%;
    margin-top: 50px;
  }
`;

export const ProductWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  ${({ theme }) => theme.mq.bigTablet} {
    width: calc(100% - 10px);
    margin-left: 10px;
  }
`;

export const StyledButton = styled.button`
  position: absolute;
  top: 0;
  left: ${({ isRight }) => (isRight ? 'calc(100% - 116px)' : 0)};
  padding: 10px;
  font-size: ${({ theme }) => theme.font.m};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.darkGray};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  height: 46px;
  position: relative;
  margin-bottom: 50px;

  ${({ theme }) => theme.mq.bigTablet} {
    width: calc(100% - 10px);
    margin-left: 10px;
  }
`;

export const ProductItem = styled.div`
  width: calc(100% - 20px);
  height: ${({ isNone }) => (isNone ? '500px' : 'auto')};
  margin: 10px;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  display: ${({ isNone }) => (isNone ? 'flex' : 'block')};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ theme }) => theme.mq.desktop} {
    width: ${({ isNone }) =>
      isNone ? 'calc(100% - 20px)' : 'calc(50% - 20px)'};
  }
`;

export const ProductTitle = styled.h2`
  width: 100%;
  font-size: ${({ theme, isNone }) =>
    isNone ? theme.font.xxl : theme.font.xs};
  color: ${({ theme }) => theme.colors.secondary};
  text-align: ${({ isNone }) => (isNone ? 'center' : 'left')};
  height: auto;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 147px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (min-width: 480px) {
    height: 200px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    height: 242px;
  }
`;

export const ProductImage = styled.img`
  width: 147px;
  height: 100%;
  @media (min-width: 480px) {
    width: 200px;
  }
  ${({ theme }) => theme.mq.bigPhone} {
    width: 60%;
    height: 100%;
  }
  ${({ theme }) => theme.mq.tablet} {
    width: 242px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    width: 200px;
    height: 200px;
  }
  ${({ theme }) => theme.mq.bigDesktop} {
    width: 60%;
    height: 100%;
  }
`;

export const ProductButton = styled(Link)`
  padding: 5px 40px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.secondary};
  position: absolute;
  bottom: 15px;
  left: 15px;
  margin: auto;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const PaginationScrollStart = styled.span`
  width: 1px;
  height: 1px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
`;

export const PaginationItemWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
  height: auto;
  ${({ theme }) => theme.mq.bigTablet} {
    width: calc(100% - 10px);
    margin-left: 10px;
  }
`;

export const ProductPrice = styled.span`
  font-size: ${({ theme, isSmall }) =>
    isSmall ? theme.font.m : theme.font.xl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  bottom: 50px;
  left: 15px;
`;

export const ProductPriceText = styled.span`
  font-size: ${({ theme }) => theme.font.m};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.secondary};
  position: absolute;
  bottom: ${({ isSmall }) => (isSmall ? '95px' : '80px')};
  left: 15px;
`;
