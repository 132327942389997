import React from 'react';
import { useQuery } from '@apollo/client';
import { navigate } from '@reach/router';
import { shuffle } from 'lodash';

import { GET_RECOMMENDED_PRODUCT } from '../../gql/getRecommendedProduct';
import Loading from '../loading';
import NoImage from '../../assets/images/no_image.png';
import {
  ProductContainer,
  ProductTitle,
  ProductBox,
  ProductItem,
  ImageWrapper,
  ProductImage,
  ProductButton,
  ProductPrice,
  ProductPriceText,
} from './recommendedProduct.styled';

function RecommendedProduct() {
  const { loading, error, data } = useQuery(GET_RECOMMENDED_PRODUCT);
  if (error) return console.log(error);
  if (loading) return <Loading />;

  console.log(data);

  const shuffledArray = shuffle(data.products.edges);

  const fourRecommendedProduct = shuffledArray.slice(0, 4);

  console.log(fourRecommendedProduct);

  return (
    <ProductContainer>
      <ProductTitle>
        Polecane produkty w kategorii artykuły biurowe:
      </ProductTitle>
      <ProductBox>
        {fourRecommendedProduct.map((product) => (
          <ProductItem
            key={product.node.id}
            onClick={() => navigate(`/${product.node.slug}`)}
          >
            <ProductTitle isProduct>{product.node.name}</ProductTitle>
            <ImageWrapper>
              {product.node.image === null ? (
                <ProductImage src={NoImage} alt={product.node.name} />
              ) : (
                <ProductImage
                  src={product.node.image.sourceUrl}
                  alt={product.node.name}
                />
              )}
            </ImageWrapper>
            {product.node.price === null ? (
              <>
                <ProductPrice isSmall>
                  Dopytaj
                  <br />
                  sprzedawce
                </ProductPrice>
                <ProductPriceText isSmall>Cena od:</ProductPriceText>
              </>
            ) : (
              <>
                <ProductPrice>{product.node.price}&nbsp;zł</ProductPrice>
                <ProductPriceText>Cena od:</ProductPriceText>
              </>
            )}
            <ProductButton to={`/produkt/${product.node.slug}`}>
              Więcej
            </ProductButton>
          </ProductItem>
        ))}
      </ProductBox>
    </ProductContainer>
  );
}

export default RecommendedProduct;
