import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_HOME_CATEGORY } from '../../gql/getHomeCategory';
import Loading from '../loading';
// import other from '../../assets/images/z.png';

import {
  SectionWrapper,
  SectionContainer,
  CategoryRow,
  CategoryItem,
  CategoryImage,
  CategoryTitle,
  CategoryWrapper,
} from './homeCategory.styled';

function HomeCategory() {
  const { loading, error, data } = useQuery(GET_HOME_CATEGORY);
  if (error) return console.log(error);
  if (loading) return <Loading />;

  return (
    <SectionWrapper>
      <SectionContainer>
        <CategoryRow>
          {data.productCategories.edges.map((category) => (
            <CategoryItem key={category.node.id} href="">
              <CategoryWrapper>
                <CategoryImage src={category.node.image.sourceUrl} />
              </CategoryWrapper>
              <CategoryWrapper>
                <CategoryTitle>{category.node.name}</CategoryTitle>
              </CategoryWrapper>
            </CategoryItem>
          ))}
          {/* <CategoryItem href="">
            <CategoryWrapper>
              <CategoryImage src={other} alt="kategoria" />
            </CategoryWrapper>
            <CategoryWrapper>
              <CategoryTitle>Pozostałe</CategoryTitle>
            </CategoryWrapper>
          </CategoryItem> */}
        </CategoryRow>
      </SectionContainer>
    </SectionWrapper>
  );
}

export default HomeCategory;
