import styled from 'styled-components/macro';

export const Svg = styled.svg`
  width: 100%;
  height: 100%;
  .st0 {
    fill: #be1622;
    opacity: 0;
  }
  .st1 {
    fill: none;
    stroke: #be1622;
    stroke-width: 18;
    stroke-miterlimit: 10;
  }
  .st2 {
    opacity: 0;
  }
  .st3 {
    enable-background: new 0 0 458 408;
  }
`;
