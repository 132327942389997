import styled from 'styled-components/macro';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const SectionContainer = styled.div`
  ${({ theme }) => theme.container};
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  text-align: center;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  font-size: ${({ theme }) => theme.font.l};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  text-align: center;
  margin-bottom: 100px;
`;

export const BoxWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const BoxItem = styled.div`
  width: 99%;
  height: auto;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 15px;
  cursor: pointer;
  &:last-child() {
    margin-bottom: 0;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    width: 49%;
    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }
  }
  ${({ theme }) => theme.mq.desktop} {
    width: 32.3%;
    &:nth-last-child(-n + 3) {
      margin-bottom: 0;
    }
  }
`;

export const BoxTitle = styled.h2`
  font-size: ${({ theme }) => theme.font.l};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  position: relative;
  margin: 15px 0;
  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const BoxText = styled.p`
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
`;

export const BoxImage = styled.img`
  width: 100%;
  height: auto;
`;
