import React from 'react';
import styled from 'styled-components/macro';
import { Link } from '@reach/router';

import Layout from '../../components/layout';
import SearchBar from '../../components/searchBar';
import SEO from '../../components/SEO';

const SectionWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 489px);
  margin-top: 250px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled.span`
  font-size: ${({ theme }) => theme.font.xl};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
`;

const StyledLink = styled(Link)`
  font-size: ${({ theme }) => theme.font.l};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="Nie Znaleziono" />
    <SearchBar />
    <SectionWrapper>
      <Text>Strona nie istnieje</Text>
      <StyledLink to="/">przejdź do strony głównej</StyledLink>
    </SectionWrapper>
  </Layout>
);

export default NotFoundPage;
