import React from 'react';
import { useSelector } from 'react-redux';

import NoImage from '../../../assets/images/no_image.png';
import {
  CartWrapper,
  CartRow,
  ImageWrapper,
  StyledImage,
  ProductTitle,
  PriceWrapper,
  PriceText,
} from '../../cartProductList/cartProductList.styled';
import {
  CartInformationItem,
  CartInformationPrice,
  CartInformationDesc,
  ProductWrapper,
  ProductTextWrapper,
  ProductDescWrapper,
  QuantityWrapper,
  DescText,
  QuantityText,
} from './summaryProductDesktop.styled';

const SummaryProductDesktop = () => {
  const cartList = useSelector((state) => state.cart.cartList);

  const calculatePrice = (total, quantity) => {
    const price = Number(total) * quantity;
    return price.toFixed(2);
  };

  return (
    <>
      <CartRow isFirst>
        <CartInformationItem isFirst>Dane Produktu</CartInformationItem>
        <CartInformationDesc>Opis</CartInformationDesc>
        <CartInformationItem>Ilość</CartInformationItem>
        <CartInformationPrice>Cena Za Sztuke Netto</CartInformationPrice>
        <CartInformationPrice>Całkowita Wartość Netto</CartInformationPrice>
      </CartRow>
      <CartWrapper>
        {cartList.map((product) => (
          <CartRow key={product.id}>
            <ProductWrapper>
              <ImageWrapper>
                {product.image === null ? (
                  <StyledImage src={NoImage} alt={product.name} />
                ) : (
                  <StyledImage
                    src={product.image.sourceUrl}
                    alt={product.name}
                  />
                )}
              </ImageWrapper>
              <ProductTextWrapper>
                <ProductTitle as="h2" isTitle>
                  {product.name}
                </ProductTitle>
                <ProductTitle>Numer produktu: {product.sku}</ProductTitle>
                {/* <ProductTitle>
                  Kolor: {product.attributes[0].options}
                </ProductTitle> */}
              </ProductTextWrapper>
            </ProductWrapper>
            <ProductDescWrapper>
              <DescText>{product.details}</DescText>
            </ProductDescWrapper>
            <QuantityWrapper>
              <QuantityText>{product.quantity}</QuantityText>
            </QuantityWrapper>
            <PriceWrapper>
              <PriceText>{product.price}</PriceText>
            </PriceWrapper>
            <PriceWrapper>
              <PriceText>
                {calculatePrice(product.price, product.quantity)}
              </PriceText>
            </PriceWrapper>
          </CartRow>
        ))}
      </CartWrapper>
    </>
  );
};

export default SummaryProductDesktop;
