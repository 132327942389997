import React, { useState } from 'react';
import { times } from 'lodash';
import { useQuery } from '@apollo/client';
import { useLocation } from '@reach/router';
import { GET_PORTFOLIO_ITEM } from '../../gql/getPortfolioItem';
import Loading from '../loading';
import {
  SectionWrapper,
  SectionContainer,
  TitleWrapper,
  Title,
  HeroImageWrapper,
  HeroImage,
  TextWrapper,
  Text,
  SliderWrapper,
  SliderImage,
  ArrowWrapper,
  StyledButton,
  StyledArrow,
  DotsWrapper,
  Dots,
} from './portfolioItemPage.styled';

function PortfolioItemPage() {
  const location = useLocation();
  const slug = location.pathname.split('/')[2];
  const variables = {
    id: `/portfolio/${slug}`,
  };
  const [index, setIndex] = useState(0);
  const { loading, error, data } = useQuery(GET_PORTFOLIO_ITEM, { variables });
  if (error) return console.log(error);
  if (loading) return <Loading />;

  const length = data.portfolio.acfPortfolio.gallery.length - 1;

  const handleNext = () =>
    index === length ? setIndex(0) : setIndex(index + 1);
  const handlePrevious = () =>
    index === 0 ? setIndex(length) : setIndex(index - 1);

  const image = data.portfolio.acfPortfolio.gallery[index];

  function CreateDots() {
    const dots = [];
    times(data.portfolio.acfPortfolio.gallery.length, (i) =>
      dots.push(
        <Dots isBig={index === i} onClick={() => setIndex(i)} key={i} />
      )
    );
    return dots;
  }

  return (
    <SectionWrapper>
      <TitleWrapper>
        <SectionContainer>
          <Title>{data.portfolio.acfPortfolio.title}</Title>
          <HeroImageWrapper>
            <HeroImage src={data.portfolio.acfPortfolio.heroimage.sourceUrl} />
          </HeroImageWrapper>
        </SectionContainer>
      </TitleWrapper>
      <SectionContainer>
        <TextWrapper>
          <Text>{data.portfolio.acfPortfolio.longdesc}</Text>
        </TextWrapper>
        <SliderWrapper>
          <SliderImage src={image.sourceUrl} key={image.id} />
          <ArrowWrapper>
            <StyledButton type="button" onClick={() => handlePrevious()}>
              <StyledArrow isNext />
            </StyledButton>
            <StyledButton type="button" onClick={() => handleNext()}>
              <StyledArrow />
            </StyledButton>
          </ArrowWrapper>
          <DotsWrapper>
            <CreateDots />
          </DotsWrapper>
        </SliderWrapper>
      </SectionContainer>
    </SectionWrapper>
  );
}

export default PortfolioItemPage;
