import { gql } from '@apollo/client';

export const GET_ABOUTUS_DATA = gql`
  query GET_ABOUTUS_DATA {
    onass {
      nodes {
        acfAboutUs {
          text1
          text2
          text3
          image1 {
            sourceUrl
          }
          image2 {
            sourceUrl
          }
          image3 {
            sourceUrl
          }
          mail
          phone
          descEkopaleciak
          descFiloslogos
          descFol
          descOutdoor
          facebook
          descProgadget
        }
      }
    }
  }
`;
