import styled from 'styled-components/macro';

export const StyledHamburger = styled.button`
  width: 70px;
  padding: 20px;
  border: none;
  background: none;
  position: relative;
  z-index: 9999;
  :focus {
    outline: none;
  }
`;

export const InnerHamburger = styled.div`
  position: relative;
  right: 20px;
  width: 70px;
  height: 7px;
  border-radius: 5px;
  background-color: ${({ theme, isOpen }) =>
    isOpen ? 'transparent' : theme.colors.white};
  transition: background-color 0.25 ease-in;
  ::after,
  ::before {
    content: '';
    position: absolute;
    left: 0;
    width: 70px;
    height: 7px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.white};
    transition: transform 0.25s ease-in-out;
  }
  ::before {
    top: -15px;
    transform: translateY(${({ isOpen }) => (isOpen ? '15px' : '0')})
      rotate(${({ isOpen }) => (isOpen ? '45deg' : '0')});
  }
  ::after {
    top: 15px;
    transform: translateY(${({ isOpen }) => (isOpen ? '-15px' : '0')})
      rotate(${({ isOpen }) => (isOpen ? '-45deg' : '0')});
  }
`;
