import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';

import { ReactComponent as LoadingSvg } from '../../assets/images/loading.svg';
import { LoadingWrapper } from './loading.styled';

function Loading() {
  const SvgRef = useRef(null);

  useEffect(() => {
    const [elements] = SvgRef.current.children;

    const circle = elements.getElementById('circle');

    const loadingTl = gsap.timeline({ repeat: -1 });

    loadingTl.to(circle, 2, {
      rotate: '360deg',
      transformOrigin: 'center center',
      ease: 'none',
    });
  });

  return (
    <LoadingWrapper ref={SvgRef}>
      <LoadingSvg />
    </LoadingWrapper>
  );
}

export default Loading;
