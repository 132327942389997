import React from 'react';
import { navigate } from '@reach/router';
import { useQuery } from '@apollo/client';
import { GET_PORTFOLIO_BOX } from '../../gql/getPortfolioBox';
import Loading from '../loading';

import {
  SectionWrapper,
  SectionContainer,
  Title,
  Text,
  BoxWrapper,
  BoxItem,
  BoxImage,
  BoxTitle,
  BoxText,
} from './portfolioBox.styled';

function PortfolioBox() {
  const { loading, error, data } = useQuery(GET_PORTFOLIO_BOX);
  if (error) return console.log(error);
  if (loading) return <Loading />;

  return (
    <SectionWrapper>
      <SectionContainer>
        <Title>Progadget – Gadgets 4 Everyone</Title>
        <Text>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. sint
          occaecat cupidatat non proident, sunt in culpa qui officia deserunt
          mollit anim id est laborum.
        </Text>
        <BoxWrapper>
          {data.portfolios.edges.map((item) => (
            <BoxItem
              key={item.node.id}
              onClick={() => navigate(`/realizacje/${item.node.slug}`)}
            >
              <BoxImage src={item.node.acfPortfolio.boximage.sourceUrl} />
              <BoxTitle>{item.node.acfPortfolio.title}</BoxTitle>
              <BoxText>{item.node.acfPortfolio.shortdesc}</BoxText>
            </BoxItem>
          ))}
        </BoxWrapper>
      </SectionContainer>
    </SectionWrapper>
  );
}

export default PortfolioBox;
