import React, { useEffect, createRef } from 'react';
import gsap from 'gsap';

import { Svg } from './succesSvg.styled';

function SuccesSvg() {
  const circleRef = createRef();
  const birdRef = createRef();

  const tl = gsap.timeline();

  useEffect(() => {
    tl.fromTo(
      circleRef.current,
      1,
      { opacity: 1, scaleX: 0, transformOrigin: 'left' },
      { scaleX: 1, transformOrigin: 'left' }
    ).to(birdRef.current, 1, { opacity: 1 });
  });

  return (
    <Svg
      version="1.1"
      id="Warstwa_1"
      x="0px"
      y="0px"
      viewBox="0 0 458 408"
      className="st1"
    >
      <g>
        <path
          className="st0"
          ref={circleRef}
          d="M229,32.82c47.46,0,92.09,18.48,125.65,52.05c33.56,33.56,52.05,78.19,52.05,125.65s-18.48,92.09-52.05,125.65
		c-33.56,33.56-78.19,52.05-125.65,52.05s-92.09-18.48-125.65-52.05C69.79,302.6,51.3,257.98,51.3,210.52
		c0-47.46,18.48-92.09,52.05-125.65S181.54,32.82,229,32.82 M229,14.82c-108.08,0-195.7,87.62-195.7,195.7s87.62,195.7,195.7,195.7
		s195.7-87.62,195.7-195.7S337.08,14.82,229,14.82L229,14.82z"
        />
      </g>
      <g>
        <path
          className="st0"
          ref={birdRef}
          d="M289.84,219.03l-82.56,114l-44.48-53.51l37.5,15.12l11.17,4.5l8.42-8.61L289.84,219.03 M447.2,32.44
		L207.03,277.93l-103.69-41.8l104.92,126.23L447.2,32.44L447.2,32.44z"
        />
      </g>
    </Svg>
  );
}

export default SuccesSvg;
