import React from 'react';

import Layout from '../../components/layout';
import SearchBar from '../../components/searchBar';
import ContactPage from '../../components/contactPage';
import SEO from '../../components/SEO';

function Contact() {
  return (
    <Layout>
      <SEO title="Kontakt" />
      <SearchBar />
      <ContactPage />
    </Layout>
  );
}

export default Contact;
