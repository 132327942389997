import styled from 'styled-components';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 30px;
  ${({ theme }) => theme.mq.bigTablet} {
    margin-top: 210px;
  }
`;

export const SectionContainer = styled.div`
  ${({ theme }) => theme.container};
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.xl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.grey};
  text-align: center;
  margin-bottom: 20px;
`;

export const TextBox = styled.p`
  font-size: ${({ theme, isTitle }) => (isTitle ? theme.font.m : theme.font.s)};
  font-weight: ${({ theme, isTitle }) =>
    isTitle ? theme.fontWeight.bold : theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  text-align: ${({ isContact }) => (isContact ? 'center' : 'left')};
  line-height: 1.5;
  margin-bottom: 5px;
  :nth-child(3) {
    margin-bottom: 30px;
  }
`;

export const InfoWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 50px 0;
`;

export const InfoTitle = styled.p`
  padding: 0 20%;
  font-size: ${({ theme }) => theme.font.xl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
  ::after {
    content: '';
    background-color: ${({ theme }) => theme.colors.kaufRed};
    width: 80%;
    height: 200%;
    position: absolute;
    top: -50%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
  }
  ::before {
    content: '';
    background-color: ${({ theme }) => theme.colors.ligthgrey};
    width: 100%;
    height: 22px;
    position: absolute;
    top: 5px;
    left: 0;
    z-index: -1;
  }
`;

export const InfoImage = styled.img`
  width: 100%;
  height: auto;
`;

export const InfoLine = styled.div`
  width: 100%;
  height: 22px;
  background-color: ${({ theme }) => theme.colors.ligthgrey};
`;
