import styled from 'styled-components/macro';

import { ReactComponent as Arrow } from '../../assets/images/arrow.svg';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 60px;
  ${({ theme }) => theme.mq.bigTablet} {
    margin-top: 210px;
  }
`;

export const SectionContainer = styled.div`
  ${({ theme }) => theme.container};
  height: auto;
  position: relative;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  height: 500px;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.font.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  padding-top: 100px;
`;

export const HeroImageWrapper = styled.div`
  width: 100%;
  height: 400px;
  position: absolute;
  top: 170px;
  left: 0;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
  overflow: hidden;
`;

export const HeroImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
`;

export const TextWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 140px;
`;

export const Text = styled.p`
  font-size: ${({ theme }) => theme.font.m};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  line-height: 1.5;
  text-align: center;
  margin-bottom: 50px;
`;

export const SliderWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
`;

export const SliderImage = styled.img`
  width: 200px;
  height: 200px;
  @media (min-width: 450px) {
    width: 300px;
    height: 300px;
  }
  @media (min-width: 550px) {
    width: 400px;
    height: 400px;
  }
  ${({ theme }) => theme.mq.desktop} {
    width: 600px;
    height: 600px;
  }
`;

export const ArrowWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 100px;
  padding: 0px 10px;
  @media (min-width: 450px) {
    top: 150px;
  }
  @media (min-width: 550px) {
    top: 200px;
  }
  ${({ theme }) => theme.mq.tablet} {
    padding: 0px 50px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    padding: 0px 100px;
  }
  ${({ theme }) => theme.mq.desktop} {
    top: 270px;
  }
`;

export const StyledButton = styled.button`
  background: none;
  border: none;
  width: 50px;
  height: 50px;
  cursor: pointer;
  ${({ theme }) => theme.mq.bigTablet} {
    width: 100px;
    height: 100px;
  }
  &:focus {
    outline: none;
  }
`;

export const StyledArrow = styled(Arrow)`
  transform: ${({ isNext }) => (isNext ? 'rotate(180deg)' : '')};
  &:focus {
    outline: none;
  }
`;

export const DotsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const Dots = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  transform: scale(${(props) => (props.isBig ? '1.5' : '1')});
  margin-right: 10px;
  cursor: pointer;
  &:last-child() {
    margin-right: 0;
  }
`;
