import React, { createRef, useRef, useEffect } from 'react';
import gsap from 'gsap';

import OfferCircleSvg from '../offerCircleSvg';
import {
  SectionWrapper,
  FirstTextBox,
  SecondTextBox,
  ThirdTextBox,
  FourthTextBox,
  FifthTextBox,
  SixthTextBox,
  SeventhTextBox,
  EighthTextBox,
  NinthTextBox,
  TenthTextBox,
  EleventhTextBox,
  TwelfthTextBox,
  TextBox,
  TextWord,
  FirstLineBox,
  SecondLineBox,
  Dot,
  Line,
} from './offerCircleText.styled';

function offerCircleText() {
  const TextRef = useRef([...Array(12)].map(() => createRef()));
  const lineRef = useRef([...Array(2)].map(() => createRef()));
  const dotRef = useRef([...Array(2)].map(() => createRef()));

  useEffect(() => {
    const lineTl = gsap.timeline({
      paused: true,
      scrollTrigger: { trigger: TextRef.current[0].current },
    });

    const tl = gsap.timeline({
      paused: true,
      scrollTrigger: { trigger: TextRef.current[0].current },
    });

    lineTl
      .fromTo(
        [dotRef.current[0].current, dotRef.current[1].current],
        1,
        { opacity: 1, scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .fromTo(
        [lineRef.current[0].current, lineRef.current[1].current],
        1,
        { opacity: 1, scaleY: 0, transformOrigin: 'top' },
        { scaleY: 1, transformOrigin: 'top' }
      );
    tl.set(TextRef.current[0].current, { delay: 2 });
    tl.set(
      [
        TextRef.current[0].current,
        TextRef.current[1].current,
        TextRef.current[2].current,
        TextRef.current[3].current,
        TextRef.current[4].current,
        TextRef.current[5].current,
        TextRef.current[6].current,
        TextRef.current[7].current,
        TextRef.current[8].current,
        TextRef.current[9].current,
        TextRef.current[10].current,
        TextRef.current[11].current,
      ],
      { opacity: 1 }
    )
      .addLabel('textAnimationStart')
      .staggerFrom(
        TextRef.current[0].current.children,
        0.45,
        { y: -50, opacity: 0 },
        0.25,
        'textAnimationStart'
      )
      .staggerFrom(
        TextRef.current[1].current.children,
        0.45,
        { y: -50, opacity: 0 },
        0.25,
        'textAnimationStart'
      )
      .staggerFrom(
        TextRef.current[2].current.children,
        0.45,
        { y: -50, opacity: 0 },
        0.25,
        'textAnimationStart'
      )
      .staggerFrom(
        TextRef.current[3].current.children,
        0.45,
        { y: -50, opacity: 0 },
        0.25,
        'textAnimationStart'
      )
      .staggerFrom(
        TextRef.current[4].current.children,
        0.45,
        { y: -50, opacity: 0 },
        0.25,
        'textAnimationStart'
      )
      .staggerFrom(
        TextRef.current[5].current.children,
        0.45,
        { y: -50, opacity: 0 },
        0.25,
        'textAnimationStart'
      )
      .staggerFrom(
        TextRef.current[6].current.children,
        0.45,
        { y: -50, opacity: 0 },
        0.25,
        'textAnimationStart'
      )
      .staggerFrom(
        TextRef.current[7].current.children,
        0.45,
        { y: -50, opacity: 0 },
        0.25,
        'textAnimationStart'
      )
      .staggerFrom(
        TextRef.current[8].current.children,
        0.45,
        { y: -50, opacity: 0 },
        0.25,
        'textAnimationStart'
      )
      .staggerFrom(
        TextRef.current[9].current.children,
        0.45,
        { y: -50, opacity: 0 },
        0.25,
        'textAnimationStart'
      )
      .staggerFrom(
        TextRef.current[10].current.children,
        0.45,
        { y: -50, opacity: 0 },
        0.25,
        'textAnimationStart'
      )
      .staggerFrom(
        TextRef.current[11].current.children,
        0.45,
        { y: -50, opacity: 0 },
        0.25,
        'textAnimationStart'
      );
  });

  return (
    <SectionWrapper>
      <OfferCircleSvg />
      <FirstLineBox>
        <Dot ref={dotRef.current[0]} />
        <Line ref={lineRef.current[0]} />
      </FirstLineBox>
      <SecondLineBox>
        <Dot ref={dotRef.current[1]} />
        <Line ref={lineRef.current[1]} isSecond />
      </SecondLineBox>
      <FirstTextBox>
        <TextBox ref={TextRef.current[0]}>
          <TextWord>TEKSTYLIA&nbsp;</TextWord>
          <TextWord>NA&nbsp;</TextWord>
          <TextWord>KAŻDĄ&nbsp;</TextWord>
          <TextWord>PORĘ&nbsp;</TextWord>
          <TextWord>ROKU</TextWord>
        </TextBox>
      </FirstTextBox>
      <SecondTextBox>
        <TextBox ref={TextRef.current[1]}>
          <TextWord>KATALOGI&nbsp;</TextWord>
          <TextWord>OGÓLNE&nbsp;</TextWord>
          <TextWord>I &nbsp;</TextWord>
          <TextWord>E-SKLEPY</TextWord>
        </TextBox>
      </SecondTextBox>
      <ThirdTextBox>
        <TextBox ref={TextRef.current[2]}>
          <TextWord>USB</TextWord>
        </TextBox>
      </ThirdTextBox>
      <FourthTextBox>
        <TextBox ref={TextRef.current[3]}>
          <TextWord>MATERIAŁY&nbsp;</TextWord>
          <TextWord>REKLAMOWE&nbsp;</TextWord>
          <TextWord>DLA&nbsp;</TextWord>
          <TextWord>WYMAGAJĄCYCH</TextWord>
        </TextBox>
      </FourthTextBox>
      <FifthTextBox>
        <TextBox ref={TextRef.current[4]}>
          <TextWord>EKSKLUZYWNE&nbsp;</TextWord>
          <TextWord>ARTYKUŁY&nbsp;</TextWord>
          <TextWord>REKLAMOWE&nbsp;</TextWord>
          <TextWord>VIP&nbsp;</TextWord>
          <TextWord>&&nbsp;</TextWord>
          <TextWord>BUSINESS</TextWord>
        </TextBox>
      </FifthTextBox>
      <SixthTextBox>
        <TextBox ref={TextRef.current[5]}>
          <TextWord>POWERBANKI</TextWord>
        </TextBox>
      </SixthTextBox>
      <SeventhTextBox>
        <TextBox ref={TextRef.current[6]}>
          <TextWord>PORCELANA</TextWord>
        </TextBox>
      </SeventhTextBox>
      <EighthTextBox>
        <TextBox ref={TextRef.current[7]}>
          <TextWord>SZWAJCARSKA&nbsp;</TextWord>
          <TextWord>PRECYZJA&nbsp;</TextWord>
          <TextWord>I&nbsp;</TextWord>
          <TextWord>JAKOŚĆ&nbsp;</TextWord>
        </TextBox>
      </EighthTextBox>
      <NinthTextBox>
        <TextBox ref={TextRef.current[8]}>
          <TextWord>SMYCZE&nbsp;</TextWord>
          <TextWord>&&nbsp;</TextWord>
          <TextWord>MULTI&nbsp;</TextWord>
          <TextWord>SCARF&nbsp;</TextWord>
        </TextBox>
      </NinthTextBox>
      <TenthTextBox>
        <TextBox ref={TextRef.current[9]}>
          <TextWord>ARTYKUŁ&nbsp;</TextWord>
          <TextWord>SKÓRZANE&nbsp;</TextWord>
          <TextWord>I&nbsp;</TextWord>
          <TextWord>PIŚMIENNICZE&nbsp;</TextWord>
        </TextBox>
      </TenthTextBox>
      <EleventhTextBox>
        <TextBox ref={TextRef.current[10]}>
          <TextWord>DYSKI&nbsp;</TextWord>
          <TextWord>TWARDE&nbsp;</TextWord>
        </TextBox>
      </EleventhTextBox>
      <TwelfthTextBox>
        <TextBox ref={TextRef.current[11]}>
          <TextWord>PLUSZOWE&nbsp;</TextWord>
          <TextWord>ZABAWKI&nbsp;</TextWord>
        </TextBox>
      </TwelfthTextBox>
    </SectionWrapper>
  );
}

export default offerCircleText;
