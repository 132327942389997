import styled from 'styled-components/macro';

export const SpacerWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.colors.ligthgrey};
`;

export const SectionContainer = styled.div`
  ${({ theme }) => theme.container};
  display: ${({ isFlex }) => (isFlex ? 'flex' : 'block')};
  flex-wrap: wrap;
`;

export const TextWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin: 20px 0;
  padding: 40px 0;
`;

export const TextBox = styled.p`
  font-size: ${({ theme, isTitle }) =>
    isTitle ? theme.font.xxl : theme.font.m};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  margin-bottom: 10px;
  text-align: center;
`;

export const ContactWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
  ${({ theme }) => theme.mq.desktop} {
    width: calc(50% - 2px);
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  margin: auto;
  ${({ theme }) => theme.mq.bigPhone} {
    width: 467px;
    height: 467px;
  }
`;

export const LineWrapper = styled.div`
  width: 4px;
  height: auto;
  display: none;
  ${({ theme }) => theme.mq.desktop} {
    display: block;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 20px;
`;

export const ContactTextWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding-left: 0;
  margin-top: 30px;
  ${({ theme }) => theme.mq.desktop} {
    width: calc(50% - 2px);
    align-items: flex-start;
    padding-left: 100px;
    margin-top: 0;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const LinkIcon = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 15px;
  :last-child() {
    margin-right: 0px;
  }
`;

export const StyledLink = styled.a`
  text-decoration: none;
`;
