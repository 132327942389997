import styled from 'styled-components';
import { Link } from '@reach/router';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 50px;
  ${({ theme }) => theme.mq.bigTablet} {
    margin-top: 250px;
  }
`;

export const SectionContainer = styled.div`
  ${({ theme }) => theme.container};
  display: ${({ isLink }) => (isLink ? 'flex' : 'block')};
  align-items: ${({ isLink }) => (isLink ? 'center' : 'none')};
  flex-wrap: ${({ isLink }) => (isLink ? 'wrap' : 'nowrap')};
`;

export const LinkWrapper = styled.div`
  width: 100%;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.ligthgrey};
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.font.xs};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-decoration: none;
`;

export const CartLinkWrapper = styled.div`
  width: 100%;
  margin: 70px 0 100px 0;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  &::after {
    content: '';
    z-index: -1;
    width: 80%;
    height: 125px;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -15px;
    border: 2px dotted ${({ theme }) => theme.colors.ligthgrey};
    ${({ theme }) => theme.mq.tablet} {
      height: 90px;
    }
  }
`;

export const CartLink = styled(Link)`
  width: ${({ isLast }) => (isLast ? '100%' : '32.3%')};
  margin-right: 1%;
  margin-top: ${({ isLast }) => (isLast ? '1.3%' : '0')};
  height: ${({ isLast }) => (isLast ? '30px' : '60px')};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.secondary : theme.colors.ligthgrey};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  font-size: ${({ theme }) => theme.font.s};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.mq.tablet} {
    width: 23.5%;
    height: 60px;
    margin-right: 2%;
    margin-top: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const AddNewWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin: 50px 0;
`;

export const AddButton = styled(Link)`
  width: 100%;
  height: 47px;
  border: 1px solid ${({ theme }) => theme.colors.ligthgrey};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.font.xs};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-transform: uppercase;
`;

export const CheckoutWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin: 50px 0;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const CheckoutText = styled.p`
  font-size: ${({ isPrice, theme }) =>
    isPrice ? theme.font.xxl : theme.font.xl};
  font-weight: ${({ isPrice, theme }) =>
    isPrice ? theme.fontWeight.bold : theme.fontWeight.regular};
  color: ${({ isPrice, theme }) =>
    isPrice ? theme.colors.secondary : theme.colors.grey};
  text-align: center;
  ${({ theme }) => theme.mq.tablet} {
    font-size: ${({ isPrice, theme }) =>
      isPrice ? theme.font.xxl : theme.font.xl};
    font-weight: ${({ isPrice, theme }) =>
      isPrice ? theme.fontWeight.bold : theme.fontWeight.regular};
    color: ${({ isPrice, theme }) =>
      isPrice ? theme.colors.secondary : theme.colors.grey};
  }
`;

export const CheckoutButtonsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
  flex-flow: column;
  ${({ theme }) => theme.mq.tablet} {
    flex-flow: row;
  }
`;

export const CheckoutButton = styled(Link)`
  width: ${({ isCheckout }) => (isCheckout ? '330px' : 'auto')};
  height: ${({ isCheckout }) => (isCheckout ? '53px' : 'auto')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme, isCheckout }) =>
    isCheckout ? theme.font.s : theme.font.xs};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme, isCheckout }) =>
    isCheckout ? theme.colors.white : theme.colors.grey};
  background-color: ${({ theme, isCheckout }) =>
    isCheckout ? theme.colors.purple : 'none'};
  text-decoration: none;
  border-radius: ${({ isCheckout }) => (isCheckout ? '10px' : 'none')};
  position: relative;
  z-index: 0;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: ${({ isCheckout }) =>
    isCheckout ? '0px 0px 20px 0px rgba(0,0,0,0.5)' : 'none'};
  border: none;
  order: ${({ isCheckout }) => (isCheckout ? '1' : '2')};
  margin: 20px auto 0 auto;
  ${({ theme }) => theme.mq.tablet} {
    order: ${({ isCheckout }) => (isCheckout ? '2' : '1')};
    margin: 0 20px 0 0;
    &:last-child {
      margin-right: 0;
    }
  }
  :hover {
    transform: ${({ isCheckout }) => (isCheckout ? 'scale(0.95)' : 'none')};
    box-shadow: ${({ isCheckout }) =>
      isCheckout ? '0px 0px 10px 0px rgba(0,0,0,0.9)' : 'none'};
  }
  &::after {
    content: '';
    width: 400px;
    height: 300%;
    border-radius: 50%;
    display: ${({ isCheckout }) => (isCheckout ? 'block' : 'none')};
    position: absolute;
    top: -200%;
    left: -20%;
    background-color: ${({ theme }) => theme.colors.secondary};
    z-index: -1;
  }
`;
