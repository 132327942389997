import styled from 'styled-components/macro';
import { Link } from '@reach/router';

export const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
  flex-direction: center;
  transform: translateX(${({ isOpen }) => (isOpen ? '0' : '-100%')});
  transition: transform 0.25s ease-in-out;
`;

export const MenuWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MenuItem = styled.div`
  width: 100%;
  height: 30px;
  margin: 5px 0;
  opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
  transition: opacity 0.25s 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuLink = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-decoration: none;
`;
