import React from 'react';
import { map } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import FormData from 'form-data';

import { submitOrder } from '../../state/actions/summary';
import {
  CheckoutWrapper,
  CheckoutText,
  CheckoutButtonsWrapper,
  CheckoutButton,
} from '../cartPage/cartPage.styled';

function SummaryConvert() {
  const dispatch = useDispatch();
  const cartForm = useSelector((state) => state.cartForm.cartForm);
  const cartList = useSelector((state) => state.cart.cartList);

  function TotalPrice() {
    const subPrices = cartList.map((product) => {
      return { subTotal: product.quantity * product.price };
    });
    const total = subPrices.reduce((acc, product) => {
      return acc + product.subTotal;
    }, 0);
    return total;
  }

  const sku = map(cartList, 'sku');
  const productNames = map(cartList, 'name');
  const productQuantity = map(cartList, 'quantity');
  const productDetails = map(cartList, 'details');
  const productPrice = map(cartList, 'price');

  const formData = new FormData();
  function convertCartForm(object) {
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  }

  formData.append('sku', sku.toString());
  formData.append('productname', productNames.toString());
  formData.append('productquantity', productQuantity.toString());
  formData.append('productdetails', productDetails.toString());
  formData.append('productprice', productPrice.toString());
  formData.append('totalprice', TotalPrice().toFixed(2));
  convertCartForm(cartForm);

  return (
    <CheckoutWrapper>
      <CheckoutText>
        Wartość zamównienia netto:&nbsp;
        <CheckoutText as="span" isPrice>
          {TotalPrice().toFixed(2)}
        </CheckoutText>
      </CheckoutText>
      <CheckoutButtonsWrapper>
        <CheckoutButton to="/produkty">
          &lArr; wróć do listy produktów
        </CheckoutButton>
        {cartList.length === 0 ? null : (
          <CheckoutButton
            as="button"
            onClick={() => dispatch(submitOrder(formData))}
            isCheckout
          >
            Prześlij do wyceny
          </CheckoutButton>
        )}
      </CheckoutButtonsWrapper>
    </CheckoutWrapper>
  );
}

export default SummaryConvert;
