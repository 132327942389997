import styled from 'styled-components/macro';
import { Form, Field } from 'formik';

export const StyledForm = styled(Form)`
  position: relative;
`;

export const FieldWrapper = styled.div`
  position: relative;
`;

export const StyledField = styled(Field)`
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  width: 100%;
  height: 35px;
  border-radius: 10px;
  border: ${({ isError, theme }) =>
    isError
      ? `1px solid ${theme.colors.red}`
      : `1px solid ${theme.colors.ligthgrey}`};
  background-color: ${({ theme }) => theme.colors.ligthgrey};
  margin-bottom: 40px;
  text-align: center;
  transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.white};
  }
  :focus {
    outline: none;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledError = styled.span`
  width: 100%;
  height: auto;
  display: block;
  position: absolute;
  bottom: 13px;
  text-align: center;
  font-size: ${({ theme }) => theme.font.xs};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.red};
`;
