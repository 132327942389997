import React from 'react';
import { useSelector } from 'react-redux';

import NoImage from '../../../assets/images/no_image.png';
import {
  CartRow,
  ImageWrapper,
  StyledImage,
  ProductTitle,
  PriceText,
} from '../../cartProductList/cartProductList.styled';
import {
  ProductWrapper,
  ProductTextWrapper,
  ProductDescWrapper,
  DescText,
  QuantityText,
} from '../desktop/summaryProductDesktop.styled';
import { InformationWrapper, ProductItem } from './summaryProductMobile.styled';

const SummaryProductMobile = () => {
  const cartList = useSelector((state) => state.cart.cartList);

  const calculatePrice = (total, quantity) => {
    const price = Number(total) * quantity;
    return price.toFixed(2);
  };

  return (
    <>
      {cartList.map((product) => (
        <CartRow key={product.id}>
          <InformationWrapper>dane produktu</InformationWrapper>
          <ProductItem>
            <ProductWrapper>
              <ImageWrapper>
                {product.image === null ? (
                  <StyledImage src={NoImage} alt={product.name} />
                ) : (
                  <StyledImage
                    fluid={product.image.sourceUrl}
                    alt={product.name}
                  />
                )}
              </ImageWrapper>
              <ProductTextWrapper>
                <ProductTitle as="h2" isTitle>
                  {product.name}
                </ProductTitle>
                <ProductTitle>Numer produktu: {product.sku}</ProductTitle>
                {/* <ProductTitle>
                  Kolor: {product.attributes[0].options}
                </ProductTitle> */}
              </ProductTextWrapper>
            </ProductWrapper>
          </ProductItem>
          <InformationWrapper>szczegóły produktu</InformationWrapper>
          <ProductItem>
            <ProductDescWrapper>
              <DescText>{product.details}</DescText>
            </ProductDescWrapper>
          </ProductItem>
          <InformationWrapper>ilość</InformationWrapper>
          <ProductItem>
            <QuantityText>{product.quantity}</QuantityText>
          </ProductItem>
          <InformationWrapper>cena za sztuke netto</InformationWrapper>
          <ProductItem>
            <PriceText>{product.price}</PriceText>
          </ProductItem>
          <InformationWrapper>całkowita wartość netto</InformationWrapper>
          <ProductItem>
            <PriceText>
              {calculatePrice(product.price, product.quantity)}
            </PriceText>
          </ProductItem>
        </CartRow>
      ))}
    </>
  );
};

export default SummaryProductMobile;
