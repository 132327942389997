import { gql } from '@apollo/client';

export const GET_SEARCHED_PRODUCT = gql`
  query GET_SEARCHED_PRODUCT(
    $sku: String
    $search: String
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    products(
      where: { search: $search, sku: $sku }
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      edges {
        node {
          ... on SimpleProduct {
            id
            name
            price(format: RAW)
            slug
            image {
              sourceUrl
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
