import { navigate } from '@reach/router';

import formApi from '../../../apis/formApi';
import { SUBMIT_ORDER, SUBMIT_ORDER_ERROR } from '../types';

export const submitOrder = (values) => async (dispatch) => {
  try {
    const response = await formApi.post('126/feedback', values);
    dispatch({ type: SUBMIT_ORDER, payload: response });
    navigate('/status');
  } catch (e) {
    dispatch({
      type: SUBMIT_ORDER_ERROR,
      payload: { status: 405 },
    });
    navigate('/status');
  }
};
