import { gql } from '@apollo/client';

export const GET_DESCRIPTION_BOX = gql`
  query GET_DESCRIPTION_BOX {
    homeboxes(where: { orderby: { field: TITLE, order: ASC } }) {
      edges {
        node {
          acfHomeBox {
            title
            fieldGroupName
            description
            image {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;
