import React from 'react';
import { Router } from '@reach/router';

import GetCategories from '../../hoc/getCatogeries';

import Home from '../../pages/home';
import Contact from '../../pages/contact';
import AboutUs from '../../pages/aboutUs';
import Portfolio from '../../pages/portfolio';
import PortfolioItem from '../../pages/portfolioItem';
import Category from '../../pages/category';
import Product from '../../pages/product';
import Products from '../../pages/products';
import Cart from '../../pages/cart';
import Checkout from '../../pages/checkout';
import Details from '../../pages/details';
import Summary from '../../pages/summary';
import Status from '../../pages/status';
import Search from '../../pages/search';
import NotFound from '../../pages/notFoundPage';
import Kaufland from '../../pages/kaufland';
import KauflandGadgets from '../../pages/kauflandGadgets';
import KaufLandSupervisor from '../../pages/kauflandSupervisior';
import KauflandHowOrder from '../../pages/kauflandHowOrder';

function App() {
  return (
    <Router>
      <Home path="/" />
      <Contact path="/kontakt" />
      <AboutUs path="/o-nas" />
      <Portfolio path="/realizacje" />
      <PortfolioItem path="/realizacje/:slug" />
      <Category path="/kategoria/:slug" />
      <Product path="/produkt/:slug" />
      <Products path="/produkty" />
      <Cart path="/koszyk" />
      <Checkout path="/kasa" />
      <Details path="/szczegoly" />
      <Summary path="/podsumowanie" />
      <Status path="/status" />
      <Search path="/znajdz" />
      <Kaufland path="/kaufland" />
      <KauflandGadgets path="/kaufland-top-gadzety" />
      <KaufLandSupervisor path="/kaufland-opiekun" />
      <KauflandHowOrder path="kaufland-jak-zamawiac" />
      <NotFound default />
    </Router>
  );
}

export default GetCategories(App);
