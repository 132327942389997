import { colors } from '../colors/colors';
import { font } from '../font/font';
import { fontWeight } from '../fontWeight/fontWeight';
import { container } from '../mixins/mixins';
import { mq } from '../mediaQueries/mediaQueries';

export const theme = {
  colors,
  font,
  fontWeight,
  mq,
  container,
};
