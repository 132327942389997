import { navigate } from '@reach/router';

import { ADD_CART_FORM, RESET_CART_FORM } from '../types';

export const addCartForm = (formValues) => (dispatch) => {
  dispatch({ type: ADD_CART_FORM, payload: formValues });
  navigate('/szczegoly');
};

export const resetCartForm = () => (dispatch) => {
  dispatch({ type: RESET_CART_FORM });
};
