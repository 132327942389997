import styled from 'styled-components/macro';
import { Link } from '@reach/router';

import { ReactComponent as Arrow } from '../../assets/images/arrow.svg';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 60px 0 50px 0;
  padding: 30px 0;
  background-color: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.mq.bigTablet} {
    margin: 210px 0 50px 0;
  }
`;

export const SectionContainer = styled.div`
  ${({ theme }) => theme.container};
  height: 250px;
  position: relative;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: ${({ isDisplay }) => (isDisplay ? 'none' : 'flex')};
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: ${({ isSecond }) => (isSecond ? '110px' : '160px')};
  left: 0;
  ${({ theme }) => theme.mq.bigPhone} {
    top: 120px;
  }
  ${({ theme }) => theme.mq.tablet} {
    top: 90px;
  }
  ${({ theme }) => theme.mq.bigTablet} {
    top: 20px;
  }
  ${({ theme }) => theme.mq.desktop} {
    top: 80px;
  }
`;

export const StyledButton = styled.button`
  background: none;
  border: none;
  width: 50px;
  height: 50px;
  cursor: pointer;
  ${({ theme }) => theme.mq.bigTablet} {
    width: 100px;
    height: 100px;
  }
  &:focus {
    outline: none;
  }
`;

export const TextBoxWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mq.bigTablet} {
    padding: 0 100px;
  }
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin-bottom: 20px;
  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.xxxl};
  }
`;

export const Text = styled.p`
  font-size: ${({ theme }) => theme.font.m};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin-bottom: 20px;
  ${({ theme }) => theme.mq.bigTablet} {
    font-size: ${({ theme }) => theme.font.l};
  }
`;

export const StyledArrow = styled(Arrow)`
  transform: ${({ isNext }) => (isNext ? 'rotate(180deg)' : '')};
  &:focus {
    outline: none;
  }
`;

export const StyledLink = styled(Link)`
  display: ${({ isDisplay }) => (isDisplay ? 'none' : 'block')};
  padding: 10px 15px;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const SvgWrapper = styled.div`
  width: 100%;
  height: auto;
`;
