export const ADD_TO_SEARCH = 'ADD_TO_SEARCH';
export const ADD_SEARCH_VALUE = 'ADD_SEARCH_VALUE';
export const ADD_TO_CART = 'ADD_TO_CART';
export const DELETE_FROM_CART = 'DELETE_FROM_CART';
export const INCREASE_QUANTITY = 'INCREASE_QUANTITY';
export const DECREASE_QUANTITY = 'DECREASE_QUANTITY';
export const SET_PRODUCT_QUANTITY = 'SET_PRODUCT_QUANTITY';
export const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS';
export const ADD_CART_FORM = 'ADD_CART_FORM';
export const SUBMIT_ORDER = 'SUBMIT_ORDER';
export const SUBMIT_ORDER_ERROR = 'SUBMIT_ORDER_ERROR';
export const RESET_CART = 'RESET_CART';
export const RESET_CART_FORM = 'RESET_CART_FORM';
export const INCREASE_FIVE_PAGE_NUMBER = 'INCREASE_FIVE_PAGE_NUMBER';
export const DECREASE_FIVE_PAGE_NUMBER = 'DECREASE_FIVE_PAGE_NUMBER';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const FIRST_PAGE_NUMBER = 'FIRST_PAGE_NUMBER';
export const LAST_PAGE_NUMBER = 'LAST_PAGE_NUMBER';
export const SET_PRODUCTS_PER_PAGE = 'SET_PRODUCTS_PER_PAGE';
export const CHANGE_SORT = 'CHANGE_SORT';
export const CHANGE_MIN_PRICE = 'CHANGE_MIN_PRICE';
export const CHANGE_MAX_PRICE = 'CHANGE_MAX_PRICE';
export const LOADING_FALSE = 'LOADING_FALSE';
export const LOADING_TRUE = 'LOADING_TRUE';
export const SEND_CONTACT = 'SEND_CONTACT';
export const SEND_CONTACT_ERROR = 'SEND_CONTACT_ERROR';
export const RESET_SEND_STATUS = 'RESET_SEND_STATUS';
export const GET_CATEGORIES = 'GET_CATEGORIES';
