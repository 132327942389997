import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_CONTACT_DATA } from '../../gql/getContactData';
import Loading from '../loading';

import ContactForm from '../contactForm';
import ContactMap from '../ContactMap';
import {
  SectionWrapper,
  SectionContainer,
  Title,
  Text,
  MapRow,
  MapItem,
  IframeWrapper,
  StyledIframe,
  SubTitle,
  SubText,
} from './contactPage.styled';

function Contact() {
  const { loading, error, data } = useQuery(GET_CONTACT_DATA);
  if (error) return console.log(error);
  if (loading) return <Loading />;

  return (
    <SectionWrapper>
      {data.kontakts.edges.map((info) => (
        <SectionContainer key={info.node.acfKontakt.title}>
          <Title>{info.node.acfKontakt.title}</Title>
          <Text>{info.node.acfKontakt.description}</Text>
          <MapRow isCenter>
            <MapItem isLeft>
              <ContactMap />
            </MapItem>
            <MapItem>
              <IframeWrapper>
                <StyledIframe
                  src={info.node.acfKontakt.googleMap}
                  width="800"
                  height="600"
                  frameBorder="0"
                  allowFullScreen=""
                  ariaHidden="false"
                  tabIndex="0"
                />
              </IframeWrapper>
            </MapItem>
          </MapRow>
          <MapRow>
            <MapItem isLeft>
              <SubTitle>Dane kontaktowy</SubTitle>
              <SubText isBold>Biuro:</SubText>
              <SubText isMargin>
                {info.node.acfKontakt.adressFirstLine}
                <br />
                {info.node.acfKontakt.adressSecondLine}
              </SubText>
              <SubText isMargin>
                {info.node.acfKontakt.emailFirst}
                <br />
                {info.node.acfKontakt.emailSecond}
                <br />
                tel.&nbsp;{info.node.acfKontakt.phone}
              </SubText>
              <SubText isBold>Dane do faktury:</SubText>
              <SubText isMargin>
                {info.node.acfKontakt.invoiceFirstLine}
                <br />
                {info.node.acfKontakt.invoiceSecondLine}
              </SubText>
              <SubText isMargin>
                NIP:&nbsp;{info.node.acfKontakt.nip}
                <br />
                REGON:&nbsp;{info.node.acfKontakt.regon}
              </SubText>
              <SubText isBold>Konto bankowe:</SubText>
              <SubText isMargin>{info.node.acfKontakt.bankAccount}</SubText>
            </MapItem>
            <MapItem>
              <SubTitle>Formularz Kontaktowy</SubTitle>
              <ContactForm />
            </MapItem>
          </MapRow>
        </SectionContainer>
      ))}
    </SectionWrapper>
  );
}

export default Contact;
