import React from 'react';

import Layout from '../../components/layout';
import SearchBar from '../../components/searchBar';
import CategoryPage from '../../components/categoryPage';
import SEO from '../../components/SEO';

function Category() {
  return (
    <Layout>
      <SEO title="Produkty" />
      <SearchBar />
      <CategoryPage />
    </Layout>
  );
}

export default Category;
