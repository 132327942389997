import styled from 'styled-components/macro';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const SectionContainer = styled.div`
  ${({ theme }) => theme.container};
  height: auto;
`;
