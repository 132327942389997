import React, { useRef, createRef, useEffect } from 'react';
import gsap from 'gsap';

import {
  TimelineWrapper,
  TimelineRow,
  ImageWrapper,
  TextWrapper,
  StyledImage,
  TextBox,
  Strip,
} from './aboutUsTimeline.styled';

function AboutUsTimeline({ data }) {
  const rowRef = useRef([...Array(3)].map(() => createRef()));

  useEffect(() => {
    rowRef.current.forEach((item) => {
      const timeline = gsap.timeline({
        paused: true,
        scrollTrigger: {
          trigger: item.current,
          start: 'top 70%',
        },
      });
      timeline
        .set(item.current.children[1].children, {
          opacity: 0,
          y: '100px',
        })
        .to(
          [
            item.current.children[0].children[0],
            item.current.children[0].children[2],
          ],
          0.45,
          { y: '-100%' }
        )
        .to(item.current.children[0].children[1], 0.45, { y: '100%' }, '-=0.45')
        .to(
          item.current.children[1].children,
          0.45,
          { y: '0', opacity: 1 },
          '-=0.45'
        );
    });
  });

  return (
    <TimelineWrapper>
      <TimelineRow ref={rowRef.current[0]}>
        <ImageWrapper>
          <Strip isFirst />
          <Strip isSecond />
          <Strip isThird />
          <StyledImage src={data.onass.nodes[0].acfAboutUs.image1.sourceUrl} />
        </ImageWrapper>
        <TextWrapper>
          <TextBox>{data.onass.nodes[0].acfAboutUs.text1}</TextBox>
        </TextWrapper>
      </TimelineRow>
      <TimelineRow ref={rowRef.current[1]}>
        <ImageWrapper isRight>
          <Strip isFirst />
          <Strip isSecond />
          <Strip isThird />
          <StyledImage src={data.onass.nodes[0].acfAboutUs.image2.sourceUrl} />
        </ImageWrapper>
        <TextWrapper isRight>
          <TextBox isRight>{data.onass.nodes[0].acfAboutUs.text2}</TextBox>
        </TextWrapper>
      </TimelineRow>
      <TimelineRow ref={rowRef.current[2]}>
        <ImageWrapper>
          <Strip isFirst />
          <Strip isSecond />
          <Strip isThird />
          <StyledImage src={data.onass.nodes[0].acfAboutUs.image3.sourceUrl} />
        </ImageWrapper>
        <TextWrapper>
          <TextBox>{data.onass.nodes[0].acfAboutUs.text3}</TextBox>
        </TextWrapper>
      </TimelineRow>
    </TimelineWrapper>
  );
}

export default AboutUsTimeline;
