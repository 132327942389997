import styled from 'styled-components/macro';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const SectionContainer = styled.div`
  ${({ theme }) => theme.container};
  overflow-x: scroll;
  overflow-y: hidden;
  height: 180px;
  width: calc(100% - 30px);
  margin: 50px 15px;
  ${({ theme }) => theme.mq.bigPhone} {
    width: 100%;
    margin: 50px auto;
  }
  ::-webkit-scrollbar {
    height: 1.2rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.ligthgrey};
    background-clip: content-box;
    border-radius: 15px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.secondary};
    border-radius: 15px;
    height: 1.2rem;
  }
`;

export const CategoryRow = styled.div`
  width: 1500px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CategoryItem = styled.a`
  width: 150px;
  height: 130px;
  margin: 10px;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.ligthgrey};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: border-color 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const CategoryTitle = styled.div`
  font-size: ${({ theme }) => theme.font.xs};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.grey};
  text-align: center;
  transition: color 0.3s ease-in-out;
  ${CategoryItem}:hover & {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const CategoryImage = styled.img`
  width: 100%;
  height: auto;
`;

export const CategoryWrapper = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
