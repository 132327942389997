import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.colors.primary};
  position: relative;
  ::after {
    content: '';
    width: 100%;
    height: 10px;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.white};
    top: 3px;
    left: 0;
  }
`;

export const Container = styled.div`
  ${({ theme }) => theme.container};
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  @media (min-width: 992px) {
    flex-flow: row;
  }
`;

export const AboutUsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column;
  margin-top: 50px;
  margin-bottom: 20px;
  @media (min-width: 992px) {
    width: 20%;
    margin-bottom: 100px;
  }
  @media (min-width: 1200px) {
    width: 30%;
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column;
  margin-top: 10px;
  margin-bottom: 50px;
  @media (min-width: 992px) {
    width: 75%;
    margin-top: 50px;
    margin-bottom: 100px;
  }
  @media (min-width: 1200px) {
    width: 60%;
  }
`;

export const ContactRow = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    flex-wrap: none;
  }
`;

export const ContactItem = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    width: 25%;
  }
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin: 0 3px;
`;

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.colors.white};
  padding-bottom: 5px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export const Text = styled.p`
  font-size: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.colors.white};
  width: ${(props) => (props.isInline ? 'calc(100% - 22px)' : '100%')};
  display: ${(props) => (props.isInline ? 'inline-block' : 'block')};
`;
