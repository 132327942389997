import { gql } from '@apollo/client';

export const GET_RECOMMENDED_PRODUCT = gql`
  query GET_RECOMMENDED_PRODUCT {
    products(first: 20) {
      edges {
        node {
          ... on SimpleProduct {
            id
            name
            slug
            price(format: RAW)
            image {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;
