import styled from 'styled-components/macro';

export const ProductWrapper = styled.div`
  ${({ theme }) => theme.container};
  margin-top: 20px;
`;

export const ProductTitle = styled.h1`
  color: ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.font.xs};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-transform: uppercase;
  margin-bottom: 20px;
  ${({ theme }) => theme.mq.desktop} {
    margin-bottom: 50px;
  }
`;

export const ProductText = styled.p`
  color: ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme, isBold }) => (isBold ? '1.8rem' : theme.font.xs)};
  font-weight: ${({ theme, isBold }) =>
    isBold ? theme.fontWeight.bold : theme.fontWeight.regular};
  margin-top: ${({ isBold }) => (isBold ? '10px' : '0')};
  margin-bottom: ${({ isBold }) => (isBold ? '5px' : '0')};
  line-height: 19px;
  border-bottom: ${({ theme, isFirst }) =>
    isFirst ? `1px solid ${theme.colors.ligthgrey}` : `none`};
  width: ${({ isFirst }) => (isFirst ? '100%' : 'auto')};
  padding-bottom: ${({ isFirst }) => (isFirst ? '5px' : '0')};
  padding-top: ${({ isSecond }) => (isSecond ? '20px' : '0')};
  ${({ theme }) => theme.mq.desktop} {
    border: none;
    width: auto;
    padding-bottom: 0;
    padding-top: 0;
  }
`;

export const ProductPrice = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: ${({ theme }) => theme.font.xl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const ProductContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  ${({ theme }) => theme.mq.desktop} {
    flex-flow: row;
  }
`;

export const ImageWrapper = styled.div`
  width: ${({ isIcon }) => (isIcon ? '60px' : '100%')};
  height: ${({ isIcon }) => (isIcon ? 'auto' : '100%')};
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ theme, isIcon }) =>
    isIcon ? 'none' : `1px solid ${theme.colors.ligthgrey}`};
  margin-top: ${({ isIcon }) => (isIcon ? '0' : '10px')};
  ${({ theme }) => theme.mq.desktop} {
    width: ${({ isIcon }) => (isIcon ? '100px' : '570px')};
    height: ${({ isIcon }) => (isIcon ? 'auto' : '450px')};
  }
`;

export const ProductImage = styled.img`
  width: 323px;
  height: 323px;
  ${({ theme }) => theme.mq.desktop} {
    width: 400px;
    height: 400px;
  }
`;

export const ProductIcon = styled.img`
  width: 45px;
  height: 45px;
`;

export const ProductInformationWrapper = styled.div`
  width: ${({ isDescription }) =>
    isDescription ? 'calc(100% - 60px)' : '100%'};
  height: auto;
  ${({ theme }) => theme.mq.desktop} {
    width: ${({ isDescription }) =>
      isDescription ? 'calc(100% - 100px)' : 'calc(100% - 570px)'};
    padding-left: ${({ isDescription }) => (isDescription ? '0' : '20px')};
  }
`;

export const PriceWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 5px;
  flex-wrap: wrap;
  margin: 10px 0;
  ${({ theme }) => theme.mq.desktop} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.ligthgrey};
    margin: 0;
  }
`;

export const ProductRow = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;

export const ProductItem = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  margin: 25px 0;
  &:first-child {
    margin-top: 10px;
  }
`;

export const ColorBoxWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const ProductBox = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${({ color }) => `#${color}`};
  margin-right: 20px;
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.4);
`;

export const RecommendedProductWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const CartWrapper = styled.div`
  width: 114px;
  height: 114px;
  margin: auto;
  ${({ theme }) => theme.mq.desktop} {
    position: absolute;
    top: 50%;
    right: 20px;
  }
`;

export const CartLink = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const SvgWrapper = styled.div`
  width: 100%;
  height: auto;
`;
