import React from 'react';
import { useSelector } from 'react-redux';

import CartProductList from '../cartProductList';
import {
  SectionWrapper,
  SectionContainer,
  LinkWrapper,
  StyledLink,
  CartLinkWrapper,
  CartLink,
  AddNewWrapper,
  AddButton,
  CheckoutWrapper,
  CheckoutText,
  CheckoutButtonsWrapper,
  CheckoutButton,
} from './cartPage.styled';

function CartPage() {
  const cartList = useSelector((state) => state.cart.cartList);

  function TotalPrice() {
    const subPrices = cartList.map((product) => {
      return { subTotal: product.quantity * product.price };
    });
    const total = subPrices.reduce((acc, product) => {
      return acc + product.subTotal;
    }, 0);
    return total;
  }

  return (
    <SectionWrapper>
      <LinkWrapper>
        <SectionContainer isLink>
          <StyledLink to="/">Strona Główna</StyledLink>
          <StyledLink as="span">&nbsp;/&nbsp;</StyledLink>
          <StyledLink to="/koszyk">Koszyk</StyledLink>
        </SectionContainer>
      </LinkWrapper>
      <SectionContainer>
        <CartLinkWrapper>
          <CartLink to="/koszyk" isActive>
            1.twój koszyk
          </CartLink>
          <CartLink as="div">2.dane kontaktowe</CartLink>
          <CartLink as="div">3.szczegóły</CartLink>
          <CartLink as="div" isLast>
            4.finalizacja
          </CartLink>
        </CartLinkWrapper>
        <CartProductList />
        <AddNewWrapper>
          <AddButton to="/produkty">+ dodaj kolejny produkt</AddButton>
        </AddNewWrapper>
        <CheckoutWrapper>
          <CheckoutText>
            Wartość zamównienia netto:&nbsp;
            <CheckoutText as="span" isPrice>
              {TotalPrice().toFixed(2)}
            </CheckoutText>
          </CheckoutText>
          <CheckoutButtonsWrapper>
            <CheckoutButton to="/produkty">
              &lArr; wróć do listy produktów
            </CheckoutButton>
            {cartList.length === 0 ? null : (
              <CheckoutButton to="/kasa" isCheckout>
                DALEJ
              </CheckoutButton>
            )}
          </CheckoutButtonsWrapper>
        </CheckoutWrapper>
      </SectionContainer>
    </SectionWrapper>
  );
}

export default CartPage;
