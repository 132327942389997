import styled from 'styled-components/macro';

export const CartInformationItem = styled.p`
  width: 50%;
  font-size: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.colors.grey};
  text-transform: uppercase;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const InformationWrapper = styled.div`
  width: ${({ isFirst }) => (isFirst ? '100%' : '50%')};
  height: auto;
  display: flex;
  justify-content: flex-end;
`;

export const ProductItem = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: ${({ isFirst }) => (isFirst ? 'column' : 'row')};
  margin-bottom: 20px;
`;
