import React, { useState, useEffect } from 'react';
import HeroSlider from './heroSlider';

function heroSliderData() {
  const [slider, setSlide] = useState(false);

  useEffect(() => {
    if (window.location.pathname === '/realizacje') {
      setSlide(true);
    }
    if (window.location.pathname === '/realizacje/') {
      setSlide(true);
    }
  });

  const handleClick = () => {
    setSlide(!slider);
  };

  const dataFalse = {
    title: 'O Nas',
    text:
      'Dostarczamy gadżety reklamowe. Lubimy to i znamy się na tym. Współpracujemy z największymi dostawcami. Dołącz do naszych Klientów.',
    link: 'Czytaj Dalej',
    linkSlug: '/o-nas',
    svg: 'people',
  };

  const dataTrue = {
    title: 'Nasze Realizacje',
    text:
      'Przedstawiamy to, z czego jesteśmy dumni. Zapraszamy do zapoznania się ze zrealizowanymi projektami.',
    link: 'Zobacz Realizacje',
    linkSlug: '/realizacje',
    svg: 'rocket',
  };

  if (slider === false) {
    return <HeroSlider data={dataFalse} handleClick={handleClick} />;
  }
  if (slider === true) {
    return <HeroSlider data={dataTrue} handleClick={handleClick} />;
  }
  return null;
}

export default heroSliderData;
